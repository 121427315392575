import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {convertUtcDateToLocal} from '../../formatters';
import {AHPRARecord} from '../AHPRARecord/AHPRARecord';

export class HistoryPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ahpraRegistrationData: this.props.ahpraRegistrationData
        };
    }

    componentDidUpdate(prevProps) {
        if(prevProps.ahpraRegistrationData !== this.props.ahpraRegistrationData) {
            this.setState({
                ahpraRegistrationData: this.props.ahpraRegistrationData
            });
        }
    }

    closing() {
        this.cleaningState();
        this.props.onClose();
    }

    cleaningState() {
        this.setState({
            ahpraRegistrationData: null,
        });
    }

    render() {

        return <Modal
            size="xl"
            show={this.props.show}
            onHide={() => this.closing()}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Recorded: {this.state.ahpraRegistrationData && this.state.ahpraRegistrationData.updatedOnUtc ? convertUtcDateToLocal(this.state.ahpraRegistrationData.updatedOnUtc) : null}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AHPRARecord ahpraRecord={this.state.ahpraRegistrationData} />
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.closing()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}