import './SelectList.scss';
import React, { Component } from 'react';

export class SelectList extends Component {
    constructor(prop) {
        super(prop);
    }

    render() {
        let inputClasses = "form-control";
        let formClasses = "form-group";

        if (this.props.error) {
            inputClasses += " is-invalid";
        }

        if (this.props.extraClasses) {
            inputClasses += ` ${this.props.extraClasses}`;
        }

        if (this.props.extraClasses) {
            formClasses += ` ${this.props.extraClasses}`;
        }

        return (
            <div className={formClasses}>
                {this.props.label.length !== 0 && <label className="control-label">{this.props.label}</label>}
                <select
                    type="text"
                    className={`${inputClasses} cursor-pointer select-padding`}
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChange}
                >
                    {this.props.defaultOption && <option value="">{this.props.defaultOption}</option>}
                    {this.props.options.map((option, index) => (
                        <option
                            key={index}
                            value={option.value}
                            disabled={option.isSeparator || option.isDisabled}
                        >
                            {option.isSeparator
                                ? "───────────────────────────────────"
                                : option.label}
                        </option>
                    ))}
                </select>
                {this.props.error && <div className="invalid-feedback">{this.props.error}</div>}
            </div>
        );
    }
}