import React from 'react';
import { SettingType, SettingTypeLabelLookup } from '../../Constants';
import SimpleInput from '../shared/SimpleInput/SimpleInput';

const Setting = ({ type, value, onChange, error }) => {
    switch (type) {
        case SettingType.DAYS_PRIOR_TO_EXPIRY_UPCOMING_ALERT: return <SimpleInput
            title={SettingTypeLabelLookup[type]}
            value={+value}
            type={"number"}
            error={error}
            onChange={(evt) => { evt.preventDefault(); onChange(type, evt.target.value); }} />;
    }

};

export default Setting;
