import React from 'react';
import SelectOption, { Option } from './SelectOption';
import './selectInput.scss';
import { classNames } from 'utils/util';
import withDeprecationWarning from 'utils/hoc/withDeprecationWarning';

interface SelectInputProps {
	label: string;
	options: Option[];
	note?: string;
	isMandatory?: boolean;
	error?: string;
}

function renderOptionsList(options: Option[]) {
	return options.map((option: Option) => <SelectOption key={option.value} option={option} />);
}

const SelectInput: React.FC<SelectInputProps> = ({ label, options = [], note, isMandatory = false, error }) => {
	const classes = classNames(['ph-selectInput', { hasError: error, isMandatory: isMandatory, hasNoLabel: !label }]);

	return (
		<label aria-label={label} className={classes}>
			{label ? <span className="ph-selectInput-label">{label}</span> : null}
			{note ? <span className="ph-selectInput-note">{note}</span> : null}
			<div className="ph-selectInput-input">
				<select>{renderOptionsList(options)}</select>
			</div>
			{error && <span className="ph-selectInput-error">{error}</span>}
		</label>
	);
};

export default withDeprecationWarning(SelectInput);
