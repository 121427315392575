import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './ModalAlert.scss';
import {Toast} from 'react-bootstrap';
import { GlobalAlertUtility } from '../../utils/GlobalAlertUtility';


const ModalAlert = ({show, messageType, message, closeAlert}) => {
    return <div className={`float-message modal-alert alert alert-${messageType} ${show}`} onClick={() => closeAlert()}><strong>{message}</strong></div>;
};

export default ModalAlert;
