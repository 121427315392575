import React, { ReactNode, useState } from 'react';
import './tabContainer.scss';
import { classNames } from 'utils/util';
import { ComponentBase } from 'utils/types';
import Tab from './Tab';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface TabContainerProps extends ComponentBase {
	data: { tabText: string; tabContent: ReactNode; showBadge?: boolean; badgeContent?: string, leftIcon?: IconProp }[];
	initialActiveTabIndex?: number;
	onChange?: (activeIndex: number) => void;
}

const TabContainer: React.FC<TabContainerProps> = ({ data, className, onChange, initialActiveTabIndex = 0 }) => {
	if (data.length < 1) {
		throw Error('There must be at least one tab in a tab group');
	}

	const classes = classNames(['ph-tab-container', className]);
	const [activeTabIndex, setActiveTabIndex] = useState(initialActiveTabIndex);

	const onClickTab = (activeIndex: number) => {
		setActiveTabIndex(activeIndex);
		if(onChange)
			onChange(activeIndex);
	};

	return (
		<div className={classes}>
			<div className="ph-tab-group" role="tablist">
				{data.map(({ tabText, badgeContent, leftIcon }, index) => {
					return (
						<Tab
							key={tabText}
							id={`tab-${index}`}
							text={tabText}
							type={activeTabIndex === index ? 'primary' : 'secondary'}
							onClick={() => onClickTab(index)}
							ariaControls={`panel-${index}`}
							tabBadge={badgeContent}
							leftIcon={leftIcon}
						/>
					);
				})}
			</div>
			{/*
				Tab panel implemented according to this example
                https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role#example
            */}
			{data.map(({ tabContent, tabText }, index) => {
				return (
					<div
						key={tabText}
						id={`panel-${index}`}
						className="ph-tab-content"
						role="tabpanel"
						tabIndex={activeTabIndex === index ? 0 : -1}
						aria-labelledby={`tab-${index}`}
						hidden={activeTabIndex !== index}
					>
						{tabContent}
					</div>
				);
			})}
		</div>
	);
};

export default TabContainer;
