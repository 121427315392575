import React from 'react';
import {useParams} from 'react-router-dom';
import {RecordType} from '../../Constants';
import { RegistrationDetail } from '../RegistrationDetail/RegistrationDetail';

const RegistrationDetailRoute = () => {
    const {id} = useParams();

    return <RegistrationDetail linkedRecordId={id} />
};

export default RegistrationDetailRoute;
