import React, { ReactNode } from 'react';
import Badge, { BadgeProps } from 'components/Badge/Badge';
import './pageHeader.scss';
import Breadcrumb, { BreadcrumbType } from 'components/Breadcrumb';

export interface PageHeaderProps {
	title: string;
	subText?: string;
	badges?: BadgeProps[];
	breadcrumbs?: BreadcrumbType[];
	pageActions?: ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subText, breadcrumbs, pageActions, badges = [] }) => {
	return (
		<div className="ph-page-Header">
			<div className="ph-page-Header-left-container">
				<Breadcrumb breadcrumbs={breadcrumbs} />
				<div className="ph-page-Header-title">
					<h1>{title}</h1>
					{badges.length > 0 && badges.map((badge) => <Badge {...badge} key={badge.content} />)}
				</div>
				{subText && <div className="ph-page-Header-subText">{subText}</div>}
			</div>
			{pageActions && <div className="ph-page-Header-right-container">{pageActions}</div>}
		</div>
	);
};

export default PageHeader;
