import React from 'react';
import './toggle.scss';
import { classNames } from 'utils/util';

export type SwitchClickEventHandler = (checked: boolean, event?: React.MouseEvent<HTMLInputElement>) => void;

interface ToggleProps {
	onClick?: () => void;
	checked?: boolean;
	disabled?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

const Toggle: React.FC<ToggleProps> = ({ className, checked, ...props }) => {
	const classes = classNames(['ph-toggle', className]);

	return (
		<label className={classes}>
			<input
				{...props}
				type="checkbox"
				aria-label="Toggle Value"
				role="switch"
				aria-checked={checked}
				checked={checked}
			/>
		</label>
	);
};

export default Toggle;
