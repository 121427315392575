import React, { memo } from 'react';
import {
	Bar,
	BarChart as REBarChart,
	CartesianGrid,
	Cell,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { classNames } from 'utils/util';
import './barChart.scss';
import Loading from 'components/Loading/Loading';

const DEFAULT_BAR_RADIUS = 0;
const DEFAULT_BAR_SIZE = 12;
export const DEFAULT_FONT_SIZE = 14;

interface VerticalBarChartProps {
	/** display loading icon */
	isLoading: boolean;

	/** Data to chart */
	data: object[];

	responsiveContainerMinWidth: number;
	responsiveContainerMinHeight: number;
	fontSize: number;

	hasBarRadius: boolean;
	xAxisDataKey: string;

	/** Bar definition */
	bars: VerticalBar[];

	/** Incase no data to display */
	noDataXAxisDomain: string[] | number[];
	noDataYAxisDomain: string[] | number[];
	/** No data position */
	noDataTopPosition: string;
	noDataLeftPosition: string;

	/** Legend */
	showLegend: boolean;
	legendAlign: 'left' | 'right' | 'center';
	legendIconType:
		| 'line'
		| 'plainline'
		| 'square'
		| 'rect'
		| 'circle'
		| 'cross'
		| 'diamond'
		| 'star'
		| 'triangle'
		| 'wye';

	yAxisTickFormatter(amount: number): string;
	layout?: 'horizontal' | 'vertical';
	label?: boolean;
	horizontalGridLine?: boolean;
	verticalGridLine?: boolean;
	xAxisTick?: boolean;
	yAxisOnTop?: boolean;
}

interface VerticalBar {
	/** Bar name */
	name: string;

	/** Bar data key from Data */
	dataKey: string;

	/** Bar color */
	backgroundColor: string | string[];
}

interface CustomYAxisTickProps {
	y: number;
	payload: {
		value: string | number;
	};
}

const CustomYAxisTick = (props: CustomYAxisTickProps) => {
	let { y } = props;
	const { payload } = props;
	y -= 13;
	return (
		<g transform={`translate(${0},${y})`}>
			<text x={0} y={0} textAnchor="start" fill="#666">
				{payload.value}
			</text>
		</g>
	);
};

const LegendFormFormatter = (value: string) => {
	return <>{value}</>;
};

const BarChart: React.FC<VerticalBarChartProps> = ({
	isLoading,
	data,
	responsiveContainerMinWidth,
	responsiveContainerMinHeight,
	fontSize,
	hasBarRadius,
	xAxisDataKey,
	bars,
	noDataXAxisDomain,
	noDataYAxisDomain,
	noDataTopPosition,
	noDataLeftPosition,
	showLegend,
	legendAlign,
	legendIconType,
	yAxisTickFormatter,
	layout = 'vertical',
	label = false,
	horizontalGridLine = false,
	verticalGridLine = false,
	xAxisTick = true,
	yAxisOnTop = false,
}) => {
	const hasNoData = data == undefined || data.length == 0;

	const classes = classNames(['ph-bar-chart']);

	const yAxisOnTopKey = yAxisOnTop ? { margin: { bottom: -30, top: 5, right: 30 } } : { margin: { top: 5 } };

	const renderAxes = (isVertical: boolean, hasNoData: boolean) => {
		const sharedProps = {
			stroke: '2',
			fontSize: fontSize ?? DEFAULT_FONT_SIZE,
			dataKey: xAxisDataKey,
		};

		const domainX = hasNoData ? noDataYAxisDomain : undefined;
		const domainY = hasNoData ? noDataYAxisDomain : undefined;

		if (isVertical) {
			return (
				<>
					<XAxis {...sharedProps} domain={noDataXAxisDomain} />
					<YAxis
						axisLine={false}
						tickLine={false}
						tickFormatter={yAxisTickFormatter}
						fontSize={fontSize ?? DEFAULT_FONT_SIZE}
						domain={domainX}
					/>
				</>
			);
		} else {
			return (
				<>
					<YAxis
						{...(yAxisOnTop ? { width: 1 } : { width: 150 })}
						type="category"
						{...sharedProps}
						domain={noDataXAxisDomain}
						fontSize={fontSize ?? DEFAULT_FONT_SIZE}
						{...(yAxisOnTop ? { tick: CustomYAxisTick } : undefined)}
					/>
					<XAxis
						type="number"
						axisLine={false}
						tickLine={false}
						tickFormatter={yAxisTickFormatter}
						fontSize={fontSize ?? DEFAULT_FONT_SIZE}
						domain={domainY}
						tick={xAxisTick}
					/>
				</>
			);
		}
	};

	const renderTooltip = () => (
		<Tooltip
			cursor={{ fill: 'rgba(0,0,0,0.05)' }}
			contentStyle={{
				border: 'none',
				boxShadow: 'var(--ph-box-shadow-base)',
				borderRadius: 'var(--ph-radius-4)',
			}}
			labelStyle={{ fontWeight: 'var(--ph-font-weight-semi-bold)', marginBottom: '2px' }}
			itemStyle={{ fontWeight: 'var(--ph-font-weight-semi-bold)', padding: 0 }}
			formatter={(value) => yAxisTickFormatter(value as number)}
			separator=": "
		/>
	);

	return (
		<div className={classes}>
			{hasNoData && !isLoading && (
				<div style={{ top: noDataTopPosition, left: noDataLeftPosition }} className="ph-bar-chart-no-data">
					No data available
				</div>
			)}
			<ResponsiveContainer
				minHeight={responsiveContainerMinHeight}
				minWidth={responsiveContainerMinWidth}
				height={'100%'}
				width={'100%'}
			>
				{isLoading ? (
					<Loading showMessage={false} />
				) : (
					<REBarChart
						layout={layout === 'vertical' ? 'horizontal' : 'vertical'}
						data={hasNoData ? [] : data}
						{...yAxisOnTopKey}
					>
						<>
							<CartesianGrid
								strokeDasharray="2 2"
								vertical={verticalGridLine}
								horizontal={horizontalGridLine}
							/>
							{renderAxes(layout === 'vertical', hasNoData)}
							{!hasNoData && renderTooltip()}
						</>

						{bars?.map((bar) => {
							return (
								<Bar
									key={bar.name}
									fill={bar.backgroundColor instanceof Array ? undefined : bar.backgroundColor}
									dataKey={bar.dataKey}
									radius={hasBarRadius ? DEFAULT_BAR_RADIUS : undefined}
									barSize={DEFAULT_BAR_SIZE}
									name={bar.name}
									{...(label ? { label: { position: 'right', fill: '#333' } } : undefined)}
								>
									{data.map((_, index) => (
										<Cell
											id={`${bar.name}-${index}`}
											key={`cell-${bar.name}`}
											fill={
												bar.backgroundColor instanceof Array
													? bar.backgroundColor[index]
													: bar.backgroundColor
											}
										/>
									))}
								</Bar>
							);
						})}

						{showLegend && (
							<Legend align={legendAlign} iconType={legendIconType} formatter={LegendFormFormatter} />
						)}
					</REBarChart>
				)}
			</ResponsiveContainer>
		</div>
	);
};

export default memo(BarChart);
