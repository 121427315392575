import React, { ReactNode, useState, useMemo } from 'react';

export interface FeatureConfigKeyValue {
	key: string;
	value: string;
}

export interface FeatureConfigContextProps {
	configList: FeatureConfigKeyValue[];
	setConfigList: React.Dispatch<React.SetStateAction<FeatureConfigKeyValue[]>>;
	portalUrl: string;
}

interface FeatureConfigProviderProps {
	children: ReactNode;
	portalUrl: string;
}

export const FeatureConfigContext = React.createContext<FeatureConfigContextProps>({
	configList: [],
	setConfigList: () => [],
	portalUrl: '',
});

const FeatureConfigProvider: React.FC<FeatureConfigProviderProps> = ({ children, portalUrl }) => {
	const [configList, setConfigList] = useState([] as FeatureConfigKeyValue[]);

	const contextValue = useMemo(() => {
		return { configList, setConfigList, portalUrl };
	}, [configList, setConfigList, portalUrl]);

	return <FeatureConfigContext.Provider value={contextValue}>{children}</FeatureConfigContext.Provider>;
};

export default FeatureConfigProvider;
