import React from 'react';
import { NotificationItem } from 'components/Notification';
import { EmptyAppHeaderNotifications } from 'components/Notification/NotificationItem/AppHeaderNotificationContentItem/AppHeaderNotificationContentItem';

interface NotificationSectionProps {
	title?: string;
	items: NotificationItem[];
	updateNotifications: (notificationIds: string[]) => void;
	notificationItemComponent: React.ElementType;
	isLoading: boolean;
}

const NotificationSection: React.FC<NotificationSectionProps> = ({
	title,
	items,
	updateNotifications,
	notificationItemComponent: NotificationItemComponent,
	isLoading,
}) => {
	if (!isLoading && items.length === 0) return null;
	const displayItems = isLoading ? EmptyAppHeaderNotifications : items;

	return (
		<section>
			{!!title && !isLoading && <h4>{title}</h4>}
			<ul className="ph-notification-items">
				{displayItems.map((item: NotificationItem, index: number) => (
					<NotificationItemComponent
						key={item.id || index}
						item={item}
						updateNotifications={updateNotifications}
						isLoading={isLoading}
					/>
				))}
			</ul>
		</section>
	);
};

export default NotificationSection;
