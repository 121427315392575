import { useState, useRef } from 'react';
import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	useDismiss,
	useRole,
	useClick,
	useInteractions,
	hide,
} from '@floating-ui/react';

const useActionMenuFloating = (isOpenInitial = false) => {
	const [isOpen, setIsOpen] = useState(isOpenInitial);
	const actionMenuRef = useRef<HTMLInputElement>(null);

	const { refs, floatingStyles, context, middlewareData } = useFloating({
		placement: 'bottom-end',
		open: isOpen,
		onOpenChange: setIsOpen,
		middleware: [
			offset(0),
			flip({ fallbackAxisSideDirection: 'end' }),
			shift(),
			hide({
				strategy: 'referenceHidden',
			}),
		],
		whileElementsMounted: autoUpdate,
	});

	const click = useClick(context);
	const dismiss = useDismiss(context);
	const role = useRole(context);

	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

	return {
		isOpen,
		setIsOpen,
		actionMenuRef,
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		middlewareData,
	};
};

export default useActionMenuFloating;
