import React, { useState, useRef } from 'react';
import './FileUpload.scss';
import { classNames } from 'utils/util';
import Icon from 'components/Icons/Icon';
import ErrorMessage from 'components/ErrorMessage';
import FormLabel from 'components/FormLabel';
import IconButton from 'components/IconButton';

interface FileProp {
	name: string;
	size?: number;
	type?: string;
	webkitRelativePath?: string;
	lastModifiedDate?: Date;
	lastModified?: number;
}

interface FileUploadProps {
	id: string;
	label?: string;
	className?: string;
	multiple?: boolean;
	isMandatory?: boolean;
	errorMessage?: string;
	fileList?: Array<FileProp>;
	caption?: string | null;
	onFileChange?: (files: Array<FileProp>) => void;
	accept?: Array<string>;
	disabled?: boolean;
	previewFileComponent?: { component: React.ReactElement, classes: string };
}

const FileUpload: React.FC<FileUploadProps> = ({
	id,
	label,
	className,
	multiple = false,
	isMandatory = false,
	errorMessage,
	fileList = [],
	caption,
	onFileChange,
	accept,
	disabled = false,
	previewFileComponent = null,
}) => {
	const classes = classNames([
		'ph-file-upload',
		{ hasNoLabel: !label, isDisabled: disabled, hasError: !!errorMessage },
		className,
	]);
	const [updatedFileList, setUpdatedFileList] = useState<FileProp[]>(fileList);

	const wrapperRef = useRef<HTMLDivElement>(null);
	const onDragEnter = () => {
		if (!wrapperRef || !wrapperRef.current) return;

		wrapperRef.current.classList.add('dragover');
	};

	const onDragLeave = () => {
		if (!wrapperRef || !wrapperRef.current) return;

		wrapperRef.current.classList.remove('dragover');
	};

	const onDrop = () => {
		onDragLeave();
	};

	const onFileDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;

		const newFile = e.target.files[0];
		if (!newFile) return;

		const updatedList = multiple ? [...updatedFileList, newFile] : [newFile];
		setUpdatedFileList(updatedList);
		if (onFileChange) onFileChange(updatedList);
	};

	const removeFile = (item: FileProp) => {
		if (item) {
			const updatedList = [...updatedFileList];
			updatedList.splice(updatedFileList.indexOf(item), 1);
			setUpdatedFileList(updatedList);
			if (onFileChange) onFileChange(updatedList);
		}
	};

	return (
		<div className={classes}>
			{label ? <FormLabel label={label} htmlIdFor={id} isMandatory={isMandatory} /> : null}
			<div
				ref={wrapperRef}
				className="ph-file-upload-drag-and-drop"
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
			>
				<input
					id={id}
					type="file"
					multiple={multiple}
					className={'ph-file-upload-input-file'}
					accept={accept ? accept.join(', ') : undefined}
					disabled={disabled}
					onChange={onFileDrop}
				/>
				<label htmlFor={id}>
					<Icon icon={['fal', 'cloud-arrow-up']} className="ph-file-upload-icon" />
					<p>
						<u>Click to upload</u> or drag and drop {multiple ? 'files' : 'file'}
					</p>
					{caption && <p className={'ph-file-upload-caption'}>{caption}</p>}
				</label>
			</div>
			{errorMessage && <ErrorMessage error={errorMessage} />}
			{updatedFileList &&
				updatedFileList.constructor === Array &&
				updatedFileList.map((item) => (
					<div key={`${item?.name ?? ''}${new Date().getTime()}`} className={'ph-file-upload-display-file'}>
						{item?.name}
						<div className={`ph-file-upload-clear-icon ${previewFileComponent?.classes || ''}`}>
							<IconButton icon={['fal', 'times']} onClick={() => removeFile(item)} />
							{previewFileComponent ? previewFileComponent.component : null}
						</div>
					</div>
				))}
		</div>
	);
};

export default FileUpload;
