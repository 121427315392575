import React, { Component } from 'react';
import './FilterHeader.scss';
import { convertHtmlStringToString } from '../../formatters';
import * as Api from '../../api';

export class FilterHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            filterOptions: [],
            selectedFilterId: -1,
            selectedFilterLabel: '',
            selectedDefaultFilterId: -1,
            selectedDefaultFilterLabel: '',
            selectedFilterToOverwrite: null,
            showSaveFilterModal: false,
            filterName: '',
            isDefault: false,
            showWarning: false,
            warningMessage: '',
            warningActionText: '',
            warningActionColor: '',
            warningAction: null,
            currentFilterOptionId: this.props.currentFilterOptionId
        };
    }

    componentDidMount() {
        this.getAllFilters();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filterOptions !== this.props.filterOptions) {
            this.state.filterOptions = this.props.filterOptions;
        }
        if (prevProps.filters !== this.props.filters) {
            this.state.filters = this.props.filters;
        }
        if(this.props.currentFilterOptionId && prevProps.currentFilterOptionId !== this.props.currentFilterOptionId ){
            this.state.currentFilterOptionId = this.props.currentFilterOptionId;
            this.setAsSelectedFilter(this.props.currentFilterOptionId);
        }
    }

    getAllFilters() {
        Api.filterApi.getAll().then((data) => {
            const options = data.reduce((optionsArray, f) => {
                const filterName = convertHtmlStringToString(f.name)
                return [
                ...optionsArray,
                {
                  name: filterName,
                  value: f.id,
                  key: f.id,
                  label: filterName
                }
              ]}, [{
                name: 'All Items',
                value: -1,
                key: -1,
                label: 'All Registrations'
              }]);

            this.setState({ filters: data, filterOptions: options }, () => {
                this.getDefaultFilter();
            });
        });
    }

    getDefaultFilter() {
        var selectedFilter = this.state.filters.find((f) => f.isDefault);
        if (selectedFilter) {
            this.setState({
                selectedFilterId: selectedFilter.id,
                selectedFilterLabel: selectedFilter.name,
                selectedDefaultFilterId: selectedFilter.id,
                selectedDefaultFilterLabel: selectedFilter.name,
                isDefault: true
            });
        }
        else {
            if(this.props.preFiltered) return;
            this.setState({
                selectedFilterId: this.state.filterOptions.find((f) => f.value === -1).value,
                isDefault: false
            }, () => { this.props.setDefaultFilters(false) });
        }
    }

    onFilterChange = (event) => {
        this.setAsSelectedFilter(parseInt(event.target.value));
    }

    setAsSelectedFilter(filterId) {
        var selectedFilter = this.state.filters.find((f) => f.id === filterId);
        if (selectedFilter) {
            this.setState({
                selectedFilterId: selectedFilter.id,
                selectedFilterLabel: selectedFilter.name,
                isDefault: selectedFilter.isDefault,
                selectedDefaultFilterId: selectedFilter.isDefault ? selectedFilter.id : this.state.selectedDefaultFilterId,
                selectedDefaultFilterLabel: selectedFilter.isDefault ? selectedFilter.name : this.state.selectedDefaultFilterLabel
            }, () => {
                this.props.applySelectedFilter(selectedFilter)
            });
        }
        else {
            this.setState({
                selectedFilterId: this.state.filterOptions.find((f) => f.value === -1).value,
                isDefault: false
            }, () => { this.props.setDefaultFilters(true) });
        }
    }

    handleNameChange = (event) => {
        let updatedState = Object.assign({}, this.state);
        updatedState[event.target.name] = event.target.value;
        this.setState(updatedState);
    }

    addFilter = () => {
        if (this.state.filterName.length == 0) {
            return;
        }

        var filterDto = {
            name: this.state.filterName,
            settings: JSON.stringify(this.props.createFilterSettings())
        };

        Api.filterApi.add(filterDto).then((result) => {
            var newFilterOption = {
                name: result.name,
                value: result.id,
                key: result.id,
                label: result.name
            };

            let options = [...this.state.filterOptions, newFilterOption];

            let filters = [
                ...this.state.filters,
                {
                    id: result.id,
                    isDefault: result.isDefault,
                    name: result.name,
                    orgId: result.orgId,
                    settings: result.settings,
                    userId: result.userId
                }
            ];

            let updatedState = Object.assign({}, this.state);
            updatedState['filterOptions'] = options;
            updatedState['filters'] = filters;
            updatedState['selectedFilterId'] = newFilterOption.value;
            updatedState['showSaveFilterModal'] = false;
            updatedState['selectedFilterToOverwrite'] = null;
            updatedState['isDefault'] = false;
            this.setState(updatedState);

            this.props.applySelectedFilter(result);
        });
    }

    handleUpdateFilterChange = (filterId) => {
        const filter = this.state.filterOptions.find((f) => f.value == filterId);
        this.setState({ selectedFilterToOverwrite: filter });
    };

    updateFilter = () => {
        const { selectedFilterToOverwrite } = this.state;
        if (selectedFilterToOverwrite == null) {
            return;
        }

        var filterDto = {
            id: selectedFilterToOverwrite.value,
            name: selectedFilterToOverwrite.name,
            settings: JSON.stringify(this.props.createFilterSettings())
        };

        Api.filterApi.update(filterDto).then((result) => {
            // Update filter options
            var optionIndex = this.state.filterOptions.findIndex((o) => o.value == result.id);
            let options = [...this.state.filterOptions];
            let updatedFilterOption = {
                ...options[optionIndex],
                name: result.name,
                label: result.name
            };

            options[optionIndex] = updatedFilterOption;

            // Update filter list
            var filterIndex = this.state.filters.findIndex((f) => f.id == result.id);
            let filters = [...this.state.filters];
            let updatedFilter = {
                ...filters[filterIndex],
                isDefault: result.isDefault,
                settings: result.settings,
                name: result.name
            };

            filters[filterIndex] = updatedFilter;

            let updatedState = Object.assign({}, this.state);
            updatedState['filterOptions'] = options;
            updatedState['filters'] = filters;
            updatedState['selectedFilterId'] = updatedFilterOption.value;
            updatedState['showSaveFilterModal'] = false;
            updatedState['selectedFilterToOverwrite'] = null;
            updatedState['isDefault'] = result.isDefault;
            if (result.isDefault) {
                updatedState['selectedFilterLabel'] = newFilterOption.name;
                updatedState['selectedDefaultFilterId'] = updatedFilterOption.value;
                updatedState['selectedDefaultFilterLabel'] = updatedFilterOption.name;
            }
            this.setState(updatedState);

            this.props.applySelectedFilter(result);
        });
    }

    updateDefaultFilter = (event) => {
        event.preventDefault();
        var filterDto = {
            id: this.state.selectedFilterId,
            isDefault: true
        };

        Api.filterApi.updateDefault(filterDto).then(() => {
            let filtersToUpdate = [...this.state.filters];

            // Remove current default filter
            var currentDefaultFilterIndex = this.state.filters.findIndex((f) => f.isDefault);
            if (currentDefaultFilterIndex > - 1) {
                let currentDefaultFilter = {
                    ...filtersToUpdate[currentDefaultFilterIndex],
                    isDefault: false
                };

                filtersToUpdate[currentDefaultFilterIndex] = currentDefaultFilter;
            }
            
            // Set new default filter
            var newDefaultFilterIndex = this.state.filters.findIndex((f) => f.id == this.state.selectedFilterId);
            let newDefaultFilter = {
                ...filtersToUpdate[newDefaultFilterIndex],
                isDefault: true
            };

            filtersToUpdate[newDefaultFilterIndex] = newDefaultFilter;

            let updatedState = Object.assign({}, this.state);
            updatedState['filters'] = filtersToUpdate;
            updatedState['isDefault'] = true;
            updatedState['selectedDefaultFilterId'] = newDefaultFilter.id;
            updatedState['selectedDefaultFilterLabel'] = newDefaultFilter.name;
            this.setState(updatedState);

            this.props.setBaseSettings();
        });
    }

    deleteFilterWarning = (event) => {
        event.preventDefault();
        this.setState({
            showWarning: true,
            warningMessage: 'Confirm deleting filter',
            warningActionText: 'Delete',
            warningActionColor: 'primary',
            warningAction: this.deleteFilter
        });
    };

    deleteFilter = () => {
        const { selectedFilterId } = this.state;

        Api.filterApi.delete(selectedFilterId).then(() => {
            let updatedState = Object.assign({}, this.state);

            // For resetting base setting to all items
            var wasDefaultFilter = this.state.filters.find((f) => f.id == selectedFilterId).isDefault;
            if (wasDefaultFilter) {
                this.props.resetBaseSettings();
                updatedState['isDefault'] = false;
                
            }

            // Remove deleted filter from options
            var options = this.state.filterOptions.filter((o) => o.value != selectedFilterId);
            var optionIndex = this.state.filterOptions.findIndex((o) => o.value == selectedFilterId);
            if (!options[optionIndex]) {
                optionIndex--;
            }

            // Remove deleted filter from filter list
            var filters = this.state.filters.filter((f) => f.id != selectedFilterId);
            var filterIndex = this.state.filters.findIndex((f) => f.id == selectedFilterId);
            if (!filters[filterIndex]) {
                filterIndex--;
            }

            updatedState['filterOptions'] = options;
            updatedState['filters'] = filters;
            updatedState.showWarning = false;
            this.setState(updatedState);

            if (optionIndex != 0) {
                this.setAsSelectedFilter(options[optionIndex].value);
            } else {
                this.props.setDefaultFilters(true);
            }
        });
    }

    toggleShowSaveFilterModal() {
        this.setState({
            showSaveFilterModal: !this.state.showSaveFilterModal,
            selectedFilterToOverwrite: null,
            filterName: ''
        });
    }

    setToAllItems() {
        this.setState({ selectedFilterId: this.state.filterOptions.find((f) => f.value === -1).value });
    }

    setToDefaultFilter() {
        this.setState({
            selectedFilterId: this.state.selectedDefaultFilterId,
            selectedFilterLabel: this.state.selectedDefaultFilterLabel,
            isDefault: true
        });
    }

    render() {
        const {
            filterOptions,
            selectedFilterId        } = this.state;

        return <div className="card-message-list-header card-header">
                    <div className="row">
                        <div className="col-fixed-width-30"><h3>View:</h3></div>
                        <div className="col-6">
                            <div className="form-group">
                                <select
                                    placeholder='All Registration'
                                    type='text'
                                    className='form-control-header text-lg view-custom-filter'
                                    name='selectedFilterId'
                                    value={selectedFilterId}
                                    onChange={this.onFilterChange}
                                >
                                {filterOptions.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.value}
                                        disabled={option.isSeparator || option.isDisabled}
                                    >
                                        {option.isSeparator
                                            ? "───────────────────────────────────"
                                            : option.label}
                                    </option>
                                ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>;
    }
}