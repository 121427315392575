import { post } from "./applicationApiActions";

export default class LinkedRecordsApi {
    static updateRecordStatus(id, status) {
        return post(`/LinkedRecords/UpdateRecordStatus/?status=${status}&id=${id}`);
    }
    static sendEmail(id, { subject, message }) {
        return post(`/LinkedRecords/${id}/sendEmail`, { subject, message });
    }

    static createRecord(staffRecordId, userId, isLicenseAvailable) {
        let query = new URLSearchParams();
        if (staffRecordId) {
            query.append('staffRecordId', staffRecordId);
        }
        if (userId) {
            query.append('userId', userId);
        }
        if (isLicenseAvailable) {
            query.append('isLicenseAvailable', isLicenseAvailable);
        }

        return post(`/LinkedRecords/?${query.toString()}`);
    }
}