import React from 'react';
import HorizontalLabel from '../shared/HorizontalLabel/HorizontalLabel';
import './AHPRARecord.scss';
import { convertUtcDateToLocal, convertUtcDateToLocalCustom } from '../../formatters';
import {AHPRAStatus} from '../shared/AHPRAStatus/AHPRAStatus';


export class AHPRARecord extends React.PureComponent {
    
    getFullName(ahpraRecord){
        let name = '';
        if(ahpraRecord.title && ahpraRecord.title != ''){
            name += ahpraRecord.title + ' ';
        }
        if(ahpraRecord.firstName && ahpraRecord.firstName != ''){
            name += ahpraRecord.firstName + ' ';
        }
        if(ahpraRecord.middleName && ahpraRecord.middleName != ''){
            name += ahpraRecord.middleName + ' ';
        }
        if(ahpraRecord.lastName && ahpraRecord.lastName != ''){
            name += ahpraRecord.lastName;
        }

        return name;
    }

    checkIfAlertIsExcessive(alerts) {
        return alerts && alerts.length > 0 ? alerts.reduce((acc, curr) => (typeof acc === "object" ? acc.details.length : 0) + curr.details.length) > 50 : 0;
    }

    checkIfRegistrationRequirmentsIsExcessive(registrations) {
        return registrations && registrations.length > 0 ? registrations.reduce((acc, curr) => 
            (typeof acc === "object" && acc.registrationRequirements ? acc.registrationRequirements.length : 0) + 
            (curr.registrationRequirements ? curr.registrationRequirements.length : 0)) > 50 : 0;
    }

    render(){
        const { ahpraRecord } = this.props;
        const regDetailsCount = ahpraRecord && ahpraRecord.registrationDetails ? ahpraRecord.registrationDetails.length : 0;
        const additionalColumnSize = 'col-' + ((4 - regDetailsCount) * 3);

        if (!ahpraRecord){
            return null;
        }

        const conditions = ahpraRecord.alerts?.filter(a=> a.alertType == 'Condition') || [];
        const undertakings = ahpraRecord.alerts?.filter(a=> a.alertType == 'Undertaking') || [];
        const reprimands = ahpraRecord.alerts?.filter(a=> a.alertType == 'Reprimand') || [];
        const endorsements = ahpraRecord.alerts?.filter(a=> a.alertType == 'Endorsement') || [];
        const notations = ahpraRecord.alerts?.filter(a=> a.alertType == 'Notation') || [];
        //Card#6456 remove duplicate Ahpra professions from the list
        const uniqueProfs = [...new Set(ahpraRecord.professions?.map(p => p.name).filter(name => name))] || [];

        return (
                <div className="AHPRARecord card shadow-sm rounded-lg">
                    <div className="card-header">
                        <h5 className="card-title font-weight-bold">Ahpra Record</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <HorizontalLabel title='REGISTRATION NUMBER' value={ahpraRecord.ahpraRegistrationNumber} titlecol='6' valuecol='6' />
                                <HorizontalLabel title='REGISTERED NAME' value={this.getFullName(ahpraRecord)} titlecol='6' valuecol='6' />
                                <HorizontalLabel title='SEX' value={ahpraRecord.sex} titlecol='6' valuecol='6' />
                                <HorizontalLabel title='PROFESSION' value='' titlecol='6' valuecol='6'>
                                    {uniqueProfs?.map((name, index) => <span key={index}>{name}<br/></span>)}
                                </HorizontalLabel>
                                <HorizontalLabel title='QUALIFICATIONS' value='' titlecol='6' valuecol='6'>
                                    {ahpraRecord.qualifications?.map((a, index) => <span key={index}>{a.name}<br/></span>)}
                                </HorizontalLabel>
                            </div>
                            <div className="col-6 AHPRA-Padding-left">
                                <HorizontalLabel title='PRINCIPAL PLACE OF PRACTICE' value={ahpraRecord.principalPlaceOfPractice} titlecol='6' valuecol='6' />
                                <HorizontalLabel title='DATE OF 1ST REGISTRATION' value={ahpraRecord.firstRegistrationOn ? convertUtcDateToLocal(ahpraRecord.firstRegistrationOn) : 'None'} titlecol='6' valuecol='6' />
                                <HorizontalLabel title='AHPRA ALERTS STATUS' value='' titlecol='6' valuecol='6' >
                                    <AHPRAStatus status={ahpraRecord.ahpraStatus}></AHPRAStatus>
                                </HorizontalLabel>
                                <HorizontalLabel title='CONDITIONS' value='' titlecol='6' valuecol='6' isExcessive={this.checkIfAlertIsExcessive(conditions)} >
                                    {conditions.map((a, index) => <span key={index}>{a.details}<br/></span>)}
                                </HorizontalLabel>
                                <HorizontalLabel title='UNDERTAKINGS' value='' titlecol='6' valuecol='6' isExcessive={this.checkIfAlertIsExcessive(undertakings)}>
                                    {undertakings.map((a, index) => <span key={index}>{a.details}<br/></span>)}
                                </HorizontalLabel>
                                <HorizontalLabel title='REPRIMANDS' value='' titlecol='6' valuecol='6' isExcessive={this.checkIfAlertIsExcessive(reprimands)}>
                                    {reprimands.map((a, index) => <span key={index}>{a.details}<br/></span>)}
                                </HorizontalLabel>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <h5 className="card-title font-weight-bold">Details</h5>
                        <div className="row">
                            <div className="col">
                                <HorizontalLabel title='REGISTRATION TYPE' VALUE ='' titlecol='3' valuecol='9'>
                                    {ahpraRecord.registrationDetails && ahpraRecord.registrationDetails.length > 0 &&
                                        <div className="row">
                                            {ahpraRecord.registrationDetails.map((r, index) =>
                                                <div className="col" key={index}>{r.registrationType}</div>
                                            )}
                                            {regDetailsCount < 4 && 
                                                <div className={additionalColumnSize}>&nbsp;</div>
                                            }
                                        </div>
                                    }
                                </HorizontalLabel>
                                <HorizontalLabel title='REGISTRATION EXPIRY' value='' titlecol='3' valuecol='9'>
                                    {ahpraRecord.registrationDetails && ahpraRecord.registrationDetails.length > 0 &&
                                        <div className="row">
                                            {ahpraRecord.registrationDetails.map((r, index) =>
                                                <div className="col" key={index}>{r.registrationExpiryOn ? convertUtcDateToLocal(r.registrationExpiryOn) : 'None'}</div>
                                            )}
                                            {regDetailsCount < 4 && 
                                                <div className={additionalColumnSize}>&nbsp;</div>
                                            }
                                        </div>
                                    }
                                </HorizontalLabel>
                                <HorizontalLabel title='ENDORSEMENTS' value='' titlecol='3' valuecol='9' isExcessive={this.checkIfAlertIsExcessive(endorsements)}>
                                    {ahpraRecord.registrationDetails && ahpraRecord.registrationDetails.length > 0 &&
                                        <div className="row">
                                            {ahpraRecord.registrationDetails.map((r, index) =>
                                                <React.Fragment key={index}>
                                                    {endorsements.length > 0 &&
                                                        <div className="col">
                                                            {(ahpraRecord.alerts?.filter(a=> a.alertType == 'Endorsement' && a.registrationId == r.id) || []).map((a, index2) => <span key={index2}>{a.details}<br/></span>)}
                                                        </div>
                                                    }
                                                    {endorsements.length == 0 &&
                                                        <div className="col">
                                                            None
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            )}
                                            {regDetailsCount < 4 && 
                                                <div className={additionalColumnSize}>&nbsp;</div>
                                            }
                                        </div>
                                    }
                                </HorizontalLabel>
                                <HorizontalLabel title='NOTATIONS' value='' titlecol='3' valuecol='9' isExcessive={this.checkIfAlertIsExcessive(notations)}>
                                    {ahpraRecord.registrationDetails && ahpraRecord.registrationDetails.length > 0 &&
                                        <div className="row">
                                            {ahpraRecord.registrationDetails.map((r, index) =>
                                                <React.Fragment key={index}>
                                                    {notations.length > 0 &&
                                                        <div className="col">
                                                            {(ahpraRecord.alerts?.filter(a=> a.alertType == 'Notation' && a.registrationId == r.id) || []).map((a, index2) => <span key={index2}>{a.details}<br/></span>)}
                                                        </div>
                                                    }
                                                    {notations.length == 0 &&
                                                        <div className="col">
                                                            None
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            )}
                                            {regDetailsCount < 4 && 
                                                <div className={additionalColumnSize}>&nbsp;</div>
                                            }
                                        </div>
                                    }
                                </HorizontalLabel>
                                <HorizontalLabel title='REGISTRATION REQUIREMENTS' value='' titlecol='3' valuecol='9' isExcessive={this.checkIfRegistrationRequirmentsIsExcessive(ahpraRecord.registrationDetails)}>
                                    {ahpraRecord.registrationDetails && ahpraRecord.registrationDetails.length > 0 &&
                                        <div className="row">
                                            {ahpraRecord.registrationDetails.map((r, index) =>
                                                <div className="col" key={index}>{r.registrationRequirements ? r.registrationRequirements : 'None'}</div>
                                            )}
                                            {regDetailsCount < 4 && 
                                                <div className={additionalColumnSize}>&nbsp;</div>
                                            }
                                        </div>
                                    }
                                </HorizontalLabel>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="row">
                            <div className="col ahpra-footer">
                                Ahpra Alerts data last synchronised: {convertUtcDateToLocalCustom(ahpraRecord.synchronisedOnUtc, 'DD/MM/YYYY HH:mm')}
                            </div>
                        </div>
                    </div>
                </div>
                );
    }
}
