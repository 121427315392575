import { AppHeaderNotifications } from 'components/Notification/NotificationContent/AppHeaderNotificationContent';

const EMPTY_NOTIFICATIONS: AppHeaderNotifications = {
	today: [],
	thisweek: [],
	earlier: [],
	isEmpty: false,
	isAnyUnread: false,
};

const GET_NOTIFICATIONS_URL = '/notification/get/';
const UPDATE_NOTIFICATIONS_URL = '/notification/update/';

export { EMPTY_NOTIFICATIONS, GET_NOTIFICATIONS_URL, UPDATE_NOTIFICATIONS_URL };
