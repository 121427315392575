import { Changes, ItemResponse, MenuItem, SubMenuUrl } from './Interfaces';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const formatUrl = (url: string): string => {
	return url.endsWith('/') ? url.slice(0, -1).toLowerCase() : url.toLowerCase();
};

export const isCurrentUrl = (url: string): boolean => {
	return formatUrl(window.location.href) === formatUrl(url);
};

export const getParentDefaultLaunchUrl = (subItemParentId: number | null, items: MenuItem[]) => {
	const parentItem = items.find((item) => item.id === subItemParentId);
	let parentDefaultLaunchUrl = '';
	if (subItemParentId !== null && parentItem !== null) {
		parentDefaultLaunchUrl = parentItem?.defaultLaunchUrl ?? '';
	}
	return parentDefaultLaunchUrl;
};

export const mapItemResponseToMenuItem = (item: ItemResponse): MenuItem => ({
	id: item.id,
	label: item.name,
	href: !item.subMenuApiUrl ? item.defaultLaunchUrl : null,
	defaultLaunchUrl: item.defaultLaunchUrl,
	icon: (item.iconClass ? item.iconClass.split(' ') : null) as IconProp,
	isExternal: item.isExternal,
	isLoading: !!item.subMenuApiUrl,
	isActive: !item.subMenuApiUrl && item.defaultLaunchUrl ? isCurrentUrl(item.defaultLaunchUrl) : false,
	supplementaryImage: (item.supplementaryImage ? item.supplementaryImage.split(' ') : null) as IconProp,
});

export function extractParentItemsAndSubMenuUrls(response: ItemResponse[]) {
	const subMenuUrls: SubMenuUrl[] = [];
	//  Filters the items from the data array that do not have a parentId
	const parentItems: MenuItem[] = response
		.filter((item) => !item.parentId)
		.map((item) => {
			if (item.defaultLaunchUrl && item.subMenuApiUrl) {
				subMenuUrls.push({ url: item.defaultLaunchUrl, menuItemsPath: item.subMenuApiUrl, id: item.id });
			}
			return mapItemResponseToMenuItem(item);
		});

	return { parentItems, subMenuUrls };
}

export function addChildrenToParents(response: ItemResponse[], parentItems: MenuItem[]): MenuItem[] {
	// Filters the items from the data array that have a parentId
	// Then reduces them to items array with their parent-child relationships
	return response
		.filter((item) => item.parentId)
		.reduce((acc: MenuItem[], cur: ItemResponse) => {
			// Finds the parent MenuItem object in the acc array (initialValue is parentItems) that has a matching parentId
			const parent = acc.find((item) => item.id == cur.parentId);
			if (parent) {
				const childMenuItem = mapItemResponseToMenuItem(cur);
				parent.items = parent.items ? [...parent.items, childMenuItem] : [childMenuItem];
				// When a child item is loaded in the inital payload and is currently active set parent to active
				parent.isActive = childMenuItem.isActive ? true : parent.isActive;
			}
			return acc;
		}, parentItems);
}

export function updateParentWithSubItems(
	response: ItemResponse[],
	subMenuUrl: SubMenuUrl,
	items: MenuItem[]
): MenuItem[] {
	const subItems: MenuItem[] = response.map((subItem, index) => {
		return {
			id: subItem.parentId ? subItem.parentId + index : subItem.id,
			label: subItem.name,
			href: `${getParentDefaultLaunchUrl(subItem.parentId, items)}${subItem.defaultLaunchUrl}`,
			isExternal: subItem.isExternal,
			isActive: isCurrentUrl(subMenuUrl.url + subItem.defaultLaunchUrl),
		};
	});

	const changes: Changes = {
		isLoading: false,
		items: subItems,
		isActive: subItems.some((subItem) => subItem.isActive),
	};

	return updateObjectInImmutableArrayById(items, subMenuUrl.id, changes);
}

export function updateObjectInImmutableArrayById(list: MenuItem[], id: number, changes: Changes): MenuItem[] {
	return list.reduce((acc: MenuItem[], curr: MenuItem) => {
		if (curr.id === id) {
			curr = { ...curr, ...changes };
		}
		acc.push(curr);
		return acc;
	}, []);
}
