import { type Placement } from '@floating-ui/react';
import { ReactNode } from 'react';
import { TooltipContext } from './TooltipContext';
import useTooltip from './useTooltip';

interface TooltipOptions {
	initialOpen?: boolean;
	placement?: Placement;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
}

export function Tooltip({ children, ...options }: { children: ReactNode } & TooltipOptions) {
	// This can accept any props as options, e.g. `placement`,
	// or other positioning options.

	const tooltip = useTooltip(options);
	return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

export default Tooltip;
