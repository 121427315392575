import AffiliationsApi from "./affiliationsApi";
import PracticeApi from "./practiceApi";
import ClinicalServiceTypeApi from "./clinicalServiceTypeApi";
import FileNotesApi from "./fileNotesApi";
import FilterApi from "./filterApi";
import LicenseApi from "./licenseApi";
import RegistrationsApi from "./registrationsApi";
import LinkedRecordsApi from "./linkedRecordsApi";
import RecentlyViewedApi from "./recentlyViewedApi";
import UserApi from "./userApi";
import StaffRecordsApi from "./staffRecordsApi";
import ProfessionApi from "./professionApi";
import OrganisationSettingsApi from "./organisationSettingsApi";
import AlertsApi from "./alertsApi";
import PermissionsApi from "./permissionApi";

export const affiliationsApi = AffiliationsApi; 
export const practiceApi = PracticeApi;
export const clinicalServiceTypeApi = ClinicalServiceTypeApi;
export const fileNotesApi = FileNotesApi;
export const filterApi = FilterApi;
export const licenseApi = LicenseApi;
export const registrationApi = RegistrationsApi;
export const linkedRecordsApi = LinkedRecordsApi;
export const recentlyViewedApi = RecentlyViewedApi;
export const userApi = UserApi;
export const staffRecordsApi = StaffRecordsApi
export const professionApi = ProfessionApi;
export const organisationSettingsApi = OrganisationSettingsApi;
export const alertsApi = AlertsApi;
export const permissionsApi = PermissionsApi;