export const RecordType = {
    STAFF_RECORD: 'staff',
    USER_RECORD: 'user'
};

export const AlertStatus = {
    NEW: "New",
    OPEN: "Open",
    CLOSED: "Closed",
    OVERDUE: "Overdue"
};

export const AlertStatusLookup = {
    [AlertStatus.NEW]: "New",
    [AlertStatus.OPEN]: "Open",
    [AlertStatus.CLOSED]: "Closed"
}

export const AlertType = {
    ADDED_USER: "AddedUser",
    DEACTIVATED_USER: "DeactivatedUser",
    STIPULATIONS: "Stipulations",
    SUSPENDED_REGISTRATIONS: "SuspendedRegistrations",
    UPCOMING_REGISTRATIONS: "UpcomingRegistrations",
    CANCELLED_REGISTRATION: "CancelledRegistration",
    INVALID_REGISTRATIONS: "InvalidRegistrations"
};

export const AlertTypeLookup = {
    [AlertType.ADDED_USER]: "Added Users",
    [AlertType.DEACTIVATED_USER]: "Deactivated Users",
    [AlertType.STIPULATIONS]: "Stipulations",
    [AlertType.SUSPENDED_REGISTRATIONS]: "Suspended Registrations",
    [AlertType.UPCOMING_REGISTRATIONS]: "Upcoming Registrations",
    [AlertType.CANCELLED_REGISTRATION]: "Cancelled Registration",
    [AlertType.INVALID_REGISTRATIONS]: "Invalid Registrations"
}

export const AlertTypeDescriptionLookup = {
    [AlertType.ADDED_USER]: "User Added",
    [AlertType.DEACTIVATED_USER]: "Deactivated Users",
    [AlertType.STIPULATIONS]: "Stipulations",
    [AlertType.SUSPENDED_REGISTRATIONS]: "Suspended Registrations",
    [AlertType.UPCOMING_REGISTRATIONS]: "Upcoming Renewal",
    [AlertType.CANCELLED_REGISTRATION]: "Cancelled Registration",
    [AlertType.INVALID_REGISTRATIONS]: "Invalid Registrations"
}

export const CertificateStatus = {
    NOT_PROVIDED: 'NotProvided',
    PROVIDED: 'Provided',
    AUTO_PROVIDED: 'AutoProvided',
    NOT_VALIDATED: 'NotValidate',
    INVALID: 'Invalid'
};

export const CertificateStatusLookUp = {
    [CertificateStatus.NOT_PROVIDED]: "Not provided",
    [CertificateStatus.PROVIDED]: "Provided",
    [CertificateStatus.AUTO_PROVIDED]: "Auto provided",
    [CertificateStatus.NOT_VALIDATED]: "Not validate",
    [CertificateStatus.INVALID]: "Invalid"
}

export const ProvisioningStatus = {
    ACTIVE: 'Active',
    DEACTIVATED: 'Deactivated',
    INACTIVE: 'Inactive',
    NEW: 'New',
    NORECORD: 'NoRecord',
    NEWNOLICENSE: 'NewNoLicense'
};

export const ProvisioningStatusLookUp = {
    [ProvisioningStatus.ACTIVE]: "Active",
    [ProvisioningStatus.DEACTIVATED]: "De-activated",
    [ProvisioningStatus.INACTIVE]: "Inactive",
    [ProvisioningStatus.NEW]: "New",
    [ProvisioningStatus.NORECORD]: "No Record",
    [ProvisioningStatus.NEWNOLICENSE]: "New (No License)"
}

export const PolicyStatus = {
    CURRENT: 'Current',
    EXPIRED: 'Expired',
    CANCELED: 'Canceled'
};

export const PolicyStatusLookUp = {
    [PolicyStatus.CURRENT]: "Current",
    [PolicyStatus.EXPIRED]: "Expired",
    [PolicyStatus.CANCELED]: "Canceled"
}

export const ActionTypes = {
    CREATED: 'Created',
    UPDATED: 'Updated',
    DELETED: 'Deleted'
};

export const SettingType = {
    DAYS_PRIOR_TO_EXPIRY_UPCOMING_ALERT: 'DaysPriorToExpiryUpcomingAlert'
};

export const SettingTypeLabelLookup = {
    [SettingType.DAYS_PRIOR_TO_EXPIRY_UPCOMING_ALERT]: "Upcoming Alert: No of days Prior to expiry date"
}

export const SuccessMessage = {
    USER_ACTIVATE: "Successfully activated user '{0}'",
    USER_DEACTIVATE: "Successfully deactivated user '{0}'",
    USER_INACTIVATE: "Successfully inactivated user '{0}'",
    REGISTRATION_ADDED: "Successfully added registration",
    REGISTRATION_REMOVED: "Successfully removed registration",
    SETTINGS_SAVE: "Organisation settings updated"
}

export const ErrMessage = {
    REGISTRATION_ADDED: "Failed sync registration",
}


export const defaultAllOptionValue = '';
export const defaultAllOptionValue2 = 'All';
export const DefaultProvisioningFilterOptions = [
    { label: 'Active', value: 1 },
    { label: 'De-activated', value: 2 },
    { label: 'Inactive', value: 3 },
    { label: 'New', value: 4 },
    { label: 'No Record', value: 5 },
];
export const DefaultAhpraStatusFilterOptions = [
    { label: 'Registered', value: 'Registered' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Suspended', value: 'Suspended' },
    { label: 'Expired', value: 'Expired' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Invalid', value: 'Invalid' },
];

export const ENABLE_APP_HEADER_NOTIFICATION = "ENABLE-APP-HEADER-NOTIFICATION";  