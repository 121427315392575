import React, { useEffect, useState } from 'react';
import './GlobalMessage.scss';
import { Button, Modal } from 'react-bootstrap';
import { MessageDialogUtility } from '../../utils/MessageDialogUtility';

const GlobalMessage = () => {
    const [show, setShow] = useState(false);
    const [header, setHeader] = useState('');
    const [message, setMessage] = useState('');

    function close() {
        setShow(false);
    }

    useEffect(() => {
        MessageDialogUtility.headerHandler = headerMessage => {
            setHeader(headerMessage);
        };
        MessageDialogUtility.messageHandler = bodyMessage => {
            setMessage(bodyMessage);
            setShow(true);
        };
        
    }, []);

    return <Modal show={show} centered>
        <Modal.Header>
            <Modal.Title>{header}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>{message}</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="primary" onClick={() => close()}>Close</Button>
        </Modal.Footer>
    </Modal>;
};

export default GlobalMessage;
