const MILLISECONDS_IN_SECONDS = 1000;
const SECONDS_IN_MINUTES = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const DAYS_IN_WEEK = 7;

export const convertUTCDateToLocalDate = (date: string): Date => new Date(date);

export const isToday = (someDate: Date): boolean => {
	const today = new Date();
	return (
		someDate.getDate() === today.getDate() &&
		someDate.getMonth() === today.getMonth() &&
		someDate.getFullYear() === today.getFullYear()
	);
};

export const isThisWeek = (date: Date) => {
	const today = new Date();
	const currentDayOfWeek = today.getDay(); // An integer, between 0 and 6: 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on
	const daysSinceMonday = (currentDayOfWeek + 6) % 7;

	// Calculate the start of the week (Monday)
	const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - daysSinceMonday);
	startOfWeek.setHours(0, 0, 0, 0);

	// Calculate the end of the week (Sunday)
	const endOfWeek = new Date(startOfWeek);
	endOfWeek.setDate(endOfWeek.getDate() + 6);
	endOfWeek.setHours(23, 59, 59, 999);

	return date >= startOfWeek && date <= endOfWeek;
};

const pluralize = (count: number, noun: string) => `${count} ${noun}${count !== 1 ? 's' : ''}`;

export const timeAgo = (date: Date) => {
	const now = new Date();
	const diffInMilliseconds = now.getTime() - date.getTime();

	const seconds = Math.round(diffInMilliseconds / MILLISECONDS_IN_SECONDS);
	const minutes = Math.round(seconds / SECONDS_IN_MINUTES);
	const hours = Math.round(minutes / MINUTES_IN_HOUR);
	const days = Math.round(hours / HOURS_IN_DAY);
	const weeks = Math.round(days / DAYS_IN_WEEK);

	if (seconds === 0) return 'Now';
	if (seconds < SECONDS_IN_MINUTES) return pluralize(seconds, 'second') + ' ago';
	if (minutes < MINUTES_IN_HOUR) return pluralize(minutes, 'minute') + ' ago';
	if (hours < HOURS_IN_DAY) return pluralize(hours, 'hour') + ' ago';
	if (days < DAYS_IN_WEEK) return pluralize(days, 'day') + ' ago';
	return pluralize(weeks, 'week') + ' ago';
};

const isValidDate = (date?: Date | string | object | null | undefined): boolean => {
	if (typeof date === 'string') {
		return !isNaN(Date.parse(date));
	} else if (date instanceof Date) {
		return !isNaN(date.getTime());
	}
	return false;
};

const getDateObject = (input: Date | string, assumeUTC = false): Date | null => {
	if (!isValidDate(input)) return null;

	// If assumeUTC is true and the string doesn't end with 'Z', treat it as UTC
	if (assumeUTC && typeof input === 'string') {
		return new Date(input.endsWith('Z') ? input : `${input}Z`);
	}

	return typeof input === 'string' ? new Date(input) : input;
};

const pad = (num: number) => num.toString().padStart(2, '0');

const formatDateTime = (input: Date | string, { includeTime = true, utc = false } = {}): string => {
	const date = getDateObject(input, utc);

	if (!date) {
		return 'Invalid date';
	}

	const day = pad(date.getDate());
	const month = pad(date.getMonth() + 1);
	const year = date.getFullYear();

	let formattedDate = `${day}/${month}/${year}`;

	if (includeTime) {
		const hours = pad(date.getHours());
		const minutes = pad(date.getMinutes());
		formattedDate += ` ${hours}:${minutes}`;
	}

	return formattedDate;
};

const formatDateOnlyUTC = (input: Date | string) => {
	return formatDateTime(input, { includeTime: false, utc: true });
};

const formatDateAndTimeUTC = (input: Date | string) => {
	return formatDateTime(input, { includeTime: true, utc: true });
};

const formatDateOnlyLocal = (input: Date | string) => {
	return formatDateTime(input, { includeTime: false, utc: false });
};

const formatDateAndTimeLocal = (input: Date | string) => {
	return formatDateTime(input, { includeTime: true, utc: false });
};

export default {
	formatDateTime,
	formatDateOnlyUTC,
	formatDateAndTimeUTC,
	formatDateOnlyLocal,
	formatDateAndTimeLocal,
	isValidDate,
};
