import React, { forwardRef } from 'react';
import { classNames } from 'utils/util';
import './button.scss';
import Icon from 'components/Icons/Icon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const ButtonTypes = ['primary', 'secondary'] as string[];
type ButtonType = (typeof ButtonTypes)[number];

export const ButtonSizeTypes = ['large', 'medium', 'small'] as string[];
export type SizeType = (typeof ButtonSizeTypes)[number];

export const ButtonStatesTypes = ['isNormal', 'isProcessing', 'isDisabled'] as string[];
export type ButtonStatesType = (typeof ButtonStatesTypes)[number];

export interface ButtonProps {
	type?: ButtonType;
	size?: SizeType;
	state?: ButtonStatesType;
	buttonText?: string;
	leftIcon?: IconProp;
	rightIcon?: IconProp;
	onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
	className?: string;
	id?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			type = 'primary',
			size = 'large',
			state = 'isNormal',
			buttonText = 'Text',
			leftIcon,
			rightIcon,
			onClick,
			className,
			id,
			...props
		},
		ref
	) => {
		const classes = classNames([
			'ph-button-v2',
			{
				[type]: type,
				[size]: size,
				[state]: state,
			},
			className,
		]);
		return (
			<button
				ref={ref}
				id={id}
				className={classes}
				onClick={onClick}
				disabled={state === 'isDisabled' || state === 'isProcessing'}
				{...props}
			>
				{state === 'isProcessing' ? <Icon icon={['fal', 'spinner']} className="ph-loading-icon" /> : null}
				{leftIcon && <Icon icon={leftIcon} />}
				{buttonText}
				{rightIcon && <Icon icon={rightIcon} />}
			</button>
		);
	}
);

Button.displayName = 'Button';

export default Button;
