import { useState, useEffect } from 'react';
import apiClient, { ApiClient } from 'api/api';
import { ItemResponse, MenuItem, SubMenuUrl, UseMenuItems } from './utils/Interfaces';
import { addChildrenToParents, extractParentItemsAndSubMenuUrls, updateParentWithSubItems } from './utils/util';
import { getUrlComponents } from 'utils/util';

let client: ApiClient;

function useMenuItems(url: string): UseMenuItems {
	const [menuItems, setMenuItems] = useState([] as Array<MenuItem>);
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [subMenuUrls, setSubMenuUrls] = useState([] as Array<SubMenuUrl>);

	const {baseURL, path: pathPrefix} = getUrlComponents(url);

	if (!client) {
		client = apiClient(baseURL, pathPrefix);
	}

	useEffect(() => {
		fetchMenuData();
	}, []);

	useEffect(() => {
		const loadSubMenuItemsAsync = async () => {
			if (subMenuUrls.length > 0) {
				const items = await loadSubMenuItems(subMenuUrls);
				setMenuItems(items);
			}
		};

		loadSubMenuItemsAsync();
	}, [subMenuUrls]);

	async function fetchMenuData() {
		try {
			setIsLoading(true);
			setHasError(false);
			setMenuItems([]);
			setSubMenuUrls([]);

			const response = await client.get<ItemResponse[]>('/sidemenu/items/');
			const { parentItems, subMenuUrls } = extractParentItemsAndSubMenuUrls(response);
			const items = addChildrenToParents(response, parentItems);

			setMenuItems(items);
			setSubMenuUrls(subMenuUrls);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			setHasError(true);
		}
	}

	async function loadSubMenuItems(urls: SubMenuUrl[]) {
		try {
			return await loadSubItemsAndUpdateParents(urls, menuItems);
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	async function loadSubItemsAndUpdateParents(urls: SubMenuUrl[], menuItems: MenuItem[]): Promise<MenuItem[]> {
		let items: MenuItem[] = [...menuItems];
		const promises = urls.map(async (subMenuUrl) => {
			try {
				const response = await client.get<ItemResponse[]>(`/${subMenuUrl.menuItemsPath}/${subMenuUrl.id}/`);
				items = updateParentWithSubItems(response, subMenuUrl, items);
			} catch (error) {
				console.error(error);
			}
		});

		await Promise.all(promises);
		return items;
	}

	return { menuItems, setMenuItems, isLoading, hasError, fetchMenuData };
}

export default useMenuItems;
