import React from 'react';
import './iconbutton.scss';
import { classNames } from 'utils/util';
import Icon from 'components/Icons/Icon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PhIconProp } from 'components/Icons/FontAwesomeCustomIcons';
import { ButtonStatesType } from 'components/Button/Button';

export interface IconButtonProps {
	onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
	icon: IconProp | PhIconProp;
	className?: string;
	state?: ButtonStatesType;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
	({ onClick, icon, className, state = 'isNormal', ...props }, ref) => {
		const classes = classNames(['ph-iconButton', className]);
		return (
			<button className={classes} onClick={onClick} ref={ref}
				disabled={state === 'isDisabled' || state === 'isProcessing'} {...props}>
				{state === 'isProcessing' ? <Icon icon={['fal', 'spinner']} className="ph-loading-icon" /> : <Icon icon={icon} />}
			</button>
		);
	}
);

IconButton.displayName = 'IconButton';

export default IconButton;
