import apiClient, { ApiClient } from 'api/api';
import { removeUrlLastTrailingSlashes, getUrlComponents } from 'utils/util';
import { AuthorisationLookup, Authorization, AuthorisationResponse, User, UserReponse } from './Interfaces';

let client: ApiClient;

const ROLE_AVANT = 'Avant';
const ROLE_ADMINISTRATOR = 'Administrator';

const mapUserResponseToUser = (userResponse: UserReponse): User => {
	return {
		isAvantAdmin:
			userResponse.user.applicationRole === ROLE_AVANT &&
			userResponse.user.applicationSubRole === ROLE_ADMINISTRATOR,
		affiliationId: userResponse.affiliation.id,
		orgId: userResponse.organisation.id,
		orgName: userResponse.organisation.name,
		orgIsTrialPlan: userResponse.organisation.isTrialPlan,
		orgTrialEndDateUtc: userResponse.organisation.trialEndDateUtc,
		orgPracticeTypeId: userResponse.organisation.practiceTypeId,
		orgPracticeTypeName: userResponse.organisation.practiceTypeName,
		userId: userResponse.user.id,
		userCurrentRole: userResponse.currentRole,
		firstName: userResponse.user.givenName,
		lastName: userResponse.user.surname,
		email: userResponse.user.email,
		phone: userResponse.user.phone,
		impersonationId: userResponse.impersonationId,
		impersonationFirstName: userResponse.impersonationFirstName,
		impersonationLastName: userResponse.impersonationLastName,
	};
};

const createAuthorizationObject = (authorisationResponse: AuthorisationResponse[]): Authorization => {
	const authorisationLookup: AuthorisationLookup = {};

	for (const element of authorisationResponse) {
		if (!authorisationLookup[element.appObject]) authorisationLookup[element.appObject] = {};
		authorisationLookup[element.appObject][element.appAction] = element.actionFlag;
	}

	return {
		authorisationLookup: authorisationLookup,
		authorised: (object: string, action: string): boolean => {
			if (!authorisationLookup[object]) return false;
			return authorisationLookup[object][action];
		},
	};
};

function useUserSessionApi(apiUrl: string) {
	const { baseURL, path: pathPrefix } = getUrlComponents(apiUrl);

	if (!client) {
		client = apiClient(baseURL, removeUrlLastTrailingSlashes(pathPrefix));
	}

	const getUserWithAffiliationsAndAffiliations = (callback: (data: User) => void) => {
		client.get<any>('/affiliations/authenticated', {}).then((userResponse: UserReponse) => {
			const user = mapUserResponseToUser(userResponse);

			client
				.get<any>(`/${user.orgId}/${user.userCurrentRole}/authorisation`)
				.then((authorisationResponse: AuthorisationResponse[]) => {
					const authorisation: Authorization = createAuthorizationObject(authorisationResponse);

					client.get<any>(`/users/${user.userId}/affiliations`).then((affiliationsResponse) => {
						const affiliations = affiliationsResponse;

						user.authorisation = authorisation;
						user.affiliations = affiliations;

						callback(user);
					});
				});
		});
	};

	return { getUserWithAffiliationsAndAffiliations };
}

export default useUserSessionApi;
