import { v4 as uuidv4 } from 'uuid';

type ClassValue =
	| string
	| Record<string, boolean>
	| Record<string, string>
	| { [key: string]: boolean | undefined | string }
	| undefined;

const classNames = (classArray: ClassValue[]): string => {
	const classes = [];
	for (const item of classArray) {
		if (typeof item === 'string') {
			classes.push(item);
		} else if (typeof item === 'object' && item !== null) {
			for (const key in item) {
				if (item.hasOwnProperty(key) && item[key]) {
					classes.push(key);
				}
			}
		}
	}
	return classes.join(' ');
};

//Remove all last trailing slashes in Url
const removeUrlLastTrailingSlashes = (url: string): string => {
	while (url.endsWith('/')) {
		url = url.slice(0, -1);
	}
	return url;
};

//get Initial from name e.g. Peter will be P
const getInitial = (name: string): string => {
	let initial = name;
	if (initial) {
		initial = initial.charAt(0).toUpperCase();
	}

	return initial;
};

//Format currency with comma separated
//Default round up to the nearest dollar (no cents)
//#21694 We want to follow the Avant's guidelines on currency format, for current functionalities just comma separated format is enough
//Avant guidelines: https://practicehub.visualstudio.com/PracticeHub/_wiki/wikis/PracticeHub.wiki/211/Avant-Writing-Style-Guide
const formatCurrency = (value: number, fractionDigits = 0): string => {
	return value.toLocaleString('en-AU', {
		style: 'currency',
		currency: 'AUD',
		currencyDisplay: 'symbol',
		maximumFractionDigits: fractionDigits,
		minimumFractionDigits: fractionDigits,
	});
};

const generateUUID = (): string => {
	return uuidv4();
};

const handleInputHasMaxValue = (ev: React.KeyboardEvent<HTMLInputElement>, maxAmount: number) => {
	const numericCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

	if (numericCharacters.includes(ev.key)) {
		const newValue = parseFloat(ev.currentTarget.value.concat(ev.key));

		if (newValue > maxAmount) {
			ev.preventDefault();
			ev.stopPropagation();
			return false;
		}
	}
};

const getUrlComponents = (endpoint: string): { baseURL: string; path: string } => {
	const pathArray = endpoint.split('/');

	if (endpoint.startsWith('http')) {
		const protocol = pathArray[0];
		const host = pathArray[2];

		// Remove the protocol and host from the array so we are left with just the path sections.
		pathArray.splice(0, 3);
		return {
			baseURL: `${protocol}//${host}`,
			path: pathArray.join('/'),
		};
	}

	// Relative URL
	return {
		baseURL: '',
		path: endpoint,
	};
};

const generateCombinedKey = (key: string, index: number) => {
	return `${key}-${index}`;
};

const emptyFn = () => {};

export {
	classNames,
	removeUrlLastTrailingSlashes,
	getInitial,
	generateUUID,
	formatCurrency,
	handleInputHasMaxValue,
	getUrlComponents,
	generateCombinedKey,
	emptyFn,
};
