import { useEffect } from 'react';

const useAnimationEnd = (
	ref: React.RefObject<HTMLElement>,
	shouldRender: boolean,
	callback: (render: boolean) => void
) => {
	useEffect(() => {
		if (shouldRender) {
			callback(true);
		}

		const node = ref.current;

		if (node) {
			const handleTransitionEnd = () => {
				if (!shouldRender) {
					callback(false);
				}
			};
			node.addEventListener('animationend', handleTransitionEnd);
			return () => {
				node.removeEventListener('animationend', handleTransitionEnd);
			};
		}
	}, [shouldRender]);
};

export { useAnimationEnd };
