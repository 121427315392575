
      import API from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      
      import setAttributes from "!../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!./GlobalMessage.scss";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = function insertAfterOverrides(element) {
									var parent = document.querySelector('head');
									var overrideStyleElement = document.getElementById('override-styles');

									var lastInsertedElement =
										window._lastElementInsertedByStyleLoader;

									if (!lastInsertedElement) {
										parent.insertBefore(element, overrideStyleElement);
									} else if (lastInsertedElement.nextSibling) {
										parent.insertBefore(element, lastInsertedElement.nextSibling);
									} else {
										parent.appendChild(element);
									}

									// eslint-disable-next-line no-underscore-dangle
									window._lastElementInsertedByStyleLoader = element;

								};
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!./GlobalMessage.scss";
       export default content && content.locals ? content.locals : undefined;
