import { get, post } from './applicationApiActions';

export default class FileNotesApi {
    static createOrUpdate({ id, ahpraRegistrationId, note, linkedRecordId }) {
        return post('/FileNotes/CreateOrUpdate', { id, ahpraRegistrationId, note, linkedRecordId });
    }

    static get(linkedRecordId) {
        return get(`/FileNotes/Get?linkedRecordId=${linkedRecordId}`);
    }
}