import { RefObject, useEffect } from 'react';

function useCheckOutsideClick(ref: RefObject<HTMLDivElement | HTMLButtonElement>, callback: () => void) {
	function handleOutsideClick(event: MouseEvent) {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			callback();
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick, true);
		return () => {
			document.removeEventListener('click', handleOutsideClick, true);
		};
	}, []);
}

export default useCheckOutsideClick;
