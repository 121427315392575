import React, { Component } from 'react';
import './FilterCustom.scss';
import { Modal, ModalBody, Button } from 'react-bootstrap';
import { SaveFilterModal } from '@practicehub/central';
import { SelectList } from '../shared/SelectList/SelectList';
import { convertHtmlStringToString } from '../../formatters';
import * as Api from '../../api';

export class FilterCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            filterOptions: [],
            selectedFilterId: -1,
            selectedFilterLabel: '',
            selectedDefaultFilterId: -1,
            selectedDefaultFilterLabel: '',
            selectedFilterToOverwrite: null,
            showSaveFilterModal: false,
            filterName: '',
            isDefault: false,
            showWarning: false,
            warningMessage: '',
            warningActionText: '',
            warningActionColor: '',
            warningAction: null
        };
    }

    componentDidMount() {
        this.getAllFilters();
    }

    getAllFilters() {
        Api.filterApi.getAll().then((data) => {
            const options = data.reduce((optionsArray, f) => {
                const filterName = convertHtmlStringToString(f.name)
                return [
                    ...optionsArray,
                    {
                        name: filterName,
                        value: f.id,
                        key: f.id,
                        label: filterName
                    }
                ]
            }, [{
                name: 'All Items',
                value: -1,
                key: -1,
                label: 'All Items'
            }]);
            this.props.filterOptionsLoaded(options);
            this.setState({ filters: data, filterOptions: options }, () => {
                this.getDefaultFilter();
            });
        });
    }

    getDefaultFilter() {
        var selectedFilter = this.state.filters.find((f) => f.isDefault);
        if (selectedFilter) {
            this.props.setCurrentFilterOptions(selectedFilter.id);
            this.setState({
                selectedFilterId: selectedFilter.id,
                selectedFilterLabel: selectedFilter.name,
                selectedDefaultFilterId: selectedFilter.id,
                selectedDefaultFilterLabel: selectedFilter.name,
                isDefault: true
            });
        }
        else {
            if (this.props.preFiltered) return;
            var id = this.state.filterOptions.find((f) => f.value === -1).value;
            this.props.setCurrentFilterOptions(id);

            this.setState({
                selectedFilterId: id,
                isDefault: false
            }, () => { this.props.setDefaultFilters(false) });
        }
    }

    onFilterChange = (event) => {
        this.setAsSelectedFilter(parseInt(event.target.value));
    }

    setAsSelectedFilter(filterId) {
        var selectedFilter = this.state.filters.find((f) => f.id === filterId);
        if (selectedFilter) {
            this.props.setCurrentFilterOptions(selectedFilter.id);

            this.setState({
                selectedFilterId: selectedFilter.id,
                selectedFilterLabel: selectedFilter.name,
                isDefault: selectedFilter.isDefault,
                selectedDefaultFilterId: selectedFilter.isDefault ? selectedFilter.id : this.state.selectedDefaultFilterId,
                selectedDefaultFilterLabel: selectedFilter.isDefault ? selectedFilter.name : this.state.selectedDefaultFilterLabel
            }, () => {
                this.props.applySelectedFilter(selectedFilter)
            });
        }
        else {
            var id = this.state.filterOptions.find((f) => f.value === -1).value;
            this.props.setCurrentFilterOptions(id);

            this.setState({
                selectedFilterId: id,
                isDefault: false
            }, () => { this.props.setDefaultFilters(true) });
        }
    }

    handleNameChange = (event) => {
        let updatedState = Object.assign({}, this.state);
        updatedState[event.target.name] = event.target.value;
        this.setState(updatedState);
    }

    addFilter = () => {
        if (this.state.filterName.length == 0) {
            return;
        }

        var filterDto = {
            name: this.state.filterName,
            settings: JSON.stringify(this.props.createFilterSettings())
        };

        Api.filterApi.add(filterDto).then((result) => {
            var newFilterOption = {
                name: result.name,
                value: result.id,
                key: result.id,
                label: result.name
            };

            let options = [...this.state.filterOptions, newFilterOption];

            let filters = [
                ...this.state.filters,
                {
                    id: result.id,
                    isDefault: result.isDefault,
                    name: result.name,
                    orgId: result.orgId,
                    settings: result.settings,
                    userId: result.userId
                }
            ];

            let updatedState = Object.assign({}, this.state);
            updatedState['filterOptions'] = options;
            updatedState['filters'] = filters;
            updatedState['selectedFilterId'] = newFilterOption.value;
            updatedState['showSaveFilterModal'] = false;
            updatedState['selectedFilterToOverwrite'] = null;
            updatedState['isDefault'] = false;
            this.setState(updatedState);
            this.props.setFiltersWithLatestValues(filters);
            this.props.applySelectedFilter(result);
            this.props.filterOptionsLoaded(options);
            this.props.setCurrentFilterOptions(result.id);
        });
    }

    handleUpdateFilterChange = (filterId) => {
        const filter = this.state.filterOptions.find((f) => f.value == filterId);
        this.setState({ selectedFilterToOverwrite: filter });
    };

    updateFilter = () => {
        const { selectedFilterToOverwrite } = this.state;
        if (selectedFilterToOverwrite == null) {
            return;
        }

        var filterDto = {
            id: selectedFilterToOverwrite.value,
            name: selectedFilterToOverwrite.name,
            settings: JSON.stringify(this.props.createFilterSettings())
        };

        Api.filterApi.update(filterDto).then((result) => {
            // Update filter options
            var optionIndex = this.state.filterOptions.findIndex((o) => o.value == result.id);
            let options = [...this.state.filterOptions];
            let updatedFilterOption = {
                ...options[optionIndex],
                name: convertHtmlStringToString(result.name),
                label: convertHtmlStringToString(result.name)
            };

            options[optionIndex] = updatedFilterOption;

            // Update filter list
            var filterIndex = this.state.filters.findIndex((f) => f.id == result.id);
            let filters = [...this.state.filters];
            let updatedFilter = {
                ...filters[filterIndex],
                isDefault: result.isDefault,
                settings: result.settings,
                name: convertHtmlStringToString(result.name)
            };

            filters[filterIndex] = updatedFilter;

            let updatedState = Object.assign({}, this.state);
            updatedState['filterOptions'] = options;
            updatedState['filters'] = filters;
            updatedState['selectedFilterId'] = updatedFilterOption.value;
            updatedState['showSaveFilterModal'] = false;
            updatedState['selectedFilterToOverwrite'] = null;
            updatedState['isDefault'] = result.isDefault;
            if (result.isDefault) {
                updatedState['selectedFilterLabel'] = newFilterOption.name;
                updatedState['selectedDefaultFilterId'] = updatedFilterOption.value;
                updatedState['selectedDefaultFilterLabel'] = updatedFilterOption.name;
            }
            this.setState(updatedState);
            this.props.setFiltersWithLatestValues(filters);
            this.props.applySelectedFilter(result);
            this.props.filterOptionsLoaded(options);
            this.props.setCurrentFilterOptions(result.id);
        });
    }

    updateDefaultFilter = (makeDefault) => {

        var filterDto = {
            id: this.state.selectedFilterId,
            isDefault: makeDefault
        };

        Api.filterApi.updateDefault(filterDto).then(() => {
            let filtersToUpdate = [...this.state.filters];

            // Remove current default filter
            var currentDefaultFilterIndex = this.state.filters.findIndex((f) => f.isDefault);
            if (currentDefaultFilterIndex > - 1) {
                let currentDefaultFilter = {
                    ...filtersToUpdate[currentDefaultFilterIndex],
                    isDefault: false
                };

                filtersToUpdate[currentDefaultFilterIndex] = currentDefaultFilter;
            }

            let updatedState = Object.assign({}, this.state);

            // Set new default filter
            if (makeDefault) {
                var newDefaultFilterIndex = this.state.filters.findIndex((f) => f.id == this.state.selectedFilterId);
                let newDefaultFilter = {
                    ...filtersToUpdate[newDefaultFilterIndex],
                    isDefault: true
                };

                filtersToUpdate[newDefaultFilterIndex] = newDefaultFilter;

                updatedState['isDefault'] = true;
                updatedState['selectedDefaultFilterId'] = newDefaultFilter.id;
                updatedState['selectedDefaultFilterLabel'] = newDefaultFilter.name;
            } else {

                updatedState['isDefault'] = false;
                updatedState['selectedDefaultFilterId'] = -1;
                updatedState['selectedDefaultFilterLabel'] = '';
            }
            updatedState['filters'] = filtersToUpdate;
            this.setState(updatedState);

            this.props.setBaseSettings();
        });
    }

    deleteFilterWarning = (event) => {
        event.preventDefault();
        this.setState({
            showWarning: true,
            warningMessage: 'Confirm deleting filter',
            warningActionText: 'Delete',
            warningActionColor: 'primary',
            warningAction: this.deleteFilter
        });
    };

    deleteFilter = () => {
        const { selectedFilterId } = this.state;

        Api.filterApi.delete(selectedFilterId).then(() => {
            let updatedState = Object.assign({}, this.state);

            // For resetting base setting to all items
            var wasDefaultFilter = this.state.filters.find((f) => f.id == selectedFilterId).isDefault;
            if (wasDefaultFilter) {
                this.props.resetBaseSettings();
                updatedState['isDefault'] = false;

            }

            // Remove deleted filter from options
            var options = this.state.filterOptions.filter((o) => o.value != selectedFilterId);
            var optionIndex = this.state.filterOptions.findIndex((o) => o.value == selectedFilterId);
            if (!options[optionIndex]) {
                optionIndex--;
            }

            // Remove deleted filter from filter list
            var filters = this.state.filters.filter((f) => f.id != selectedFilterId);
            var filterIndex = this.state.filters.findIndex((f) => f.id == selectedFilterId);
            if (!filters[filterIndex]) {
                filterIndex--;
            }

            updatedState['filterOptions'] = options;
            updatedState['filters'] = filters;
            updatedState.showWarning = false;
            this.setState(updatedState);
            this.props.filterOptionsLoaded(options);

            var defaultFilter = filters.find((f) => f.isDefault);
            if (defaultFilter) {
                optionIndex = this.state.filterOptions.findIndex((o) => o.key == defaultFilter.id);
            }
            if (optionIndex != 0) {
                this.setAsSelectedFilter(options[optionIndex].value);
            } else {
                this.props.setDefaultFilters(true);
            }
        });
    }

    toggleShowSaveFilterModal() {
        this.setState({
            showSaveFilterModal: !this.state.showSaveFilterModal,
            selectedFilterToOverwrite: null,
            filterName: ''
        });
    }

    setToAllItems() {
        this.setState({ selectedFilterId: this.state.filterOptions.find((f) => f.value === -1).value });
    }

    setToDefaultFilter() {
        this.setState({
            selectedFilterId: this.state.selectedDefaultFilterId,
            selectedFilterLabel: this.state.selectedDefaultFilterLabel,
            isDefault: true
        });
    }

    render() {
        const {
            showSaveFilterModal,
            filterOptions,
            selectedFilterId,
            selectedFilterLabel,
            isDefault,
            showWarning,
            warningMessage,
            warningActionText,
            warningActionColor,
            warningAction
        } = this.state;

        return (
            <>
                <Modal
                    show={showSaveFilterModal}
                    onHide={() => this.toggleShowSaveFilterModal()}
                    size='lg'>
                    <ModalBody style={{ padding: 0 }}>
                        <SaveFilterModal
                            onClose={() => this.toggleShowSaveFilterModal()}
                            profileOptions={filterOptions.filter((f) => f.value != -1)}
                            handleNameChange={this.handleNameChange}
                            handleProfileChange={this.handleUpdateFilterChange}
                            addProfile={this.addFilter}
                            updateProfile={this.updateFilter} />
                    </ModalBody>
                </Modal>

                <div className="row">
                    <div className="col">
                        <div id="custom-filters" className={`card ${showWarning ? 'background-orange' : ''}`}>
                            <div className="card-body" style={{ padding: '25px 25px 25px 25px' }}>
                                {showWarning && (
                                    <div
                                        className='col-12 mt-0 mb-2 p-2 text-dark text-center'
                                        style={{ width: '3000px', maxHeight: '200px' }}
                                    >
                                        {warningMessage}
                                        <div style={{ fontWeight: 'bold' }}>
                                            "{selectedFilterLabel}"
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <Button
                                                    color='secondary'
                                                    autoFocus={true}
                                                    onClick={() => { this.setState({ showWarning: false }); }}
                                                    style={{ textTransform: 'none' }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className='col-6'>
                                                <Button
                                                    color={warningActionColor}
                                                    className='mr-2 ml-2'
                                                    onClick={warningAction}
                                                    style={{ textTransform: 'none' }}
                                                >
                                                    {warningActionText}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!showWarning && (
                                    <div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="control-label" htmlFor="lstFilters">Manage Filters</label>
                                                    <SelectList
                                                        placeholder='Select Filter'
                                                        name='selectedFilterId'
                                                        label=''
                                                        options={filterOptions}
                                                        value={selectedFilterId}
                                                        onChange={this.onFilterChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 float-left">
                                                <div className="form-group float-left">
                                                    <a id="btnMakeDefaultFilter"
                                                        href="#" style={{ fontSize: '14px' }}
                                                        onClick={(evt) => { evt.preventDefault(); this.updateDefaultFilter(!isDefault) }}
                                                        className={`${selectedFilterId == -1
                                                            ? 'disable-link'
                                                            : 'cursor-pointer text-color-blue'
                                                            }`}
                                                    >
                                                        {isDefault && (
                                                            <>
                                                                <i className='fal fa-star'></i>{' '}
                                                                &nbsp; Default
                                                            </>
                                                        )}
                                                        {!isDefault && (
                                                            <>Make Default</>
                                                        )}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-6 float-right">
                                                <div className="form-group float-right">
                                                    <a id="btnDeleteFilter" href="#" style={{ fontSize: '14px' }}
                                                        onClick={this.deleteFilterWarning}
                                                        className={`${selectedFilterId == -1
                                                            ? 'disable-link'
                                                            : 'cursor-pointer text-color-blue'
                                                            }`}
                                                    >
                                                        Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}