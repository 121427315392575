export const isEmpty = (obj) => {
    let key;
    
    for(key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }

    return true;

};

export const dynamicSort = (property) => {

    let sortOrder = 1;
    
    if(property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }

    return (a,b) => {
        if(!a || !a[property]) {
            return false;
        }
        if(!b || !b[property]) {
            return true;
        }
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    };
};

export const objectToQueryString = (obj) => {

    if(!obj) return '';

	const queryParams = Object.keys(obj).reduce((params, key) => {

		if(Array.isArray(obj[key])) {
			
			params = params.concat(obj[key].map((i) => `${key}=${i}`));

		} else if(obj[key] !== '') {
			params.push(`${key}=${obj[key]}`);
		}

		return params;

	}, []);

	return queryParams.join('&');

};

export const truncate = (str, characters) => {

    return str.length <= characters ? str : `${str.substring(0, characters).trim()}...`;

};

export const deepClone = (obj) => {

    return JSON.parse(JSON.stringify(obj));

};