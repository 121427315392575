import React, { Component } from 'react';
import './RegistrationList.scss';
import { FilterCustom } from '../FilterCustom/FilterCustom';
import { ConfirmDialogUtility } from '../../utils/ConfirmDialogUtility';
import { RegistrationListFilters } from '../RegistrationListFilter/RegistrationListFilters';
import { FilterHeader } from '../FilterHeader/FilterHeader';
import Heading from '../shared/Heading/Heading';
import RegistrationListHead from './RegistrationListHead';
import RegistrationListRow from './RegistrationListRow';
import { MessageDialogUtility } from '../../utils/MessageDialogUtility';
import { Link } from 'react-router-dom';
import { GlobalAlertUtility } from '../../utils/GlobalAlertUtility';
import { SuccessMessage, defaultAllOptionValue, defaultAllOptionValue2 } from '../../Constants';
import * as Api from '../../api';
import { StipulationFilterOptions } from '../../utils/enums';

export class RegistrationList extends Component {
    constructor(props) {
        super(props);

        const filters = this.props.filters;

        this.state = {
            filtersVisible: false,
            registrationRecords: [],
            loadNumber: 1,
            baseSettings: '',
            userFilters: filters.userFilter,
            statusFilters: filters.statusFilter,
            professionFilters: filters.professionFilter,
            clinicalServiceTypeFilters: filters.clinicalServiceTypeFilter,
            practiceFilters: filters.practiceFilter,
            dateFilter: filters.dateFilter || -1,
            dateFilterFrom: filters.dateFilterFrom || '',
            dateFilterTo: filters.dateFilterTo || '',
            provisioningStatusFilters: filters.provisioningStatusFilter,
            stipulationFilters: filters.stipulationFilter,
            alertTypeFilters: filters.alertTypeFilter,
            alertStatusFilters: filters.alertStatusFilter,
            isLoading: true,
            rowCount: 1,
            preFiltered: filters.preFiltered,
            isLicenseAvailable: false,
            filters: [],
            filterOptions: [],
            currentFilterOptionId: -1
        };

        this.activateRecord = this.activateRecord.bind(this);
        this.deactivateRecord = this.deactivateRecord.bind(this);
        this.setRecordInactive = this.setRecordInactive.bind(this);        
    }

    componentDidMount() {
        document.title = 'Ahpra Alerts - Registration List';
        this.getRegistrationRecordsByFilter();
    }

    getRegistrationRecordsByFilter() {
        Api.filterApi.getAll().then((result) => {
            var selectedFilter = result.find((f) => f.isDefault);
            if (selectedFilter && !this.state.preFiltered) {
                var settings = JSON.parse(selectedFilter.settings);
                this.setState({
                    userFilters: settings.userFilters != null ? settings.userFilters : this.state.userFilters,
                    clinicalServiceTypeFilters: settings.clinicalServiceTypeFilters != null ? settings.clinicalServiceTypeFilters : this.state.clinicalServiceTypeFilters,
                    professionFilters: settings.professionFilters != null ? settings.professionFilters : this.state.professionFilters,
                    practiceFilters: settings.practiceFilters != null ? settings.practiceFilters : this.state.practiceFilters,
                    statusFilters: settings.statusFilters != null ? settings.statusFilters : this.state.statusFilters,
                    dateFilter: settings.dateFilter != null ? settings.dateFilter : this.state.dateFilter,
                    dateFilterFrom: settings.dateFilterFrom != null || settings.dateFilterFrom != '' ? settings.dateFilterFrom : this.state.dateFilterFrom,
                    dateFilterTo: settings.dateFilterTo != null || settings.dateFilterTo != '' ? settings.dateFilterTo : this.state.dateFilterTo,
                    provisioningStatusFilters: settings.provisioningStatusFilters != null ? settings.provisioningStatusFilters : this.state.provisioningStatusFilters,
                    stipulationFilters: settings.stipulationFilters != null ? settings.stipulationFilters : this.state.stipulationFilters,
                    alertTypeFilters: settings.alertTypeFilters != null ? settings.alertTypeFilters : this.state.alertTypeFilters,
                    alertStatusFilters: settings.alertStatusFilters != null ? settings.alertStatusFilters : this.state.alertStatusFilters,
                }, () => {
                    this.setBaseSettings();
                    this.getRegistrationsRecords();
                });
            }
            else {
                this.getRegistrationsRecords();
            }
        });
    }

    /**
     * validate the filter values to be passed to backend, if the array contains default All option value, then just return an empty array 
     * @param {*} filter 
     * @returns return an empty array if filter is empty or contains default All option value, then otherwise return as is
     */
    passedFilterValues(filter) {
        return !filter || filter.includes(defaultAllOptionValue) || filter.includes(defaultAllOptionValue2) ? [] : filter
    }

    mapStipulationFilterValues(filter) {
        var tempFilter = this.passedFilterValues(filter);
        let result = [];
        if (tempFilter.length > 0 && !tempFilter.includes(defaultAllOptionValue) && !tempFilter.includes(defaultAllOptionValue2)) {
            if (tempFilter.includes(StipulationFilterOptions.WithStipulations)) {
                result.push(StipulationFilterOptions.WithStipulations);
            }
            if (tempFilter.includes(StipulationFilterOptions.WithoutStipulations)) {
                result.push(StipulationFilterOptions.WithoutStipulations);
            }
        }
        return result;
    }

    getRegistrationsRecords() {
        this.setState({ isLoading: true });
        
        Api.registrationApi.getAll({
            userFilters: this.passedFilterValues(this.state.userFilters),
            clinicalServiceTypeFilters: this.passedFilterValues(this.state.clinicalServiceTypeFilters),
            professionFilters: this.passedFilterValues(this.state.professionFilters),
            practiceFilters: this.passedFilterValues(this.state.practiceFilters),
            statusFilters: this.passedFilterValues(this.state.statusFilters),
            dateFilter: this.state.dateFilter,
            dateFilterFrom: this.state.dateFilterFrom === '' ? null : new Date(this.state.dateFilterFrom),
            dateFilterTo: this.state.dateFilterTo === '' ? null : new Date(this.state.dateFilterTo),
            rowCount: 1,
            provisioningStatusFilters: this.passedFilterValues(this.state.provisioningStatusFilters),
            stipulationFilters: this.mapStipulationFilterValues(this.state.stipulationFilters),
            alertTypeFilters: this.passedFilterValues(this.state.alertTypeFilters),
            alertStatusFilters: this.passedFilterValues(this.state.alertStatusFilters)
        }).then((result) => {
            Api.licenseApi.isLicenseAvailable().then((licenseResult) => {
                if (licenseResult === true) {
                    this.setState({
                        isLicenseAvailable: true,
                        registrationRecords: result,
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        isLicenseAvailable: false,
                        registrationRecords: result,
                        isLoading: false
                    });
                }
            });
        });
    }

    loadFilteredRegistrationRecords() {
        this.setState({ registrationRecords: [] }, () => {
            this.getRegistrationsRecords()
        });
    }

    /**
     * selected user and staffs
     * @param {*} values 
     */
    userFilterChange = (values) => {
        this.setState({ userFilters: values }, () => {
            this.loadFilteredRegistrationRecords()
        });
    }

    statusFilterChange = (values) => {
        this.setState({ statusFilters: values }, () => {
            this.loadFilteredRegistrationRecords()
        });
    }

    provisioningFilterChange = (values) => {
        this.setState({ provisioningStatusFilters: values }, () => {
            this.loadFilteredRegistrationRecords()
        });
    }

    professionFilterChange = (values) => {
        this.setState({ professionFilters: values }, () => {
            this.loadFilteredRegistrationRecords()
        });
    }

    clinicalServiceTypeFilterChange = (values) => {
        this.setState({ clinicalServiceTypeFilters: values }, () => {
            this.loadFilteredRegistrationRecords()
        });
    }

    practiceFilterChange = (values) => {
        this.setState({ practiceFilters: values }, () => {
            this.loadFilteredRegistrationRecords()
        });
    }

    dateFilterChange = (event) => {
        event.persist();
        switch (event.target.name) {
            case 'dateFilter':
                this.setState({ dateFilter: +event.target.value }, () => {
                    if (event.target.value !== 3) {
                        this.loadFilteredRegistrationRecords();
                    }
                });
                break;
            case 'dateFilterFrom':
                this.setState({ dateFilterFrom: event.target.value });
                break;
            case 'dateFilterTo':
                this.setState({ dateFilterTo: event.target.value });
                break;
        }
    }

    updateCustomDateFilter = (event) => {
        event.preventDefault();
        this.loadFilteredRegistrationRecords();
    };

    stipulationsFilterChange = (values) => {
        this.setState({ stipulationFilters: values }, () => {
            this.loadFilteredRegistrationRecords();
        });
    }

    alertTypeFilterChange = (values) => {
        this.setState({ alertTypeFilters: values }, () => {
            this.loadFilteredRegistrationRecords();
        });
    }

    alertStatusFilterChange = (values) => {
        this.setState({ alertStatusFilters: values }, () => {
            this.loadFilteredRegistrationRecords();
        });
    }

    resetAllFilters = () => {
        if (this.state.baseSettings !== '') {
            this.setState(this.state.baseSettings);
            this.customFilterRef.setToDefaultFilter();
            this.loadFilteredRegistrationRecords();
        } else {
            this.setDefaultFilters(true);
        }
    };

    setBaseSettings = () => {
        this.setState({ baseSettings: this.createFilterSettings() });
    };

    createFilterSettings = () => {
        return {
            userFilters: this.state.userFilters,
            clinicalServiceTypeFilters: this.state.clinicalServiceTypeFilters,
            professionFilters: this.state.professionFilters,
            practiceFilters: this.state.practiceFilters,
            statusFilters: this.state.statusFilters,
            dateFilter: this.state.dateFilter,
            dateFilterFrom: this.state.dateFilter != 3 ? '' : this.state.dateFilterFrom,
            dateFilterTo: this.state.dateFilter != 3 ? '' : this.state.dateFilterTo,
            provisioningStatusFilters: this.state.provisioningStatusFilters,
            stipulationFilters: this.state.stipulationFilters,
            alertTypeFilters: this.state.alertTypeFilters,
            alertStatusFilters: this.state.alertStatusFilters
        };
    };

    applySelectedFilter = (selectedFilter) => {
        if (!selectedFilter) return;
        var settings = JSON.parse(selectedFilter.settings);
        this.setState({
            userFilters: settings.userFilters != null ? settings.userFilters : this.state.userFilters,
            clinicalServiceTypeFilters: settings.clinicalServiceTypeFilters != null ? settings.clinicalServiceTypeFilters : this.state.clinicalServiceTypeFilters,
            professionFilters: settings.professionFilters != null ? settings.professionFilters : this.state.professionFilters,
            practiceFilters: settings.practiceFilters != null ? settings.practiceFilters : this.state.practiceFilters,
            statusFilters: settings.statusFilters != null ? settings.statusFilters : this.state.statusFilters,
            dateFilter: settings.dateFilter != null ? settings.dateFilter : this.state.dateFilter,
            dateFilterFrom: settings.dateFilterFrom != null ? settings.dateFilterFrom : this.state.dateFilterFrom,
            dateFilterTo: settings.dateFilterTo != null ? settings.dateFilterTo : this.state.dateFilterTo,
            provisioningStatusFilters: settings.provisioningStatusFilters != null ? settings.provisioningStatusFilters : this.state.provisioningStatusFilters,
            stipulationFilters: settings.stipulationFilters != null ? settings.stipulationFilters : this.state.stipulationFilters,
            alertTypeFilters: settings.alertTypeFilters != null ? settings.alertTypeFilters : this.state.alertTypeFilters,
            alertStatusFilters: settings.alertStatusFilters != null ? settings.alertStatusFilters : this.state.alertStatusFilters
        }, () => {
            if (selectedFilter.isDefault) {
                this.setBaseSettings();
            }
            this.loadFilteredRegistrationRecords();
        });

    }

    saveFilters = () => {
        this.customFilterRef.toggleShowSaveFilterModal();
    }

    setDefaultFilters = (isRefresh) => {
        this.setState({
            userFilters: [defaultAllOptionValue],
            clinicalServiceTypeFilters: [defaultAllOptionValue],
            professionFilters: [defaultAllOptionValue],
            practiceFilters: [defaultAllOptionValue],
            statusFilters: [defaultAllOptionValue],
            dateFilter: -1,
            dateFilterFrom: '',
            dateFilterTo: '',
            provisioningStatusFilters: [defaultAllOptionValue],
            stipulationFilters: [defaultAllOptionValue],
            alertTypeFilters: [defaultAllOptionValue],
            alertStatusFilters: [defaultAllOptionValue]
        }, () => {
            if (isRefresh) {
                this.customFilterRef.setToAllItems();
                this.loadFilteredRegistrationRecords();
            }
        });
    };

    resetBaseSettings = () => {
        this.setState({ baseSettings: '' });
    }

    setFiltersWithLatestValues = (filters) => {
        this.setState({ filters: filters });
    }

    filterOptionsLoaded = (options) => {
        this.setState({ filterOptions: options });
    };

    setCurrentFilterOptions = (optionId) => {
        this.setState({ currentFilterOptionId: optionId });
    };

    renderFilters() {
        if (!this.state.filtersVisible)
            return null;

        return <div className="col-3">
            <RegistrationListFilters
                selectedUsers={this.state.userFilters}
                selectedClinicalServiceTypes={this.state.clinicalServiceTypeFilters}
                selectedProfession={this.state.professionFilters}
                selectedPractices={this.state.practiceFilters}
                selectedStatus={this.state.statusFilters}
                selectedDate={this.state.dateFilter}
                selectedDateFrom={this.state.dateFilterFrom}
                selectedDateTo={this.state.dateFilterTo}
                selectedProvisionings={this.state.provisioningStatusFilters}
                selectedStipulations={this.state.stipulationFilters}
                selectedAlertType={this.state.alertTypeFilters}
                selectedAlertStatus={this.state.alertStatusFilters}
                userFilterChange={this.userFilterChange}
                clinicalServiceTypeFilterChange={this.clinicalServiceTypeFilterChange}
                professionFilterChange={this.professionFilterChange}
                practiceFilterChange={this.practiceFilterChange}
                statusFilterChange={this.statusFilterChange}
                dateFilterChange={this.dateFilterChange}
                updateCustomDateFilter={this.updateCustomDateFilter}
                stipulationsFilterChange={this.stipulationsFilterChange}
                resetAllFilters={this.resetAllFilters}
                provisioningFilterChange={this.provisioningFilterChange}
                alertTypeFilterChange={this.alertTypeFilterChange}
                alertStatusFilterChange={this.alertStatusFilterChange}
                saveFilters={this.saveFilters}
            />

            <FilterCustom
                ref={(ref) => (this.customFilterRef = ref)}
                preFiltered={this.props.filters.preFiltered}
                setBaseSettings={this.setBaseSettings}
                createFilterSettings={this.createFilterSettings}
                applySelectedFilter={this.applySelectedFilter}
                setDefaultFilters={this.setDefaultFilters}
                resetBaseSettings={this.resetBaseSettings}
                filterOptionsLoaded={this.filterOptionsLoaded}
                setCurrentFilterOptions={this.setCurrentFilterOptions}
                setFiltersWithLatestValues={this.setFiltersWithLatestValues}
            />
        </div>;
    }

    renderFilterHeader() {
        return <FilterHeader
            ref={(ref) => (this.customFilterRef = ref)}
            preFiltered={this.props.filters.preFiltered}
            filterOptions={this.state.filterOptions}
            filters={this.state.filters}
            currentFilterOptionId={this.state.currentFilterOptionId}
            setBaseSettings={this.setBaseSettings}
            createFilterSettings={this.createFilterSettings}
            applySelectedFilter={this.applySelectedFilter}
            setDefaultFilters={this.setDefaultFilters}
            resetBaseSettings={this.resetBaseSettings}
        />
    }

    deactivateRecord(registrationRecord) {
        ConfirmDialogUtility.showConfirmDialog(`Are you sure you want to deactivate '${registrationRecord.firstName + ' ' + registrationRecord.lastName}'?`, deactivateResult => {
            if (deactivateResult) {
                ConfirmDialogUtility.showConfirmDialog('Do you wish to also make this record inactive?', inactiveResult => {
                    if (inactiveResult) {
                        Api.linkedRecordsApi.updateRecordStatus(registrationRecord.linkedRecordId, 3).then(() => {
                            this.loadFilteredRegistrationRecords();
                            GlobalAlertUtility.showSuccess(SuccessMessage.USER_INACTIVATE.replace('{0}', registrationRecord.firstName + ' ' + registrationRecord.lastName));
                        });
                    }
                    else {
                        Api.linkedRecordsApi.updateRecordStatus(registrationRecord.linkedRecordId, 2).then(() => {
                            this.loadFilteredRegistrationRecords();
                            GlobalAlertUtility.showSuccess(SuccessMessage.USER_DEACTIVATE.replace('{0}', registrationRecord.firstName + ' ' + registrationRecord.lastName));
                        });
                    }

                });
            }
        });
    }

    setRecordInactive(registrationRecord) {
        ConfirmDialogUtility.showConfirmDialog(`Are you sure you want to make '${registrationRecord.firstName + ' ' + registrationRecord.lastName}' inactive?`, result => {
            if (result) {
                Api.linkedRecordsApi.updateRecordStatus(registrationRecord.linkedRecordId, 3).then(() => {
                    this.loadFilteredRegistrationRecords();
                    GlobalAlertUtility.showSuccess(SuccessMessage.USER_INACTIVATE.replace('{0}', registrationRecord.firstName + ' ' + registrationRecord.lastName));
                });
            }
        });
    }

    activateRecord(registrationRecord) {
        if (registrationRecord.isActive) {
            ConfirmDialogUtility.showConfirmDialog(`Are you sure you would like to activate the Ahpra record for '${registrationRecord.firstName + ' ' + registrationRecord.lastName}'?`, result => {
                if (result) {
                    var status = 4;
                    if (!this.state.isLicenseAvailable) status = 6;                    

                    if (registrationRecord.provisioningStatus === 'NoRecord') {
                        Api.linkedRecordsApi.createRecord(registrationRecord.staffRecordId, registrationRecord.userId, this.state.isLicenseAvailable).then((linkedRecord) => {
                            const linkedRecordId = registrationRecord.linkedRecordId && registrationRecord.linkedRecordId > 0 ?
                                linkedRecord.id : linkedRecord.id && linkedRecord.id > 0 ? linkedRecord.id : null;
                            if (linkedRecordId) {
                                Api.linkedRecordsApi.updateRecordStatus(registrationRecord.linkedRecordId, status).then(() => {
                                    this.loadFilteredRegistrationRecords();
                                    GlobalAlertUtility.showSuccess(SuccessMessage.USER_ACTIVATE.replace('{0}', registrationRecord.firstName + ' ' + registrationRecord.lastName));
                                });
                            } else {
                                GlobalAlertUtility.showError('Failed creating a new record.');
                            }
                        }).catch(() => GlobalAlertUtility.showError('Failed creating a new record.'));
                    }
                    else {
                        Api.linkedRecordsApi.updateRecordStatus(registrationRecord.linkedRecordId, status).then(() => {
                            this.loadFilteredRegistrationRecords();
                            GlobalAlertUtility.showSuccess(SuccessMessage.USER_ACTIVATE.replace('{0}', registrationRecord.firstName + ' ' + registrationRecord.lastName));
                        });
                    }
                }
            }, this.state.isLicenseAvailable ? null : 'You have no remaining licences. A record will be added, however you will only be able to edit this user record after you purchased more licences.');
        }
        else {
            MessageDialogUtility.showMessageDialog('Cannot Activate Record', `Cannot activate the record because the ${registrationRecord.staffRecordId ? 'staff record' : 'user'} '${registrationRecord.firstName + ' ' + registrationRecord.lastName}' is deactivated`);
        }
    }

    render() {
        const isAdmin = true;
        return <div className="RegistrationList">
            <Heading
                colClasses='col-9'
                title="Registration List"
                titleElement={
                    <h3 title="Registration List" className='overflow-inner'>
                        <span className='page-title-inner'>Registration List</span>
                    </h3>
                }
                breadcrumbs={
                    <div className='col-md-12'>
                        <ol className='breadcrumb float-left'>
                            <li className='breadcrumb-item'>
                                <Link to='/'>Ahpra Alerts</Link>
                            </li>
                            <li className='breadcrumb-item active'>
                                <span>Registration List</span>
                            </li>
                        </ol>
                    </div>
                }
            />

            <div className='col-3 pr-3' style={{ float: 'right' }}>
                <div className='row'>
                    <div className='col-md-12 mt-2 text-right'>
                        <button
                            type='button'
                            className='btn btn-default'
                            onClick={() => this.setState({ filtersVisible: !this.state.filtersVisible })}>
                            Filters&nbsp;&nbsp;<i className='far fa-sliders-h'></i>
                        </button>
                    </div>
                </div>
            </div>
            <div style={{ clear: 'both' }} />

            {/* <div className="row">

            <Breadcrumb>
                <Link to="/" className="breadcrumb-item">Ahpra</Link>
                <Breadcrumb.Item active>Registrations List</Breadcrumb.Item>
            </Breadcrumb>
        </div> */}
            <div className="row scrollable-content">
                <div className={`${this.state.filtersVisible ? 'col-9' : 'col-12'}`}>
                    <div className="card">
                        {this.renderFilterHeader()}
                        <div className="card-body">
                            <table className="table">
                                <RegistrationListHead />
                                <tbody>
                                    {!this.state.isLoading && this.state.registrationRecords.map((registrationRecord, i) =>
                                        <RegistrationListRow
                                            key={i}
                                            registrationRecord={registrationRecord}
                                            activateRecord={this.activateRecord}
                                            deactivateRecord={this.deactivateRecord}
                                            setRecordInactive={this.setRecordInactive}
                                        />
                                    )}
                                    {this.state.isLoading &&
                                        <tr>
                                            <td colSpan="10">
                                                <div className="text-center pt-4 pb-4">
                                                    <div className="fa fa-spinner fa-spin display-4"></div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {this.renderFilters()}
            </div>
        </div>;
    }
}