import apiClient, { ApiClient } from 'api/api';
import { useState, useEffect } from 'react';
import { EMPTY_NOTIFICATIONS, GET_NOTIFICATIONS_URL, UPDATE_NOTIFICATIONS_URL } from './utils/constants';
import { UseNotification } from 'components/Notification';
import { AppHeaderNotificationResponse } from './utils/Interfaces';
import { getUrlComponents } from 'utils/util';
import {
	categorizeAndSortNotifications,
	isAnyUnread,
	updateReadStatus,
} from 'components/useAppHeaderNotification/utils/util';
import { AppHeaderNotifications } from 'components/Notification/NotificationContent/AppHeaderNotificationContent';

let client: ApiClient;

function useAppHeaderNotification(url: string): UseNotification {
	const [appHeaderNotifications, setAppHeaderNotification] = useState<AppHeaderNotifications>(EMPTY_NOTIFICATIONS);
	const [fetchState, setFetchState] = useState({ isLoading: false, hasError: false });
	const [updateState, setUpdateState] = useState({ isLoading: false, hasError: false });

	const { baseURL, path: pathPrefix } = getUrlComponents(url);

	if (!client) {
		client = apiClient(baseURL, pathPrefix);
	}

	async function fetchNotifications() {
		try {
			setFetchState({ isLoading: true, hasError: false });

			const response = await client.get<AppHeaderNotificationResponse[]>(GET_NOTIFICATIONS_URL);
			const items = categorizeAndSortNotifications(response);

			setAppHeaderNotification(items);
		} catch (error) {
			setFetchState({ isLoading: false, hasError: true });
		} finally {
			setFetchState((prevState) => ({ ...prevState, isLoading: false }));
		}
	}

	async function updateNotifications(notificationIds: string[]) {
		setUpdateState({ isLoading: true, hasError: false });

		try {
			await client.put(UPDATE_NOTIFICATIONS_URL, notificationIds);

			setAppHeaderNotification((prev) => {
				const updatedToday = updateReadStatus(prev.today, notificationIds);
				const updatedThisWeek = updateReadStatus(prev.thisweek, notificationIds);
				const updatedEarlier = updateReadStatus(prev.earlier, notificationIds);

				return {
					...prev,
					today: updatedToday,
					thisweek: updatedThisWeek,
					earlier: updatedEarlier,
					isAnyUnread: isAnyUnread([...updatedToday, ...updatedThisWeek, ...updatedEarlier]),
				};
			});
		} catch (error) {
			console.error(error);
			setUpdateState({ isLoading: false, hasError: true });
		} finally {
			setUpdateState((prevState) => ({ ...prevState, isLoading: false }));
		}
	}

	useEffect(() => {
		fetchNotifications();
	}, []);

	function clearNotifications() {
		setAppHeaderNotification(EMPTY_NOTIFICATIONS);
	}

	return {
		notification: {
			notifications: appHeaderNotifications,
			fetchNotifications: fetchNotifications,
			...fetchState,
			clearNotifications: clearNotifications,
		},
		updateNotification: {
			updateNotifications: updateNotifications,
			...updateState,
		},
	};
}

export default useAppHeaderNotification;
