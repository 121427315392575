import Icon from 'components/Icons/Icon';
import React, { useRef, useState } from 'react';
import './orgSwitcher.scss';
import { classNames } from 'utils/util';
import useCheckOutsideClick from 'components/useCheckOutsideClick/useCheckOutsideClick';

export interface Organisation {
	organisationName: string;
	organisationId: string;
	isTrialPlan: boolean;
	trialEndDateUtc?: string;
	practiceTypeId: number;
	practiceTypeName: string;
}

interface OrgSwitcherProps {
	currentOrg: Organisation;
	orgs: Organisation[];
	onOrgChange?: (orgId: string) => void;
}

const DEFAULT_ORG_NAME = 'Personal';

const OrgSwitcher: React.FC<OrgSwitcherProps> = ({ currentOrg, orgs, onOrgChange }) => {
	const [expandDropdown, setExpandDropdown] = useState(false);

	const orgSwitcherRef = useRef<HTMLDivElement>(null);

	useCheckOutsideClick(orgSwitcherRef, () => {
		setExpandDropdown(false);
	});

	const hasMultipleOrgs = orgs && orgs.length > 1;

	const displayOrgName = currentOrg.organisationName ? currentOrg.organisationName : DEFAULT_ORG_NAME;

	const onOrgChangeInternal = (orgId: string) => {
		if (onOrgChange) {
			onOrgChange(orgId);
		}

		setExpandDropdown(false);
	};

	const onToggle = () => {
		if (hasMultipleOrgs) {
			setExpandDropdown(!expandDropdown);
		}
	};

	const renderOrgsList = () => {
		const orgListItems = orgs.map((org) => {
			const selected = currentOrg.organisationId == org.organisationId;
			return (
				<div
					role="option"
					key={org.organisationId}
					className={`ph-orgList-org ${selected ? 'selected' : ''}`}
					aria-selected={selected}
					onClick={() => onOrgChangeInternal(org.organisationId)}
				>
					{org.organisationName}
				</div>
			);
		});

		return (
			<div className="ph-orgList" id="orgList" role="listbox" aria-labelledby="org-label">
				{orgListItems}
			</div>
		);
	};

	const iconClasses = classNames([
		{
			isExpanded: expandDropdown,
			isCollapsed: !expandDropdown,
		},
	]);

	return (
		<div className="ph-org-switcher" ref={orgSwitcherRef}>
			<button
				className="ph-org-switcher-button"
				id="orgSwitcher"
				role="combobox"
				title={displayOrgName}
				type="button"
				aria-expanded={expandDropdown}
				aria-controls="orgList"
				aria-haspopup="listbox"
				aria-labelledby="org-label"
				aria-activedescendant=""
				onClick={onToggle}
				disabled={!hasMultipleOrgs}
			>
				<Icon icon={['fal', 'building']} />
				<div className="ph-org-switcher-button-org">
					<p>Organisation</p>
					<div className="ph-org-switcher-button-org-orgName">{displayOrgName}</div>
				</div>
				{hasMultipleOrgs && <Icon icon={['fal', 'chevron-down']} className={iconClasses} />}
			</button>

			{expandDropdown && hasMultipleOrgs && renderOrgsList()}
		</div>
	);
};

export default OrgSwitcher;
