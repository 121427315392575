import React, {useState, createContext, useEffect} from 'react';
import * as Api from '../lib/api';

export const PermissionsContext = createContext();

export const PermissionsProvider = (props) => {

    const [isAdmin, setIsAdmin] = useState(false); 
    const [isSingleSite, setIsSingleSite] = useState(true);
    const [isRootOrg, setIsRootOrg] = useState(true);
    const [isImpersonation, setIsImpersonation] = useState(false);
    const [impersonationId, setImpersonationId] = useState(null);

    useEffect(() => {
        Api.permissionsApi.get().then((result) => {
            setIsAdmin(result.isAdmin);
            setIsSingleSite(result.isSingleSite);
            setIsRootOrg(result.isRootOrg);
            setIsImpersonation(result.isImpersonation);
            setImpersonationId(result.impersonationId);
        });
    }, []);

    const values = {
        isAdmin, 
        isSingleSite, 
        isRootOrg,
        isImpersonation,
        impersonationId
    }

    return(
        <PermissionsContext.Provider value={values}>
            {props.children}
        </PermissionsContext.Provider>
    );

}