import { get, post, put, _delete } from './applicationApiActions'

export default class FilterApi {
    static getAll() {
        return get('/Filter');
    }

    static add({ name, settings }) {
        return post('/Filter', { name, settings });
    }

    static update({ id, name, settings }) {
        return put('/Filter/Update', { id, name, settings });
    }

    static updateDefault({ id, isDefault }) {
        return put('/Filter/UpdateDefault', { id, isDefault })
    }
    static delete(filterId) {
        return _delete(`/Filter?profileId=${filterId}`);
    }
}