import React from 'react';
import { convertHtmlStringToString, convertUtcDateToLocal } from '../../formatters';
import './FileNotes.scss';

const FileNoteRow = ({ fileNote, setToEdit, provisioningStatus, isLicenseAvailable}) =><tr>
    <td>{convertUtcDateToLocal(fileNote.date)}</td>
    <td>{fileNote.clinicalServiceType}</td>
    <td>{fileNote.createdByName}</td>
    <td>{convertHtmlStringToString(fileNote.note)}
    </td>
    <td>
        {(provisioningStatus === 'Active'|| (provisioningStatus === 'New' && isLicenseAvailable)) &&
            <>
                <a className="ellipsis-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <i className="fa fa-ellipsis-v text-color-blue" aria-hidden="true"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                    <a href="#" onClick={(evt) => { evt.preventDefault(); setToEdit(fileNote); }} className="dropdown-item" title="View certificate">Edit</a>
                </div>
            </>
        }
    </td>
</tr>; 

export default FileNoteRow;