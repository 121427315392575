import React, { useMemo, MutableRefObject } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import AgGridCustomNoRowsOverlay from './TableCustomNoRowsOverlay';
import AgGridCustomLoadingOverlay from './TableCustomLoadingOverlay';
import AgGridConstants from './TableConstants';
import { GridOptions } from 'ag-grid-community';
import './table.scss';

export interface ITableOptions extends GridOptions {
	gridRef: MutableRefObject<AgGridReactType>;
}

const Table: React.FC<ITableOptions> = ({
	gridRef,
	columnDefs,
	onCellClicked,
	onGridReady,
	onSortChanged,
	noRowsOverlayComponentParams,
	...props
}) => {
	const popupParent = useMemo(() => {
		return document.querySelector('body');
	}, []);

	return (
		<div style={{ height: '100%', width: '100%' }} className="ag-theme-material">
			<AgGridReact
				ref={gridRef}
				columnDefs={columnDefs}
				onCellClicked={onCellClicked}
				onGridReady={onGridReady}
				onSortChanged={onSortChanged}
				defaultColDef={AgGridConstants.DEFAULT_COL_DEF}
				animateRows={true}
				stopEditingWhenCellsLoseFocus={true}
				loadingOverlayComponent={AgGridCustomLoadingOverlay}
				noRowsOverlayComponent={AgGridCustomNoRowsOverlay}
				noRowsOverlayComponentParams={noRowsOverlayComponentParams}
				// Default to infinite
				rowModelType="infinite"
				maxConcurrentDatasourceRequests={1}
				cacheOverflowSize={2}
				// set popup parent to <body> so that when grid is small, filter will not be clipped
				// ref: https://ag-grid.com/react-data-grid/context-menu/#popup-parent
				popupParent={popupParent}
				suppressDragLeaveHidesColumns
				// All props above can be overridden
				{...props}
			/>
		</div>
	);
};

export default Table;
