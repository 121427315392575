import React from 'react';
import './avatar.scss';
import { getInitial } from 'utils/util';

export interface AvatarProps {
	firstName: string;
	lastName: string;
}

const Avatar: React.FC<AvatarProps> = ({ firstName, lastName }) => {
	return (
		<div className="ph-avatar">
			{getInitial(firstName)}
			{getInitial(lastName)}
		</div>
	);
};

export default Avatar;
