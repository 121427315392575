import React, { useContext, useState } from 'react';
import './DashboardOverview.scss';
import { Link } from 'react-router-dom';
import { PermissionsContext } from '../../PermissionsContext';
import moment from 'moment';
import { StipulationFilterOptions } from '../../utils/enums';

function toggleOrgInArray(org, arr) {
    if (arr.includes(org)) {
        return [...arr.filter(o => o !== org)];
    }

    return [...arr, org];
}

/**
 * render total badges on footer
 * @param {any} totalNew
 * @param {any} totalOverdue
 */
function renderTotalFooterBadge(totalNew, totalOverdue, link, hideNew) {
    return (
        <React.Fragment>
            <span className="badge badge-pill badge-new">
                <div className="auto-size">
                    <Link to={link} style={{color: '#000000'}}>
                            {totalNew || 0}
                    </Link>
                </div>
            </span>
            {!hideNew && <span className="badge badge-pill badge-danger ml-1">
                <div className="auto-size text-white">
                    {totalOverdue || 0}
                </div>
            </span>}
        </React.Fragment>
    );
}

const DashboardOverviewRow = ({ mainData, data, level, orgIdClasses, displayFilters, daysPriorToExpiryUpcomingAlert }) => { 
    const { isSingleSite } = useContext(PermissionsContext);
    const { isMainOrg } = !level || level == 0;
    const [expandedOrgs, setExpandedOrgs] = useState([]);
    var totalRegisteredData = 0,
        totalStipulatedData = 0,
        totalUpcomingRenewalData = 0,
        totalExpiredData = 0,
        totalSuspendedData = 0,
        totalCancelledData = 0,
        totalInvalidData = 0,
        totalAddedData = 0,
        totalRemovedData = 0;
    var newRegisteredData = 0,
        newStipulatedData = 0,
        newUpcomingRenewalData = 0,
        newExpiredData = 0,
        newSuspendedData = 0,
        newCancelledData = 0,
        newInvalidData = 0,
        newAddedData = 0,
        newRemovedData = 0;

    if (!data.organisation && !data.clinicalServiceType) return null;
    if (mainData.length == 0) return null;

    const dateFrom = moment().format("YYYY-MM-DD");
    const dateTo = moment().add(daysPriorToExpiryUpcomingAlert, 'days').format("YYYY-MM-DD");

    mainData.map((main, index) => {
        main.isExpand = true;
        totalRegisteredData += main.statusCountTotals["Registered"] ? main.statusCountTotals["Registered"].total : 0;
        totalStipulatedData += main.statusCountTotals["Stipulations"] ? main.statusCountTotals["Stipulations"].total : 0;
        totalUpcomingRenewalData += main.statusCountTotals["Upcoming Renewal"] ? main.statusCountTotals["Upcoming Renewal"].total : 0;
        totalExpiredData += main.statusCountTotals["Expired"] ? main.statusCountTotals["Expired"].total : 0;
        totalSuspendedData += main.statusCountTotals["Suspended"] ? main.statusCountTotals["Suspended"].total : 0;
        totalCancelledData += main.statusCountTotals["Cancelled"] ? main.statusCountTotals["Cancelled"].total : 0;
        totalInvalidData += main.statusCountTotals["Invalid"] ? main.statusCountTotals["Invalid"].total : 0;
        totalAddedData += main.statusCountTotals["Added"] ? main.statusCountTotals["Added"].total : 0;
        totalRemovedData += main.statusCountTotals["Deactivated"] ? main.statusCountTotals["Deactivated"].total : 0;

        newRegisteredData += main.statusCountTotals["Registered"] ? main.statusCountTotals["Registered"].new : 0;
        newStipulatedData += main.statusCountTotals["Stipulations"] ? main.statusCountTotals["Stipulations"].new : 0;
        newUpcomingRenewalData += main.statusCountTotals["Upcoming Renewal"] ? main.statusCountTotals["Upcoming Renewal"].new : 0;
        newExpiredData += main.statusCountTotals["Expired"] ? main.statusCountTotals["Expired"].new : 0;
        newSuspendedData += main.statusCountTotals["Suspended"] ? main.statusCountTotals["Suspended"].new : 0;
        newCancelledData += main.statusCountTotals["Cancelled"] ? main.statusCountTotals["Cancelled"].new : 0;
        newInvalidData += main.statusCountTotals["Invalid"] ? main.statusCountTotals["Invalid"].new : 0;
        newAddedData += main.statusCountTotals["Added"] ? main.statusCountTotals["Added"].new : 0;
        newRemovedData += main.statusCountTotals["Deactivated"] ? main.statusCountTotals["Deactivated"].new : 0;
    });
    return (
        <tbody>
            {/* Data */}
            {!isSingleSite && <tr>
                    <td><a href="#" className="has-children" aria-expanded={expandedOrgs.includes(data.organisation)} onClick={(evt) => { evt.preventDefault(); setExpandedOrgs(toggleOrgInArray(data.organisation, expandedOrgs)); }}>{data.organisation}</a></td>
                    <td className="text-center">
                        <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [1, 4], status: ['Registered'], profession: [data.clinicalServiceType ? data.clinicalServiceType : ''], practice: [data.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : data.organisationId] } }}>
                            {data.statusCounts["Registered"] ? data.statusCounts["Registered"].total : 0}
                        </Link>
                    </td>
                    <td className="text-center">
                        <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [1, 4], profession: [data.clinicalServiceType ? data.clinicalServiceType : ''], practice: [data.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : data.organisationId], stipulation: [StipulationFilterOptions.WithStipulations] } }}>
                            {data.statusCounts["Stipulations"] ? data.statusCounts["Stipulations"].total : 0}
                        </Link>
                        <span className="ml-1 text-danger">({data.statusCounts["Stipulations"] ? data.statusCounts["Stipulations"].new : 0})</span>
                    </td>
                    <td className="text-center">
                        <Link to={{ pathname: "/registrations-list", state: {provisioningstatus: [1, 4], profession: [data.clinicalServiceType ? data.clinicalServiceType : ''], practice: [data.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : data.organisationId], dateFilter: 3, dateFilterTo: dateTo, dateFilterFrom: dateFrom } }}>
                            {data.statusCounts["Upcoming Renewal"] ? data.statusCounts["Upcoming Renewal"].total : 0}
                        </Link>
                        <span className="ml-1 text-danger">({data.statusCounts["Upcoming Renewal"] ? data.statusCounts["Upcoming Renewal"].new : 0})</span>
                    </td>
                    <td className="text-center">
                        <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [1, 4], status: ['Expired'], profession: [data.clinicalServiceType ? data.clinicalServiceType : ''], practice: [data.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : data.organisationId], dateFilter: 3, dateFilterTo: dateFrom } }}>
                            {data.statusCounts["Expired"] ? data.statusCounts["Expired"].total : 0}
                        </Link>
                        <span className="ml-1 text-danger">({data.statusCounts["Expired"] ? data.statusCounts["Expired"].new : 0})</span>
                    </td>
                    <td className="text-center">
                        <Link to={{ pathname: "/registrations-list", state: { status: ['Suspended'], profession: [data.clinicalServiceType ? data.clinicalServiceType : ''], practice: [data.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : data.organisationId] } }}>
                            {data.statusCounts["Suspended"] ? data.statusCounts["Suspended"].total : 0}
                        </Link>
                        <span className="ml-1 text-danger">({data.statusCounts["Suspended"] ? data.statusCounts["Suspended"].new : 0})</span>
                    </td>
                    <td className="text-center">
                        <Link to={{ pathname: "/registrations-list", state: { status: ['Cancelled'], profession: [data.clinicalServiceType ? data.clinicalServiceType : ''], practice: [data.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : data.organisationId] } }}>
                            {data.statusCounts["Cancelled"] ? data.statusCounts["Cancelled"].total : 0}
                        </Link>
                        <span className="ml-1 text-danger">({data.statusCounts["Cancelled"] ? data.statusCounts["Cancelled"].new : 0})</span>
                    </td>
                    <td className="text-center">
                        <Link to={{ pathname: "/registrations-list", state: { status: ['Invalid'], profession: [data.clinicalServiceType ? data.clinicalServiceType : ''], practice: [data.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : data.organisationId] } }}>
                            {data.statusCounts["Invalid"] ? data.statusCounts["Invalid"].total : 0}
                        </Link>
                        <span className="ml-1 text-danger">({data.statusCounts["Invalid"] ? data.statusCounts["Invalid"].new : 0})</span>
                    </td>
                    <td className="text-center">
                        <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [4], profession: [data.clinicalServiceType ? data.clinicalServiceType : ''], practice: [data.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : data.organisationId] } }}>
                            {data.statusCounts["Added"] ? data.statusCounts["Added"].total : 0}
                        </Link>
                        <span className="ml-1 text-danger">({data.statusCounts["Added"] ? data.statusCounts["Added"].new : 0})</span>
                    </td>
                    <td className="text-center">
                        <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [2], profession: [data.clinicalServiceType ? data.clinicalServiceType : ''], practice: [data.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : data.organisationId] } }}>
                            {data.statusCounts["Deactivated"] ? data.statusCounts["Deactivated"].total : 0}
                        </Link>
                        <span className="ml-1 text-danger">({data.statusCounts["Deactivated"] ? data.statusCounts["Deactivated"].new : 0})</span>
                    </td>
            </tr>}
            {data.children && data.children.map((c, ci) =>
                <React.Fragment key={ci}>
                    {(!c.profession || expandedOrgs.includes(data.organisation) || isSingleSite) &&
                        <tr>
                            <td style={{ paddingLeft: '10px' }}> 
                                {c.profession}
                                {!c.profession && <a href="#" className="has-children" aria-expanded={expandedOrgs.includes(c.organisation)} onClick={(evt) => { evt.preventDefault(); setExpandedOrgs(toggleOrgInArray(c.organisation, expandedOrgs)); }}>{c.organisation}</a>}
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { status: ['Registered'], profession: [c.profession && c.profession !== 'No Profession' ? c.profession : ''], practice: [c.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId] } }}>
                                    {c.statusCounts["Registered"] ? c.statusCounts["Registered"].total : 0}
                                </Link>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [1, 4], profession: [c.profession && c.profession !== 'No Profession' ? c.profession : ''], practice: [c.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId], stipulation: [StipulationFilterOptions.WithStipulations] } }}>
                                    {c.statusCounts["Stipulations"] ? c.statusCounts["Stipulations"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({c.statusCounts["Stipulations"] ? c.statusCounts["Stipulations"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { profession: [c.profession && c.profession !== 'No Profession'  ? c.profession : ''], practice: [c.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId], dateFilter: 3, dateFilterTo: dateTo, dateFilterFrom: dateFrom } }}>
                                    {c.statusCounts["Upcoming Renewal"] ? c.statusCounts["Upcoming Renewal"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({c.statusCounts["Upcoming Renewal"] ? c.statusCounts["Upcoming Renewal"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [1, 4], status: ['Expired'], profession: [c.profession && c.profession !== 'No Profession' ? c.profession : ''], practice: [c.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId], dateFilter: 3, dateFilterTo: dateFrom } }}>
                                    {c.statusCounts["Expired"] ? c.statusCounts["Expired"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({c.statusCounts["Expired"] ? c.statusCounts["Expired"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { status: ['Suspended'], profession: [c.profession && c.profession !== 'No Profession' ? c.profession : ''], practice: [c.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId] } }}>
                                    {c.statusCounts["Suspended"] ? c.statusCounts["Suspended"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({c.statusCounts["Suspended"] ? c.statusCounts["Suspended"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { status: ['Cancelled'], profession: [c.profession && c.profession !== 'No Profession' ? c.profession : ''], practice: [c.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId] } }}>
                                    {c.statusCounts["Cancelled"] ? c.statusCounts["Cancelled"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({c.statusCounts["Cancelled"] ? c.statusCounts["Cancelled"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { status: ['Invalid'], profession: [c.profession && c.profession !== 'No Profession' ? c.profession : ''], practice: [c.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId] } }}>
                                    {c.statusCounts["Invalid"] ? c.statusCounts["Invalid"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({c.statusCounts["Invalid"] ? c.statusCounts["Invalid"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                {!c.profession && <>
                                    <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [4], profession: [c.profession && c.profession !== 'No Profession' ? c.profession : ''], practice: [c.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId] } }}>
                                        {c.statusCounts["Added"] ? c.statusCounts["Added"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({c.statusCounts["Added"] ? c.statusCounts["Added"].new : 0})</span>
                                </>}
                            </td>
                            <td className="text-center">
                                {!c.profession && <>
                                    <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [2], profession: [c.profession && c.profession !== 'No Profession' ? c.profession : ''], practice: [c.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId] } }}>
                                        {c.statusCounts["Deactivated"] ? c.statusCounts["Deactivated"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({c.statusCounts["Deactivated"] ? c.statusCounts["Deactivated"].new : 0})</span>
                                </>}
                            </td>
                        </tr>
                    }
                    {expandedOrgs.includes(c.organisation) && c.children && c.children.map((y, yi) =>
                        <tr key={yi}>
                            <td style={{ paddingLeft: '20px' }}>
                                {y.profession}
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { status: ['Registered'], profession: [y.profession && c.profession !== 'No Profession' ? y.profession : ''], practice: [y.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId] } }}>
                                    {y.statusCounts["Registered"] ? y.statusCounts["Registered"].total : 0}
                                </Link>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [1, 4], profession: [y.profession && c.profession !== 'No Profession' ? y.profession : ''], practice: [y.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId], stipulation: [StipulationFilterOptions.WithStipulations] } }}>
                                    {y.statusCounts["Stipulations"] ? y.statusCounts["Stipulations"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({y.statusCounts["Stipulations"] ? y.statusCounts["Stipulations"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { profession: [y.profession && c.profession !== 'No Profession' ? y.profession : ''], practice: [y.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId], dateFilter: 3, dateFilterTo: dateTo, dateFilterFrom: dateFrom } }}>
                                    {y.statusCounts["Upcoming Renewal"] ? y.statusCounts["Upcoming Renewal"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({y.statusCounts["Upcoming Renewal"] ? y.statusCounts["Upcoming Renewal"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { provisioningstatus: [1, 4], status: ['Expired'], profession: [y.profession && c.profession !== 'No Profession' ? y.profession : ''], practice: [y.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId], dateFilter: 3, dateFilterTo: dateFrom } }}>
                                    {y.statusCounts["Expired"] ? y.statusCounts["Expired"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({y.statusCounts["Expired"] ? y.statusCounts["Expired"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { status: ['Suspended'], profession: [y.profession && c.profession !== 'No Profession' ? y.profession : ''], practice: [y.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId] } }}>
                                    {y.statusCounts["Suspended"] ? y.statusCounts["Suspended"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({y.statusCounts["Suspended"] ? y.statusCounts["Suspended"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { status: ['Cancelled'], profession: [y.profession && c.profession !== 'No Profession' ? y.profession : ''], practice: [y.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId] } }}>
                                    {y.statusCounts["Cancelled"] ? y.statusCounts["Cancelled"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({y.statusCounts["Cancelled"] ? y.statusCounts["Cancelled"].new : 0})</span>
                            </td>
                            <td className="text-center">
                                <Link to={{ pathname: "/registrations-list", state: { status: ['Invalid'], profession: [y.profession && c.profession !== 'No Profession' ? y.profession : ''], practice: [y.organisationId == '00000000-0000-0000-0000-000000000000' ? orgIdClasses : c.organisationId] } }}>
                                    {y.statusCounts["Invalid"] ? y.statusCounts["Invalid"].total : 0}
                                </Link>
                                <span className="ml-1 text-danger">({y.statusCounts["Invalid"] ? y.statusCounts["Invalid"].new : 0})</span>
                            </td>
                            <td className="text-center">
                            </td>
                            <td className="text-center">
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            )}
            {/* Footer */}
            <tr>
                <td style={{ fontWeight: 700 }}>Totals</td>
                <td className="text-center" style={{ fontWeight: 700 }}>{renderTotalFooterBadge(totalRegisteredData, newRegisteredData, { pathname: "/registrations-list", state: {provisioningstatus: [1, 4], status: ['Registered'] } }, true)}</td>
                <td className="text-center" style={{ fontWeight: 700 }}>{renderTotalFooterBadge(totalStipulatedData, newStipulatedData, { pathname: "/registrations-list", state: {provisioningstatus: [1, 4], stipulation: [StipulationFilterOptions.WithStipulations] } })}</td>
                <td className="text-center" style={{ fontWeight: 700 }}>{renderTotalFooterBadge(totalUpcomingRenewalData, newUpcomingRenewalData, { pathname: "/registrations-list", state: {provisioningstatus: [1, 4], dateFilter: 3, dateFilterTo: dateTo, dateFilterFrom: dateFrom } })}</td>
                <td className="text-center" style={{ fontWeight: 700 }}>{renderTotalFooterBadge(totalExpiredData, newExpiredData, { pathname: "/registrations-list", provisioningstatus: [1, 4], state: { status: ['Expired'], dateFilter: 3, dateFilterTo: dateFrom } })}</td>
                <td className="text-center" style={{ fontWeight: 700 }}>{renderTotalFooterBadge(totalSuspendedData, newSuspendedData, { pathname: "/registrations-list", state: { status: ['Suspended'] } })}</td>
                <td className="text-center" style={{ fontWeight: 700 }}>{renderTotalFooterBadge(totalCancelledData, newCancelledData, { pathname: "/registrations-list", state: { status: ['Cancelled'] } })}</td>
                <td className="text-center" style={{ fontWeight: 700 }}>{renderTotalFooterBadge(totalInvalidData, newInvalidData, { pathname: "/registrations-list", state: { status: ['Invalid'] } })}</td>
                <td className="text-center" style={{ fontWeight: 700 }}>{renderTotalFooterBadge(totalAddedData, newAddedData, { pathname: "/registrations-list", state: { provisioningstatus: [4] } })}</td>
                <td className="text-center" style={{ fontWeight: 700 }}>{renderTotalFooterBadge(totalRemovedData, newRemovedData, { pathname: "/registrations-list", state: { provisioningstatus: [2] } })}</td>
            </tr>
        </tbody>
    );
}
export default DashboardOverviewRow;