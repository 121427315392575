import React, { Children, useState } from 'react';
import './HorizontalLabel.scss';
import OverflowModal from './OverflowModal';

const HorizontalLabel = ({title, value, children, titlecol, valuecol, isExcessive}) => {

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className="row border-bottom HorizontalLabel" onClick={isExcessive ? () => setShowModal(true) : undefined}>
                <div className={'col-' + titlecol +' title-label align-self-center'}>{title}</div>
                {children && Array.isArray(children) && children.length > 0 &&
                    <div className={`col-${valuecol} ${isExcessive ? ' truncate' : ''}`}>{children}</div>
                }
                {children && !Array.isArray(children) &&
                    <div className={`col-${valuecol} ${isExcessive ? ' truncate' : ''}`}>{children}</div>
                }
                {(!children ||  (children && Array.isArray(children) && children.length == 0))&& 
                    <div className={`col-${valuecol} ${isExcessive ? ' truncate' : ''}`}>{value && value != '' ? value : 'None' }</div>
                }
            </div>
            {showModal && 
                <OverflowModal title={title} showModal={setShowModal} >{children}</OverflowModal>
            }
        </>
    );
    
}

export default HorizontalLabel;