import { _delete, get, post } from './applicationApiActions';

class RegistrationsApi {
    static getDashboard() {
        return get('/Registrations/Dashboard');
    }

    static getDetail(linkedRecordId) {
        return get(`/Registrations/User/?linkedRecordId=${linkedRecordId}`)
    }

    static synchroniseAhpraData(linkedRecordId) {
        return post(`/Registrations/${linkedRecordId}/SynchroniseAhpraData`)
    }

    static getAll({
        userFilters,
        clinicalServiceTypeFilters,
        professionFilters,
        practiceFilters,
        statusFilters,
        dateFilter,
        dateFilterFrom,
        dateFilterTo,
        rowCount,
        provisioningStatusFilters,
        stipulationFilters,
        alertTypeFilters,
        alertStatusFilters
    }) {
        return post(`/Registrations`, {
            userFilters,
            clinicalServiceTypeFilters,
            professionFilters,
            practiceFilters,
            statusFilters,
            dateFilter,
            dateFilterFrom,
            dateFilterTo,
            rowCount,
            provisioningStatusFilters,
            stipulationFilters,
            alertTypeFilters,
            alertStatusFilters
        });
    }

    static delete(id, note) {
        return _delete(`/Registrations/${id}?note=${note}`);
    }

    static create({ linkedRecordId, registrationNumber, profession }) {
        return post('/Registrations/Create', { linkedRecordId, registrationNumber, profession });
    }

    static getRegistrationDetailHistory({ linkedRecordId, practiceIds, keyword }) {
        return post('/Registrations/GetRegistrationDetailHistory', { linkedRecordId, practiceIds, keyword });
    }

    static getAhpraRegDetailHistory(ahpraRegId) {
        return get(`/Registrations/GetAhpraRegDetailHistory/?ahpraRegId=${ahpraRegId}`);
    }
    
    static getbyLinkedRecord(linkedRecordId) {
        return get(`/Registrations/GetbyLinkedRecord/?linkedRecordId=${linkedRecordId}`);
    }
}

export default RegistrationsApi;