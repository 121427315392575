import { AppHeaderNotificationResponse } from './Interfaces';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { convertUTCDateToLocalDate, isThisWeek, isToday } from 'utils/dateUtil';
import { AppHeaderNotifications } from 'components/Notification/NotificationContent/AppHeaderNotificationContent';
import { AppHeaderNotificationItem } from 'components/Notification/NotificationItem/AppHeaderNotificationContentItem';

const mapItemResponseToNotificationItem = (item: AppHeaderNotificationResponse): AppHeaderNotificationItem => ({
	id: item.id,
	absoluteUrl: item.absoluteUrl,
	appIcon: (item.appIcon ? item.appIcon.split(' ') : null) as IconProp,
	title: item.title,
	action: item.action,
	subject: item.subject,
	message: item.message,
	isRead: item.isRead,
	notifiedOnUtc: convertUTCDateToLocalDate(item.notifiedOnUtc),
});

const sortNotificationItems = (items: AppHeaderNotificationItem[]) => {
	items.sort((a, b) => b.notifiedOnUtc.getTime() - a.notifiedOnUtc.getTime());
};

const categorizeAndSortNotifications = (data: AppHeaderNotificationResponse[]): AppHeaderNotifications => {
	let notifications: AppHeaderNotifications = {
		today: [],
		thisweek: [],
		earlier: [],
		isEmpty: data.length === 0,
		isAnyUnread: false,
	};

	if (data?.length === 0) return notifications;

	notifications = data.reduce((acc: AppHeaderNotifications, cur: AppHeaderNotificationResponse) => {
		const item = mapItemResponseToNotificationItem(cur);

		if (isToday(item.notifiedOnUtc)) {
			acc.today.push(item);
		} else if (isThisWeek(item.notifiedOnUtc)) {
			acc.thisweek.push(item);
		} else {
			acc.earlier.push(item);
		}

		return acc;
	}, notifications);

	notifications.isAnyUnread = isAnyUnread([
		...notifications.today,
		...notifications.thisweek,
		...notifications.earlier,
	]);
	sortNotificationItems(notifications.today);
	sortNotificationItems(notifications.thisweek);
	sortNotificationItems(notifications.earlier);

	return notifications;
};

const updateReadStatus = (
	items: AppHeaderNotificationItem[],
	notificationIds: string[]
): AppHeaderNotificationItem[] => {
	return items.map((item) => ({
		...item,
		isRead: notificationIds.includes(item.id) ? true : item.isRead,
	}));
};

const isAnyUnread = (items: AppHeaderNotificationItem[]): boolean => {
	return items.some((item) => item.isRead === false);
};

export {
	categorizeAndSortNotifications,
	updateReadStatus,
	mapItemResponseToNotificationItem,
	sortNotificationItems,
	isAnyUnread,
};
