import useFeatureConfig from 'components/useFeatureConfig';

const withFeatureConfig = (Component: React.ElementType, configKeys: string[], baseUrl: string | null) => {
	const Wrapper = (props: object) => {
		const featureConfigValues = useFeatureConfig(configKeys, baseUrl);
		return <Component featureConfigValues={featureConfigValues} {...props} />;
	};

	return Wrapper;
};

export default withFeatureConfig;
