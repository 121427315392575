import React from 'react';

export interface Option {
	value: string;
	name: string;
}

interface SelectOptionProps {
	option: Option;
}

const SelectOption: React.FC<SelectOptionProps> = ({ option }) => {
	return <option value={option.value}>{option.name}</option>;
};

export default SelectOption;
