import React from 'react';
import {Route, Switch, useRouteMatch, useParams, useLocation} from 'react-router-dom';
import { RegistrationList } from '../RegistrationList/RegistrationList';
import RegistrationDetailRoute from './RegistrationDetailRoute';
import qs from 'qs';
import { defaultAllOptionValue, defaultAllOptionValue2 } from '../../Constants';

const RegistrationsRoute = () => {
    let {path} = useRouteMatch();
    let location = useLocation();
    const {
        provisioningstatus,
        stipulation,
        status,
        practice,
        user,
        clinicalServiceType,
        alertType,
        alertStatus,
        profession
    } = useParams();
    
    const filters = {
        provisioningStatusFilter: provisioningstatus ? Array.isArray(provisioningstatus) ? provisioningstatus.map(x => +x) : [+provisioningstatus] : [defaultAllOptionValue],
        statusFilter: status ? Array.isArray(status) ? status.map(x => +x) : [+status] : [defaultAllOptionValue],
        practiceFilter: practice ? Array.isArray(practice) ? practice.map(x => +x) : [+practice] : [defaultAllOptionValue],
        userFilter: user ? Array.isArray(user) ? user.map(x => +x) : [+user] : [defaultAllOptionValue],
        clinicalServiceTypeFilter: clinicalServiceType ? Array.isArray(clinicalServiceType) ? clinicalServiceType.map(x => +x) : [+clinicalServiceType] : [defaultAllOptionValue],
        stipulationFilter: stipulation ? Array.isArray(stipulation) ? stipulation.map(x => +x) : [+stipulation] : [defaultAllOptionValue],
        alertTypeFilter: alertType ? Array.isArray(alertType) ? alertType.map(x => +x) : [+alertType] : [defaultAllOptionValue],
        alertStatusFilter: alertStatus ? Array.isArray(alertStatus) ? alertStatus.map(x => +x) : [+alertStatus] : [defaultAllOptionValue],
        professionFilter: profession ? Array.isArray(profession) ? profession.map(x => +x) : [+profession] : [defaultAllOptionValue],
    }

    var queries = qs.parse(location.search, { ignoreQueryPrefix: true });
    if(location.state || Object.keys(queries).length > 0) filters.preFiltered = true;

    if(queries.provisioningstatus) filters.provisioningStatusFilter = queries.provisioningstatus && Array.isArray(queries.provisioningstatus) ? queries.provisioningstatus.map(x => +x) : [+queries.provisioningstatus];
    if(queries.stipulation) filters.stipulationFilter = queries.stipulation && Array.isArray(queries.stipulation) ? queries.stipulation.map(x => x) : [queries.stipulation];
    if(queries.alertType) filters.alertTypeFilter = queries.alertType && Array.isArray(queries.alertType) ? queries.alertType.map(x => x) : [queries.alertType];
    if(queries.alertStatus) filters.alertStatusFilter = queries.alertStatus && Array.isArray(queries.alertStatus) ? queries.alertStatus.map(x => x) : [queries.alertStatus];
    if(queries.status) filters.statusFilter = queries.status && Array.isArray(queries.status) ? queries.status.map(x => x) : [queries.status];
    if(queries.practice) filters.practiceFilter = queries.practice && Array.isArray(queries.practice) ? queries.practice.map(x => x) : [queries.practice];
    if(queries.user) filters.userFilter = queries.user && Array.isArray(queries.user) ? queries.user.map(x => x) : [queries.user];
    if(queries.clinicalServiceType) filters.clinicalServiceTypeFilter = queries.clinicalServiceType && Array.isArray(queries.clinicalServiceType) ? queries.practice.map(x => x) : [queries.clinicalServiceType];
    if(queries.dateFilter) filters.dateFilter = +queries.dateFilter;
    if(queries.dateFilterTo) filters.dateFilterTo = queries.dateFilterTo;
    if(queries.dateFilterFrom) filters.dateFilterFrom = queries.dateFilterFrom;
    if(queries.profession) filters.professionFilter = queries.profession && Array.isArray(queries.profession) ? queries.practice.map(x => x) : [queries.profession];

    if(location.state && location.state.provisioningstatus) filters.provisioningStatusFilter = location.state.provisioningstatus;
    if(location.state && location.state.stipulation) filters.stipulationFilter = location.state.stipulation;
    if(location.state && location.state.alertType) filters.alertTypeFilter = location.state.alertType;
    if(location.state && location.state.alertStatus) filters.alertStatusFilter = location.state.alertStatus;
    if(location.state && location.state.status) filters.statusFilter = location.state.status;
    if(location.state && location.state.practice) filters.practiceFilter = location.state.practice;
    if(location.state && location.state.user) filters.userFilter = location.state.user;
    if(location.state && location.state.clinicalServiceType) filters.clinicalServiceTypeFilter = location.state.clinicalServiceType;
    if(location.state && location.state.dateFilter) filters.dateFilter = location.state.dateFilter;
    if(location.state && location.state.dateFilterTo) filters.dateFilterTo = location.state.dateFilterTo;
    if(location.state && location.state.dateFilterFrom) filters.dateFilterFrom = location.state.dateFilterFrom;
    if(location.state && location.state.profession) filters.professionFilter = location.state.profession;

    return <Switch>
        <Route exact path={path}>
            <RegistrationList filters={filters}/>
        </Route>
        <Route path={`${path}/:id`}>
            <RegistrationDetailRoute/>
        </Route>
    </Switch>;
};

export default RegistrationsRoute;
