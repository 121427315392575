import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class Heading extends React.PureComponent {

    render() {

        const { title, titleElement, breadcrumbs, colClasses } = this.props;

        return (

            <div className={colClasses || "col-5"} style={{ float: "left", paddingLeft: "0px" }}>
                <div className="row page-titles">

                    <div className="col-12 align-self-center">
                        {titleElement}
                    </div>

                    {breadcrumbs}

                    {!breadcrumbs &&
                        <div className="col-md-12">
                            <ol className="breadcrumb float-left">
                                <li className="breadcrumb-item active">
                                    <Link to='/'>Ahpra Alerts</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <span>{title}</span>
                                </li>
                            </ol>
                        </div>
                    }

                </div>
            </div>

        );
    }
}

Heading.propTypes = {
    titleElement: PropTypes.element,
    title: PropTypes.string
};

export default Heading;