import React, { Component } from 'react';
import './DashboardAlerts.scss';
import { AlertTypeLookup, AlertType } from '../../Constants';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { StipulationFilterOptions, FilterAlertStatus, AlertType as FilterAlertType } from '../../utils/enums';

export class DashboardAlerts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: []
        };
    }

    getRouteStateByAlertType(type, dateTo) {

        switch (type) {
            case AlertType.ADDED_USER: return { provisioningstatus: [4], alertType: [FilterAlertType.AddedUser], alertStatus: [FilterAlertStatus.New, FilterAlertStatus.Open] };
            case AlertType.DEACTIVATED_USER: return { provisioningstatus: [2], alertType: [FilterAlertType.DeactivatedUser], alertStatus: [FilterAlertStatus.New, FilterAlertStatus.Open] };
            case AlertType.STIPULATIONS: return { provisioningstatus: [1, 4], stipulation: [StipulationFilterOptions.WithStipulations], alertType: [FilterAlertType.Stipulations], alertStatus: [FilterAlertStatus.New, FilterAlertStatus.Open] };
            case AlertType.SUSPENDED_REGISTRATIONS: return { provisioningstatus: [1, 4], status: ['Suspended'], alertType: [FilterAlertType.SuspendedRegistrations], alertStatus: [FilterAlertStatus.New, FilterAlertStatus.Open] };
            case AlertType.UPCOMING_REGISTRATIONS: return { status: ['Registered', 'Expired'], provisioningstatus: [1, 4], dateFilter: 3, dateFilterTo: dateTo, alertType: [FilterAlertType.UpcomingRegistrations], alertStatus: [FilterAlertStatus.New, FilterAlertStatus.Open, FilterAlertStatus.Overdue] };
            case AlertType.CANCELLED_REGISTRATION: return { provisioningstatus: [1, 4], status: ['Cancelled'], alertType: [FilterAlertType.CancelledRegistration], alertStatus: [FilterAlertStatus.New, FilterAlertStatus.Open] };
            case AlertType.INVALID_REGISTRATIONS: return { provisioningstatus: [1, 4], status: ['Invalid'], alertType: [FilterAlertType.InvalidRegistrations], alertStatus: [FilterAlertStatus.New, FilterAlertStatus.Open] };
        }
    }

    /**
     * to render badge
     * @param {number} totalAlert
     * @param {boolean} isNew
     */
    renderBadge(totalAlert, isNew) {
        if (totalAlert === 0)
            return null;

        return <div className="tag tag-wide tag-urgent mr-1" style={{ float: 'right' }}>
            <div className="tag-label">{totalAlert} {isNew ? 'New' : 'Overdue'}</div>
            <div className="tag-icon tag-status-icon">
                <i className={isNew ? 'fal fa-exclamation-circle' : 'fal fa-clock'}></i>
            </div>
        </div>;
    }

    render() {

        const dateTo = moment().add(this.props.daysPriorToExpiryUpcomingAlert, 'days').format("YYYY-MM-DD");

        return <div className="DashboardAlerts card shadow-sm rounded-lg">
            <div className="card-header">
                <h5 className="card-title font-weight-bold">Alerts</h5>
            </div>
            <div className="card-body">

                {!this.props.data &&
                    <h3 className="text-center font-weight-bold">
                        <i className="fas fa-spinner fa-spin"></i> Loading
                    </h3>
                }

                {this.props.data &&
                    <table className="table">
                        <tbody>
                            {this.props.data.map(alert =>
                                <tr key={alert.type}>
                                    <td className="total-col-width text-right pr-2">{alert.total}</td>
                                    <td><Link to={{ pathname: "/registrations-list", state: this.getRouteStateByAlertType(alert.type, dateTo) }}>{AlertTypeLookup[alert.type]}</Link></td>
                                    <td className="badge-column">
                                        <div className="row">
                                            <div className={alert.news > 0 && alert.overdue > 0 ? "col-6 text-right" : alert.news > 0 && alert.overdue == 0 ? " offset-6 col-6 text-right" : "hide"}>{this.renderBadge(alert.news, true)}</div>
                                            <div className={alert.news > 0 && alert.overdue > 0 ? "col-6 text-right" : alert.news == 0 && alert.overdue > 0 ? "offset-6 col-6 text-right" : "hide"}>{this.renderBadge(alert.overdue, false)}</div>
                                        </div>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                }
            </div>
        </div>;
    }
}
