import React from 'react';
import Alert from 'components/Alert';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { SizeTypes } from 'components/Modal/Modal';
import './backToSignInModal.scss';

interface BackToSignInScreenProps {
	showScreen: boolean;
	userFirstName: string;
	userLastName: string;
	disableLogOutButton: boolean;
	onSignOut: (urlParam?: string) => void;
}

interface AvatarSectionProps {
	userFirstName: string;
	userLastName: string;
}

interface LogoutMessageProps {
	onSignOut: (urlParam?: string) => void;
}

const AvatarSection: React.FC<AvatarSectionProps> = ({ userFirstName, userLastName }) => (
	<>
		<Avatar firstName={userFirstName} lastName={userLastName} />
		<h3>{`${userFirstName.trim()} ${userLastName.trim()}`}</h3>
	</>
);

const LogoutAlert = () => (
	<Alert
		message="Please log on from your initial login session to proceed."
		icon={['fal', 'circle-info']}
		showCloseButton={false}
		state="isWarning"
	/>
);

const LogoutMessage: React.FC<LogoutMessageProps> = ({ onSignOut }) => (
	<>
		<p>You have been logged out.</p>
		<Button
			buttonText="Back to Login"
			onClick={() => {
				onSignOut('returnUrl=%2F');
			}}
		/>
	</>
);

const BackToSignInModal: React.FC<BackToSignInScreenProps> = ({
	showScreen,
	userFirstName,
	userLastName,
	disableLogOutButton,
	onSignOut,
}) => {
	return (
		<Modal
			show={showScreen}
			className="ph-back-signin-modal"
			title="Back to sign in"
			showHeader={false}
			showFooter={false}
			size={SizeTypes[0]}
		>
			<AvatarSection userFirstName={userFirstName} userLastName={userLastName} />
			{disableLogOutButton ? <LogoutAlert /> : <LogoutMessage onSignOut={onSignOut} />}
		</Modal>
	);
};

export default BackToSignInModal;
