import React from 'react';
import { classNames } from 'utils/util';
import * as Enums from './Enums';
import './status.scss';

const renderStatusClass = (status: Enums.StatusEnum) => {
	switch (status) {
		case Enums.StatusEnum.Success:
			return 'success';
		case Enums.StatusEnum.Warning:
			return 'warning';
		case Enums.StatusEnum.Error:
			return 'error';
		case Enums.StatusEnum.NoAccess:
			return 'noaccess';
		case Enums.StatusEnum.Info:
			return 'info';
		default:
			return 'success';
	}
};

const renderShapeClass = (shape: Enums.ShapeEnum) => {
	switch (shape) {
		case Enums.ShapeEnum.Circle:
			return 'circle';
		case Enums.ShapeEnum.Rectangle:
			return 'rectangle';
		default:
			return 'circle';
	}
};

interface StatusProps {
	label: string;
	status: Enums.StatusEnum;
	shape: Enums.ShapeEnum;
}

const Status: React.FC<StatusProps> = ({ label, status, shape }) => {
	const classes = classNames(['ph-status', renderStatusClass(status), renderShapeClass(shape)]);

	return (
		<div className={classes}>
			<span>{label}</span>
		</div>
	);
};

export default Status;
