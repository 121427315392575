import React from 'react';
import './formLabel.scss';
import { classNames } from 'utils/util';

export interface FormLabelWithControl {
	label: string;
	isMandatory?: boolean;
}

interface FormLabelProps extends FormLabelWithControl {
	htmlIdFor?: string;
}

const FormLabel: React.FC<FormLabelProps> = ({
	htmlIdFor,
	label,
	isMandatory = false

}) => {
	const classes = classNames(['ph-label']);

	return (
		<>
			{label ? <label className={classes} htmlFor={htmlIdFor}>{label}{isMandatory ? <span>(Required)</span> : null}</label> : null}
		</>
	);
};

export default FormLabel;
