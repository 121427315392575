import React, {Component} from 'react';
import './FileNotes.scss';
import {FileNoteEditor} from '../FileNoteEditor/FileNoteEditor';
import FileNoteRow from './FileNoteRow';

export class FileNotes extends Component {

    state = {
        fileNoteToEdit: {},
        showEditor: false
    }

    addNewNote = (evt) => {

        if(evt) evt.preventDefault();

        this.setState({showEditor: true});
    }

    setToEdit = (fileNote) => {
        this.setState({fileNoteToEdit:fileNote, showEditor: true});

    }

    render() {

        const fileNotes = this.props.fileNotes;

        return (
            <div className="FileNotes card shadow-sm rounded-lg">
                <div className="card-header">
                    <h5 className="card-title">File Notes</h5>
                </div>
                <div className="card-body">
                    <div className="row justify-content-between title-header pb-2">
                        <div className="col-3 align-self-center text-left">
                            {(this.props.provisioningStatus === 'Active' || this.props.provisioningStatus === 'New') &&
                                <div className="mb-2">
                                    <a className=" cursor-pointer" onClick={this.addNewNote}>
                                        <i className="fa fa-plus   text-color-blue"></i>
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                    {!this.props.isLoading &&
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Clinical Service Type</th>
                                <th>User</th>
                                <th>Note</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {fileNotes && fileNotes.map((fileNote) =>
                                    <FileNoteRow
                                        key={fileNote.id}
                                        fileNote={fileNote}
                                        setToEdit={this.setToEdit}
                                        provisioningStatus={this.props.provisioningStatus}
                                        isLicenseAvailable={this.props.isLicenseAvailable}
                                    />)}
                            </tbody>
                        </table>
                    }
                    {this.props.isLoading &&
                        <h3 className="text-center font-weight-bold">
                            <i className="fas fa-spinner fa-spin"></i> Loading
                        </h3>
                    }
                    <FileNoteEditor
                        show={this.state.showEditor}
                        fileNote={this.state.fileNoteToEdit}
                        ahpraRecords={this.props.ahpraRecords}
                        linkedRecordId={this.props.linkedRecordId}
                        onClose={() => this.setState({showEditor: false, fileNoteToEdit: {}}, this.props.onClose)}
                        onCloseWithSave={() => this.setState({showEditor: false, fileNoteToEdit: {}}, this.props.onCloseWithSave)}
                    />
                </div>
            </div>
        );

    }
}