import React from 'react';
import {useParams} from 'react-router-dom';
import {History} from '../History/History';

const HistoryRoute = () => {
    const {id} = useParams();
    return <History linkedRecordId={id}/>
};

export default HistoryRoute;
