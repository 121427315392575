import React from 'react';
import './charLimit.scss';
import { classNames } from 'utils/util';
import { Icon } from 'index';

interface CharLimitProps {
	charLimit: number;
	isInvalid: boolean;
	className: string | undefined;
	inputValue: string;
}

const CharLimit: React.FC<CharLimitProps> = ({ charLimit, isInvalid, className, inputValue }) => {
	const classesLimit = classNames([
		'ph-input-charLimit',
		{
			hasError: isInvalid,
		},
		className,
	]);

	return (
		charLimit > 0 && (
			<span className={classesLimit}>
				{isInvalid && <Icon icon={['fal', 'triangle-exclamation']} />}
				{inputValue.length}/{charLimit}
			</span>
		)
	);
};

export default CharLimit;
