
import useMenuItems from 'components/useMenuItems';
import { MenuItem } from 'components/useMenuItems/utils/Interfaces';
import { FC, ReactNode, createContext, useContext, useMemo } from 'react';

interface IMenuContext {
    menuItems: Array<MenuItem>;
    setMenuItems: React.Dispatch<React.SetStateAction<MenuItem[]>> | void;
    isLoading: boolean;
    hasError: boolean;
    fetchMenuData: () => Promise<void> | void;
}

export const MenuContext = createContext<IMenuContext>(
    // initial value
    {
        menuItems: [],
        setMenuItems: () => undefined,
        isLoading: false,
        hasError: false,
        fetchMenuData: () => undefined,
    }
);

export const useMenuContext = () => {
	return useContext(MenuContext);
};

export interface MenuContextProviderProps {
    url: string,
	children: ReactNode;
}

export const MenuContextProvider: FC<MenuContextProviderProps> = ({ url, children }) => {
	const { menuItems, setMenuItems, isLoading, hasError, fetchMenuData } = useMenuItems(url);

    const value = useMemo(() => ({
        menuItems,
        isLoading,
        hasError,
        fetchMenuData,
        setMenuItems
    }),[menuItems, isLoading, hasError]);

	return (
		<MenuContext.Provider value={value}>
			{children}
		</MenuContext.Provider>
	);
};