import axios, { AxiosInstance } from 'axios';

export interface AxiosInstances {
	[key: string]: AxiosInstance
}

const axiosInstances: AxiosInstances = {};

// Return a single instance of Axios per base URL
const httpClientFactory = (baseURL: string): AxiosInstance => {

    if(axiosInstances[baseURL]) return axiosInstances[baseURL];

    const newInstance = axios.create({
        baseURL: baseURL
    });
    axiosInstances[baseURL] = newInstance;

    return newInstance;
};

// Add an interceptor that call a methos to recieve an access token to append to each request.
export const appendTokenToRequests = (instance: AxiosInstance, acquireTokenFunctionAsync: () => Promise<string>): AxiosInstance => {

    instance.interceptors.request.use(
        async (config) => {

            const token = await acquireTokenFunctionAsync();

            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }

            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    return instance;
};

export default httpClientFactory;


