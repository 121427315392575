import React from 'react';
import {AlertType, AlertStatus, AlertTypeDescriptionLookup} from '../../Constants';
import './Alert.scss';

const getAlertClass = (status) => {
    return status === AlertStatus.OPEN ? 'alert-warning' : (status === AlertStatus.NEW || status === AlertStatus.OVERDUE) ? 'alert-danger' : 'alert-info'; 
}

const getAdditionalInfo = (additionalInfo) => {
    if(additionalInfo){
        return ', ' + additionalInfo;
    }else{
        return '';
    }
}

const openRegistrationEditorIfAHPRARegistrationIsEmpty = (event, closeAlert, alert, isEmptyAhpraRecord, openRegistrationEditorAndCloseAlert) => {
    event.preventDefault();

    if (isEmptyAhpraRecord && openRegistrationEditorAndCloseAlert) {
        const isNewUserAddedAlert =
            alert.type === AlertType.ADDED_USER &&
            (alert.status === AlertStatus.NEW || alert.status === AlertStatus.OPEN);

        if (isNewUserAddedAlert && isEmptyAhpraRecord()) {
            return openRegistrationEditorAndCloseAlert(closeAlert, alert);
        }
    }

    return closeAlert(alert);
}

const Alert = ({alert, acknowledgeAlert, closeAlert, isEmptyAhpraRecord, openRegistrationEditorAndCloseAlert}) => <div className={`alert ${getAlertClass(alert.status)} show`} role="alert">
    <a className="icon-options-vertical link pull-right" style={{float: "right"}} data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        <i className="fa fa-ellipsis-v text-color-blue" aria-hidden="true"></i>
    </a>
    <div className="dropdown-menu dropdown-menu-right">
        {
            alert.status === AlertStatus.NEW &&
            <a href="#" onClick={(evt) => {evt.preventDefault(); acknowledgeAlert(alert);}} className="dropdown-item">Acknowledge alert</a>
        }
        <a href="#" onClick={(evt) => { openRegistrationEditorIfAHPRARegistrationIsEmpty(event, closeAlert, alert, isEmptyAhpraRecord, openRegistrationEditorAndCloseAlert); }} className="dropdown-item">Close alert</a>
    </div>
    {
        (alert.status == AlertStatus.NEW || alert.status == AlertStatus.OVERDUE) &&
        <div>
            <span className="alert-icon"><i className="fal fa-exclamation-circle"></i></span>&nbsp;&nbsp;&nbsp;
            {(alert.status == AlertStatus.NEW ? 'New Alert - ' : 'Overdue Alert - ') + AlertTypeDescriptionLookup[alert.type] + getAdditionalInfo(alert.additionalInfo)}
        </div>
    }
    {
        alert.status == AlertStatus.OPEN &&
        <div>
            <span className="warning-icon"><i className="fal fa-clock"></i></span>&nbsp;&nbsp;&nbsp;
            {'Open Alert - ' + AlertTypeDescriptionLookup[alert.type] + getAdditionalInfo(alert.additionalInfo)}
        </div>
    }
    
</div>


export default Alert;