import React from 'react';
import axios from 'axios';
import { Menu, useMenuItems } from '@practicehub/assets';
import { withRouter } from 'react-router-dom';

const SideMenu = () => {
    const { menuItems, isLoading, hasError, fetchMenuData } = useMenuItems('/v2', axios);
    return (
        <Menu items={menuItems} isLoading={isLoading} hasError={hasError} fetchMenuData={fetchMenuData} />
    );
}

export default withRouter(SideMenu);
