import React, { ReactNode, useState } from 'react';
import './inputCollection.scss';
import Icon from 'components/Icons/Icon';
import { InitialCollection } from './InputCollection';

interface InputCollectionItemProps {
	renderItem: ((value?: ReactNode) => ReactNode) | undefined;
	item: InitialCollection;
	removeItem: (item: InitialCollection) => void;
}

const InputCollectionItem: React.FC<InputCollectionItemProps> = ({ renderItem, item, removeItem }) => {
	const [isExpanded, setIsExpanded] = useState(true);

	const onRemoveItem = (evt: React.MouseEvent<HTMLDivElement>) => {
		evt.preventDefault();
		evt.stopPropagation();

		removeItem(item);
	};

	const onExpand = (evt: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		evt.preventDefault();

		setIsExpanded(!isExpanded);
	};

	return (
		<div className="ph-inputCollectionitem" title="Collection item">
			<div className="ph-inputCollectionitem-label">
				{item.name}
				{isExpanded ? (
					<Icon icon={['fal', 'angle-down']} className="ph-inputCollectionitem-expand" onClick={onExpand} />
				) : (
					<Icon icon={['fal', 'angle-up']} className="ph-inputCollectionitem-expand" onClick={onExpand} />
				)}
				<div className="ph-inputCollectionitem-remove" onClick={onRemoveItem}>
					<Icon icon={['fal', 'trash-can']} />
				</div>
			</div>

			{isExpanded && renderItem && renderItem(item.value)}
		</div>
	);
};

export default InputCollectionItem;
