import React from 'react';
import './UserDetail.scss';
import { Link } from 'react-router-dom';
import { ProvisioningStatusLookUp } from '../../Constants';
import { convertUtcDateToLocalCustom } from '../../formatters';

const UserDetail = ({staffRecordDetail, linkedRecordId, ahpraRecord}) => {
    // US#15764 - Dynamic Decoupling - covers when user/staff not migrated or not found yet.
    // Note: Organisation or user or Affiliation record are not found 
    let fullName = staffRecordDetail && staffRecordDetail.firstName && staffRecordDetail.lastName ? `${staffRecordDetail.firstName} ${staffRecordDetail.lastName}` : 
                   staffRecordDetail.staffRecordId ? "Staff Not Found/Not Migrated yet" : 
                   staffRecordDetail.userId ? "User Not Found/Not Migrated yet" : "-";

    return <div className="UserDetail card shadow-sm rounded-lg">
        <div className="card-header">
            <h5 className="card-title">User Details</h5>
        </div>
        <div className="card-body">
            <div className="form-group SimpleInput">
                <label className="control-label">Name</label>
                <p>{`${fullName}`}</p>
            </div>
            <br/>
            <div className="form-group SimpleInput">
                <label className="control-label">Business Role</label>
                <p>{staffRecordDetail.userRoleNames}</p>
            </div>
            <br/>
            <div className="form-group SimpleInput">
                <label className="control-label">Practices</label>
                <p>{staffRecordDetail.affiliations?.map((a, index) => <span key={index}>{a.name}<br/></span>)}</p>
            </div>
            <br/>
            <div className="form-group SimpleInput">
            <label className="control-label">Provisioning Status</label>
                <p>{ProvisioningStatusLookUp[staffRecordDetail.provisioningStatus]}</p>
            </div>
            <br/>
            <div className="userdetail-footer">
                <Link to={`/registrations/record/${linkedRecordId}/history/`}>
                    <span>History</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Last Updated: {convertUtcDateToLocalCustom(staffRecordDetail.updatedOnUtc, 'DD MMM, YYYY')}</span>
                </Link>
                
            </div>
        </div>
    </div>;
};

export default UserDetail;
