import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.scss';
import { DashboardAlerts } from '../DashboardAlerts/DashboardAlerts';
import { DashboardAhpraStatus } from '../DashboardAhpraStatus/DashboardAhpraStatus';
import { DashboardOverview } from '../DashboardOverview/DashboardOverview';
import Heading from '../shared/Heading/Heading';
import * as Api from '../../api';

export class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            baseSettings: '',
            selectedTab: '1',
            displayFilters: false,
            canFilter: true,
            registrationRecords: [],

            headerTabOptions: [
                { value: '1', name: 'All' },
                { value: '2', name: 'Nurses' }
            ],

            practices: [],
            practiceFilters: ['All'],
            practiceIds: [],

            clinicalServiceTypeOptions: [],
            clinicalServiceTypeFilters: ['All'],
            clinicalServiceTypes: [],

            isLoading: true,

        };
    }

    componentDidMount() {
        document.title = 'Ahpra Alerts - Overview';
        this.getPractices();
        this.getClinicServiceTypes();
        this.getData();
    }

    /**
     * load the list available practices data
     */
    getPractices() {
        Api.practiceApi.getAll().then((data) => {
            const practices = data.map((value) => {
                return {
                    label: value.name,
                    value: value.id
                };
            });
            this.setState({ practices: practices });
        });
    }

    /**
     * load the list of available clinical service type
     */
    getClinicServiceTypes() {
        Api.clinicalServiceTypeApi.get().then((data) => {
            const clinicServiceTypes = data.map((value) => {
                return {
                    label: value.name,
                    value: value.name
                };
            });
            this.setState({ clinicServiceTypes: clinicServiceTypes });
        });
    }

    getData() {
        Api.registrationApi.getDashboard().then((data) => {
            this.setState({ data: data });
        });
    }

    onTabChange(value) {
        if (value == 1) {// all options
            this.clinicalServiceTypeFilterChange(["All"]);
        } else if (value == 2) {// only nurse
            this.clinicalServiceTypeFilterChange(["Nurse"]);
        }
        this.setState({
            selectedTab: value
        });
    }

    toggleDisplayFilters = () => {
        this.setState({ displayFilters: !this.state.displayFilters });
    }

    loadFilteredRegistrationRecords() {
        this.setState({ registrationRecords: [] }, () => {
            //callback is left empty since at this state it is not needed
        });
    }

    resetAllFilters = () => {
        if (this.state.baseSettings !== '') {
            this.setState(this.state.baseSettings);
            this.customFilterRef.setToDefaultFilter();
            this.loadFilteredRegistrationRecords();
        } else {
            this.setDefaultFilters(true);
        }
    };

    setDefaultFilters = (isRefresh) => {
        this.setState({
            clinicalServiceTypeFilters: ['All'],
            practiceFilters: ['All'],
        }, () => {
            if (isRefresh) {
                this.customFilterRef.setToAllItems();
                this.loadFilteredRegistrationRecords();
            }
        });
    };

    render() {
        const { displayFilters, canFilter, data } = this.state;

        return <div className="Dashboard">
            <Heading
                colClasses='col-12'
                title="Overview"
                titleElement={
                    <h3 title="Overview" className='overflow-inner'>
                        <span className='page-title-inner'>Overview</span>
                    </h3>
                }
                breadcrumbs={
                    <div className='col-md-12'>
                        <ol className='breadcrumb float-left'>
                            <li className='breadcrumb-item'>
                                <Link to='/'>Ahpra Alerts</Link>
                            </li>
                            <li className='breadcrumb-item active'>
                                <Link to='/'>Overview</Link>
                            </li>
                        </ol>
                    </div>
                }
            />
            <div className="row scrollable-content" style={{ minWidth: '1px' }}>
                <div className={`col ${this.state.displayFilters ? 'col-10' : 'col-12'}`}>
                    <div className="row">
                        <div className="col-6">
                            <DashboardAlerts data={data?.alertsSummary} daysPriorToExpiryUpcomingAlert={data?.daysPriorToExpiryUpcomingAlert} practiceIds={this.state.practiceIds} clinicalServiceTypes={this.state.clinicalServiceTypes} />
                        </div>
                        <div className="col-6">
                            <DashboardAhpraStatus data={data?.ahpraStatusSummary} practiceIds={this.state.practiceIds} clinicalServiceTypes={this.state.clinicalServiceTypes} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            <DashboardOverview data={data?.overview} daysPriorToExpiryUpcomingAlert={data?.daysPriorToExpiryUpcomingAlert} practiceIds={this.state.practiceIds} clinicalServiceTypes={this.state.clinicalServiceTypes} displayFilters={this.state.displayFilters} />
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
