import { get, post } from "./applicationApiActions";

export default class OrganisationSettingsApi {
    static get() {
        return get('/OrganisationSettings');
    }

    static updateOrganisationSettings(settings) {
        return post('/OrganisationSettings', settings);
    }
}