const DATE_FILTER_PARAMS = {
	filterOptions: [
		'equals',
		'notEqual',
		'greaterThan',
		'greaterThanOrEqual',
		'lessThan',
		'lessThanOrEqual',
		'inRange',
	],
	minValidDate: '0001-01-01',
	maxValidDate: '9999-12-31',
	buttons: ['reset'],
};

const DEFAULT_COL_DEF = {
	sortable: true,
	resizable: true,
	filter: true,
	filterParams: {
		maxNumConditions: 2,
	},
	suppressMovable: true,
};

export default {
	DATE_FILTER_PARAMS,
	DEFAULT_COL_DEF,
};
