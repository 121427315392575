import React from 'react';

export interface Option {
	name: string;
	value: string;
}

interface CountrySelectOptionProps {
	option: Option;
	displayValueNextToName: boolean;
}

const CountrySelectOption: React.FC<CountrySelectOptionProps> = ({ option, displayValueNextToName }) => {
	return (
		<option value={option.value}>
			{option.name}
			{displayValueNextToName ? ` (${option.value})` : ''}
		</option>
	);
};

export default CountrySelectOption;
