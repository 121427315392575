import { HTMLProps, forwardRef } from 'react';
import { useTooltipContext } from './TooltipContext';
import { FloatingArrow, FloatingPortal, useMergeRefs } from '@floating-ui/react';
import './tooltip.scss';
import { classNames } from 'utils/util';

export const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(function TooltipContent(
	{ className, style, children, ...props },
	propRef
) {
	const classes = classNames([className, 'ph-tooltip']);
	const context = useTooltipContext();
	const ref = useMergeRefs([context.refs.setFloating, propRef]);

	if (!context.open) return null;

	return (
		<FloatingPortal>
			<div
				className={classes}
				ref={ref}
				style={{
					...context.floatingStyles,
					...style,
				}}
				{...context.getFloatingProps(props)}
			>
				{children}
				<FloatingArrow
					context={context.context}
					ref={context.arrowRef}
					fill="var(--ph-white)"
					stroke="var(--ph-border-dark)"
					strokeWidth={1}
				/>
			</div>
		</FloatingPortal>
	);
});
