import './emptyNotifications.scss';
import Icon from 'components/Icons/Icon';

const EmptyNotifications: React.FC = () => {
	return (
		<div className="ph-empty-notification">
			<Icon icon={['fal', 'bell']} />
			<h4>No notifications right now</h4>
			<p>Check again for updates about manuals, messages and documents.</p>
		</div>
	);
};

export default EmptyNotifications;
