export class MessageDialogUtility {

    static headerHandler = (header) => { return; }

    static messageHandler = (message) => { return; }

    static showMessageDialog(header, message, handler) {
        if (MessageDialogUtility.headerHandler != null) {
            MessageDialogUtility.headerHandler(header);
        }
        if (MessageDialogUtility.messageHandler != null) {
            MessageDialogUtility.messageHandler(message);
        }
    }
}