import React from 'react';
import {HorizontalBar} from 'react-chartjs-2';
import { AhpraStatus, StipulationFilterOptions } from '../../utils/enums';
import { useHistory } from "react-router-dom";
import './DashboardAhpraStatus.scss';
import moment from 'moment';

function getRouteStateByType(type) {
    const dateFrom = moment().format("YYYY-MM-DD");
    switch(type) {
        case AhpraStatus.New : return {status: ['New']};
        case AhpraStatus.Registered : return {status: ['Registered']};
        case AhpraStatus.Stipulations : return {stipulation: [StipulationFilterOptions.WithStipulations]};
        case AhpraStatus.Suspended : return {status: ['Suspended']};
        case AhpraStatus.Cancelled : return {status: ['Cancelled']};
        case AhpraStatus.Invalid : return {status: ['Invalid']};
        case AhpraStatus.Expired : return {status: ['Expired', 'Registered'], dateFilter: 3, dateFilterTo: dateFrom};
        case AhpraStatus.Inactive : return {status: ['Inactive']};
    }
}

const DashboardAhpraStatusChart = ({dashboardStatusData}) => { 

    const data = dashboardStatusData;

    const history = useHistory();

    const handleClick = (elems) => {
        if(elems){
            const ahpraStatus = data[elems[0]._index].ahpraStatus;
            history.push({pathname: "/registrations-list", state: getRouteStateByType(ahpraStatus)});
        }
    }

    return <div className="DashboardAhpraStatus card shadow-sm rounded-lg">
                <div className="card-header">
                    <h5 className="card-title font-weight-bold">Ahpra Alerts Status</h5>
                </div>
                <div className="card-body">
                    
                    {!data &&
                        <h3 className="text-center font-weight-bold">
                            <i className="fas fa-spinner fa-spin"></i> Loading
                        </h3>
                    }

                    {data && <>

                        <HorizontalBar data={
                            {
                                labels: data.map(x => x.ahpraStatus),
                                datasets: [{
                                    label: 'Number of Staff',
                                    data: data.map(x => x.count),
                                    backgroundColor: [
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(54, 162, 235, 1)'
                                    ],
                                    borderColor: [
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(54, 162, 235, 1)'
                                    ],
                                    borderWidth: 1
                                }]
                            }
                        }
                        options={{
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        min: 0,
                                        precision: 1,
                                        stepSize: 1,
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }}
                        height={120} legend={{ display: false}} onElementsClick={handleClick} />
                        <div className="card-footer-container"><h5>Number of Staff</h5></div>
                    </>
                    }
                    
                </div>
            </div>;
};
     
export default DashboardAhpraStatusChart;