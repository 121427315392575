import React from 'react';
import './loading.scss';
import Icon from 'components/Icons/Icon';

interface LoadingProps {
	showMessage?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ showMessage = true }) => {
	return (
		<div className="ph-loading">
			<Icon icon={['fas', 'spinner']} className="ph-loading-icon" /> {showMessage && ' Loading...'}
		</div>
	);
};

export default Loading;
