import React from 'react';
import {Form} from 'react-bootstrap';

export const SimpleDropdown = ({title, elements, defaultName, selected, onChange, error}) => <div className="form-group SimpleDropdown">
    <label className="control-label">{title}</label>
    <Form.Control as="select" onChange={onChange} value={selected} className={`form-control ${error ? 'is-invalid' : ''}`}>
        {defaultName && <option>{defaultName}</option>}
        {elements.map((element) => <option key={element.value} value={element.value}>{element.name}</option>)}
    </Form.Control>
    {error && <div className="invalid-feedback">{error}</div>}
</div>;