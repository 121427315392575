import React, { useContext } from 'react';
import Loading from 'components/Loading';
import { UseNotification } from 'components/Notification';
import { IsAvantAdminContext } from 'components/AppHeader';

interface MarkAsReadProps extends UseNotification {
	handleMarkAllAsRead: (notificationIds: string[]) => void;
	message: string;
}

const MarkAllAsRead: React.FC<MarkAsReadProps> = ({
	handleMarkAllAsRead,
	message,
	notification,
	updateNotification,
}) => {
	const isAvantAdmin = useContext(IsAvantAdminContext);
	if (
		notification.isLoading ||
		notification.notifications.isEmpty ||
		notification.hasError ||
		!notification.notifications.isAnyUnread
	)
		return null;
	if (updateNotification.isLoading) return <Loading showMessage={false} />;

	const handleUpdateNotifications = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		const allNotifications = [
			...notification.notifications.today,
			...notification.notifications.thisweek,
			...notification.notifications.earlier,
		];
		handleMarkAllAsRead(allNotifications.map((notification) => notification.id));
	};

	return (
		<a href="#" onClick={handleUpdateNotifications} className={isAvantAdmin ? 'disabled' : ''}>
			{message}
		</a>
	);
};

export default MarkAllAsRead;
