import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import SimpleTextArea from '../shared/SimpleTextArea/SimpleTextArea';

export class AlertFileNoteEditor extends Component {
    state = {
        note: '',
        errors: {}
    }

    validate(){
        const errors = {};
        let isValid = true;
        if(this.props.requireNote && !this.state.note) {
            errors.note = "Please enter a file note.";
            isValid = false;
        }
        this.setState({errors: errors});
        return isValid;
    }

    save() {
        if(!this.validate()) {
            return;
        }
        this.closing();
        this.props.onSave(this.state.note);
    }

    closing() {
        this.cleaningState();
        this.props.onClose();
    }

    cleaningState() {
        this.setState({
            note: ''        
        });
    }

    render() {

        const {title, actionDescription, actionText} = this.props;

        return <Modal
            size="lg"
            show={this.props.show}
            onHide={() => this.closing()}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>{actionDescription}</h3>
                <br/>
                <div className="row">
                    <div className="col-12">
                        <SimpleTextArea
                            title="Note"
                            value={this.state.note}
                            onChange={value => this.setState({note: value.target.value})}
                            error={this.state.errors.note}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.closing()}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={() => this.save()}>
                    {actionText}
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}
