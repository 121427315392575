import { ReactNode, forwardRef } from 'react';
import { classNames } from 'utils/util';
import './card.scss';

interface Header {
	heading: ReactNode;
	action?: ReactNode;
}

interface CardProps {
	className?: string;
	header?: Header;
	children: ReactNode;
	style?: React.CSSProperties;
}

const Card = forwardRef<HTMLDivElement, CardProps>(({ className, header, children, style }, ref) => {
	const classes = classNames(['ph-card', className]);
	const headerExists = !!header;

	return (
		<div className={classes} ref={ref} style={style}>
			{headerExists && (
				<div className="ph-card-header">
					<h4>{header.heading}</h4>
					{!!header.action && <div className="ph-card-actions">{header.action}</div>}
				</div>
			)}
			<div className={classNames(['ph-card-body'])}>{children}</div>
		</div>
	);
});

Card.displayName = 'Card';

export default Card;
