import React from 'react';
import './Status.scss';

export class Status extends React.PureComponent {
    render() {
        const { countlabel, label, classtype, icon } = this.props;

        return (
            <div className='tag tag-wide tag-urgent mr-0'>
                {label != null &&
                    <div className="tag-label">{countlabel != null && countlabel != '1' && (countlabel + ' ')}{label}</div>
                }
                {icon != null && icon ==='alert' &&
                    <div className='tag-icon tag-status-icon'><i className="fal fa-exclamation-circle"></i></div>
                }
                {icon != null && icon ==='success' &&
                    <div className='tag-icon tag-status-icon'><i className="fal fa-check-circle"></i></div>
                }
                {icon != null && icon ==='warning' &&
                    <div className='tag-icon tag-status-icon'><i className="fal fa-clock"></i></div>
                }
            </div>
        );
    }
}