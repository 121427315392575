import React from 'react';
import { classNames } from 'utils/util';
import './textDisplay.scss';
import withDeprecationWarning from 'utils/hoc/withDeprecationWarning';

interface TextDisplayProps {
	label: string;
	placeholder?: string;
	value?: string | React.ReactNode;
	children?: React.ReactNode;
	isMandatory?: boolean;
	note?: string;
	error?: string;
}

const TextDisplay: React.FC<TextDisplayProps> = ({ label, placeholder, value, children }) => {
	const classes = classNames(['ph-textDisplay', { hasNoLabel: !label }]);

	if (!value && children) value = children;

	return (
		<label aria-label={label} className={classes}>
			{label ? <span className="ph-textDisplay-label">{label}</span> : null}
			<div className="ph-textDisplay-value">{value ? value : placeholder}</div>
		</label>
	);
};

export default withDeprecationWarning(TextDisplay);
