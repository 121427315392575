import apiClient, { ApiClient } from 'api/api';
import { useEffect, useState } from 'react';
import { getUrlComponents, removeUrlLastTrailingSlashes } from 'utils/util';

export interface UseGlobalSearch {
	performSearch: (value: string) => void;
	performSearchTag: (searchText: string, tagIds: string[]) => void;
	searchTags: (searchText: string) => Tag[];
	getSearchValueFromParam: () => string | undefined;
}

export interface Tag {
	id: number;
	name: string;
}

const SEARCH_PARAM = 'searchtext';
const SEARCH_TAG_PARAM = 'tIds';
const SEARCH_TAG_SIZE = 5;

let client: ApiClient;
let notPortalClient: ApiClient;

function useGlobalSearch(portalUrl: string, proxyUrl?: string): UseGlobalSearch {
	const [fetchState, setFetchState] = useState({ isLoading: false, hasError: false });
	const _portalUrl = removeUrlLastTrailingSlashes(portalUrl);
	const _proxyUrl = removeUrlLastTrailingSlashes(proxyUrl || '');
	const { baseURL, path: pathPrefix } = getUrlComponents(_portalUrl);
	const [tags, setTags] = useState<Tag[]>();

	if (!client) {
		client = apiClient(baseURL, pathPrefix);
	}

	if (!notPortalClient && _proxyUrl) {
		const url = getUrlComponents(_proxyUrl);
		notPortalClient = apiClient(url.baseURL, url.path);
	}

	useEffect(() => {
		fetchTags();
	}, [client]);

	const performSearch = (value: string) => {
		if (!value) {
			return;
		}
		value = value.trim();

		if (value !== '') {
			window.location.href = `${_portalUrl}/globalsearch/searchall/?${SEARCH_PARAM}=${value}`;
		}
	};

	const getSearchValueFromParam = () => {
		const queryParameters = new URLSearchParams(window.location.search);
		const value = queryParameters.get(SEARCH_PARAM);

		//Force empty if value is null as value / undefined in Search component only support type string | undefined
		if (value === null || value === undefined) {
			return '';
		}

		return value;
	};

	const fetchTags = async () => {
		try {
			setFetchState({ isLoading: true, hasError: false });

			const response = await (notPortalClient || client).get<Tag[]>('/globalsearch/tags/');

			const tags = response && response.length > 0 ? response : [];
			setTags(tags);
		} catch (error) {
			setFetchState({ isLoading: false, hasError: true });
		} finally {
			setFetchState((prevState) => ({ ...prevState, isLoading: false }));
		}
	};

	const getTags = (searchText: string) => {
		const _searchText = searchText.toLowerCase();

		return tags
			?.filter((t) => t.name.toLocaleLowerCase().includes(_searchText))
			.sort((a, b) => {
				// simple fussy search
				return Math.abs(a.name.length - searchText.length - b.name.length - searchText.length);
			})
			?.slice(0, SEARCH_TAG_SIZE);
	};

	const performSearchTag = (searchText: string, tagIds: string[]) => {
		if (!tagIds) {
			return;
		}

		let query = new URLSearchParams({ [SEARCH_PARAM]: searchText });
		tagIds.forEach((tag) => query.append(SEARCH_TAG_PARAM, tag));

		if (tagIds && tagIds.length > 0) {
			window.location.href = `${_portalUrl}/globalsearch/searchall/?${decodeURIComponent(query.toString())}`;
		}
	};

	return {
		performSearch,
		performSearchTag,
		getSearchValueFromParam,
		tags,
		searchTags: getTags,
		fetchState,
	} as UseGlobalSearch;
}

export default useGlobalSearch;
