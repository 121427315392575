import React, {Component} from 'react';
import './DashboardOverview.scss';
import DashboardOverviewRow from './DashboardOverviewRow';
import DashboardOverviewHead from './DashboardOverviewHead';

export class DashboardOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayFilters: this.props.displayFilters
        };

        // This binding is necessary to make `this` work in the callback
        this.renderDataPerRow = this.renderDataPerRow.bind(this);
    }

    /**
     * render data per row, if level = 0 then it is main parent, and if bigger than 0 then it is the child level after its parent
     * @param {*} data 
     * @param {*} level 
     * @param {*} orgIdClasses 
     */
    renderDataPerRow(daysPriorToExpiryUpcomingAlert, mainData, data, level, orgIdClasses) {
        return (
            <React.Fragment>
                <DashboardOverviewRow daysPriorToExpiryUpcomingAlert={daysPriorToExpiryUpcomingAlert} mainData={mainData} data={data} level={level} orgIdClasses={orgIdClasses} displayFilters={this.state.displayFilters}/>
            </React.Fragment>
        );
    }

    render() {
        var data = this.props.data;
        return (
            <div className="DashboardOverview card shadow-sm rounded-lg">
                <div className="card-header">Overview</div>
                <div className="card-body">

                    {!data &&
                        <h3 className="text-center font-weight-bold">
                            <i className="fas fa-spinner fa-spin"></i> Loading
                        </h3>
                    }

                    {data &&
                        <table className="table dashboard-overview">
                            <DashboardOverviewHead />
                            {data && data.map((main, index) =>
                                <React.Fragment key={main}>
                                    {this.renderDataPerRow(this.props.daysPriorToExpiryUpcomingAlert, data, main, 0)}
                                </React.Fragment>
                            )}
                        </table>
                    }
                </div>
            </div>
        );
    }
}
