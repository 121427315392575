import React from 'react';
import './icons.scss';
import { IconPack, IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { classNames } from 'utils/util';
import { PhIconProp, customIconsObject } from './FontAwesomeCustomIcons';

library.add(fal, fas as IconPack, far as IconPack, fat as IconPack, customIconsObject);

export interface IconProps {
	icon?: IconProp | PhIconProp;
	title?: string;
	className?: string;
	onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const Icon: React.FC<IconProps> = ({
	icon = ['fal', 'triangle-exclamation'],
	className = '',
	title = '',
	...props
}) => {
	return (
		<div className={classNames(['ph-icon', className])}>
			<FontAwesomeIcon icon={icon as IconProp} title={title} {...props} />
		</div>
	);
};

export default Icon;
