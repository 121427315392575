export enum StatusEnum {
	Success = 'Success',
	Warning = 'Warning',
	Error = 'Error',
	NoAccess = 'No Access',
	Info = 'Info',
}

export enum ShapeEnum {
	Rectangle = 'Rectangle',
	Circle = 'Circle',
}