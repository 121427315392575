import React, { ReactNode } from 'react';
import './fieldset.scss';
import withDeprecationWarning from 'utils/hoc/withDeprecationWarning';

interface FieldsetProps {
	label: string;
	children: ReactNode;
}

const Fieldset: React.FC<FieldsetProps> = ({ label, children }) => {
	return (
		<fieldset className="ph-fieldset">
			<legend>{label}</legend>
			{children}
		</fieldset>
	);
};

export default withDeprecationWarning(Fieldset);
