export class ConfirmDialogUtility {

    static displayHandler = (message, includeNote) => { return; }

    static resultHandler = (result) => { return; }

    static alertHandler = (alertMessage) => { return; }

    static showConfirmDialog(message, handler, alertMessage, includeNote) {
        ConfirmDialogUtility.resultHandler = handler;
        if (ConfirmDialogUtility.displayHandler != null) {
            ConfirmDialogUtility.displayHandler(message, includeNote);
        }
        if (ConfirmDialogUtility.alertHandler != null) {
            ConfirmDialogUtility.alertHandler(alertMessage);
        }
    }
}