import React, {useContext} from 'react';
import {PermissionsContext} from '../../PermissionsContext';

const RegistrationListHead = () => {

    const {isSingleSite} = useContext(PermissionsContext);

    return (<thead>
        <tr>
            <th>User</th>
            <th>Provisioning</th>
            {!isSingleSite && <th>Practices</th>}
            <th>Profession</th>
            <th>Expiry Date</th>
            <th>Ahpra Alerts Data Last Synchronised</th>
            <th>Ahpra Alerts Status</th>
            <th>Stipulations</th>
            <th>Alerts</th>
            <th></th>
        </tr>
    </thead>);
}

export default RegistrationListHead;