import httpClientFactory from 'httpClientFactory/httpClientFactory';

export interface Headers {
	[key: string]: string
 }

export interface ApiClient {
	get: <T>(resource: string, headers?: Headers) => Promise<T>;
	post: <T>(resource: string, body: object, headers?: Headers) => Promise<T>;
	put: <T>(resource: string, body: object, headers?: Headers) => Promise<T>;
}

type MethodType = 'get' | 'post' | 'put';

const ongoingRequests = new Map();

const apiClient = (baseURL: string, pathPrefix?: string): ApiClient => {

	const httpClient = httpClientFactory(baseURL);

	const fetchResource = async <T>(
		resource: string,
		method: MethodType,
		body?: object,
		customHeaders?: Headers
	): Promise<T> => {
		// Add path prefix to resource path. This was separated so that the baseURL of the client
		// is always just the protocol and host name (or empty if it is relative)
		const path = `${pathPrefix ?? ''}${resource}`;
		const cacheKey = `${method}_${baseURL}${path}_${JSON.stringify(body)}`;

		if (ongoingRequests.has(cacheKey)) {
			// Reuse the ongoing request
			return ongoingRequests.get(cacheKey);
		}

		const headers: Headers = {
			'Content-Type': 'application/json',
			...customHeaders,
		};

		const fetchPromise = (async () => {

			try {
				const response = await httpClient(path, {
					method,
					headers: headers,
					data: body,
				});

				if (response.status < 200 || response.status >= 300) {
					throw new Error(`HTTP error: ${response.status}`);
				}

				if (response.status === 204) {
					return {} as T;
				}

				return response.data;
			} catch (error) {
				console.error(error);
				throw new Error(`Failed to fetch ${resource}. Error: ${error}`);
			} finally {
				// Remove the request from the cache once it's completed
				ongoingRequests.delete(cacheKey);
			}
		})();

		// Store the ongoing request in the cache
		ongoingRequests.set(cacheKey, fetchPromise);

		return fetchPromise as Promise<T>;
	};

	const get = <T>(resource: string, headers?: Headers): Promise<T> => {
		return fetchResource(resource, 'get', undefined, headers);
	};

	const post = <T>(resource: string, body: object, headers?: Headers): Promise<T> => {
		return fetchResource(resource, 'post', body, headers);
	};

	const put = <T>(resource: string, body: object, headers?: Headers): Promise<T> => {
		return fetchResource(resource, 'put', body, headers);
	};

	return {
		get,
		post,
		put,
	};
};

export default apiClient;
