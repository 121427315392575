import React, { useContext, useEffect, useState } from 'react';
import './appHeader.scss';
import Image from 'components/Image';
import Search from 'components/Search';
import OrgSwitcher, { Organisation } from 'components/OrgSwitcher';
import UserNotifications from './UserNotifications/UserNotifications';
import { NotificationProps } from 'components/Notification/Notification';
import { UserProfileItems, UserProfileItemsProps } from './UserProfileItems/UserProfileItems';
import TrialBanner from './TrialBanner/TrialBanner';
import { UseGlobalSearch } from 'components/useGlobalSearch/useGlobalSearch';

interface AppHeaderProps extends NotificationProps, UserProfileItemsProps {
	logoUrl: string;
	portalUrl: string;
	manageAccountUrl: string;
	currentOrg: Organisation;
	orgs: Organisation[];
	onOrgChange: () => void;
	searchValue?: string;
	showNotification?: boolean;
	isAvantAdmin?: boolean;
	userEmail: string;
	userPhone: string;
	onSubmitUpgradeForm: () => Promise<void>;
	getGlobalSearch: () => UseGlobalSearch;
}

export const IsAvantAdminContext = React.createContext<boolean | undefined>(undefined);

const AppHeader: React.FC<AppHeaderProps> = ({
	logoUrl,
	portalUrl,
	currentOrg,
	orgs,
	onSignOut,
	onOrgChange,
	getGlobalSearch,
	userFirstName,
	userLastName,
	userEmail,
	userPhone,
	manageAccountUrl,
	showManageAccount,
	notification,
	updateNotification,
	notificationContentComponent: NotificationContent,
	showNotification,
	isAvantAdmin,
	onSubmitUpgradeForm,
}) => {
	const globalSearch = getGlobalSearch();
	const searchValue = globalSearch.getSearchValueFromParam();

	const [clearSignal, setClearSignal] = useState(0);
	const clearHeaderSearchHandler = (event: any) => {
		setClearSignal(new Date().getTime());
	};

	const ClearHeaderSearchEvent = 'ClearHeaderSearchEvent';

	useEffect(() => {
		document.addEventListener(ClearHeaderSearchEvent, clearHeaderSearchHandler, false);

		return () => {
			document.removeEventListener(ClearHeaderSearchEvent, clearHeaderSearchHandler, false);
		};
	}, []);

	return (
		<IsAvantAdminContext.Provider value={isAvantAdmin}>
			<header className="ph-app-header">
				<div className="ph-logo">
					<a href={portalUrl}>
						<Image url={logoUrl} />
					</a>
				</div>

				<OrgSwitcher currentOrg={currentOrg} orgs={orgs} onOrgChange={onOrgChange} />

				<div className="ph-header-actions">
					{currentOrg.isTrialPlan && (
						<TrialBanner
							org={currentOrg}
							user={{ userFirstName, userLastName, userEmail, userPhone }}
							onSubmitHandler={onSubmitUpgradeForm}
						/>
					)}
					<Search
						placeholder="Search"
						searchTags={globalSearch.searchTags}
						onKeyUp={globalSearch.performSearch}
						value={searchValue || ''}
						onTagSearch={globalSearch.performSearchTag}
						clearSignal={clearSignal}
					/>

					{showNotification && (
						<UserNotifications
							notification={notification}
							updateNotification={
								isAvantAdmin
									? { ...updateNotification, updateNotifications: () => Promise.resolve() }
									: updateNotification
							}
							notificationContentComponent={NotificationContent}
						/>
					)}

					<UserProfileItems
						onSignOut={onSignOut}
						userFirstName={userFirstName}
						userLastName={userLastName}
						manageAccountUrl={manageAccountUrl}
						showManageAccount={showManageAccount}
					/>
				</div>
			</header>
		</IsAvantAdminContext.Provider>
	);
};

export default AppHeader;
