import React, { Component } from 'react';
import HistoryItemRow from './HistoryItemRow';
import { HistoryPopup } from './HistoryPopup';
import { Heading, BootstrapCardWrapper } from '@practicehub/central';
import * as Api from '../../api';

export class History extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            userDetail: {},
            history: [],
            showModal: false,
            historyItem: {},
            linkedRecordId: this.props.linkedRecordId,
            practices: [],
            practiceFilters: ['All'],
            practiceIds: [],

            searchText: '',
            ahpraRegistrationData: {}
        };

    }

    componentDidMount() {
        document.title = 'Ahpra Alerts - History';
        this.getUserDetail();
        this.getHistory();
    }

    getUserDetail() {
        Api.registrationApi.getDetail(this.props.linkedRecordId)
            .then((result) => {
                this.setState({
                    userDetail: result,
                    username: `${result.firstName ? result.firstName : ''} ${result.lastName ? result.lastName : ''}`
                });
            });
    }


    getHistory() {
        Api.registrationApi.getRegistrationDetailHistory({
            linkedRecordId: Number(this.state.linkedRecordId),
            practiceIds: this.state.practiceIds,
            keyword: this.state.searchText
        }).then(result => {
            this.setState({
                history: result
            });
        });
    }

    openDetailHistory(auditEntry) {
        Api.registrationApi.getAhpraRegDetailHistory(auditEntry.ahpraRegistrationId).then(result => {
            this.setState({
                ahpraRegistrationData: result,
                showModal: true,
            });
        });
    }

    onClosePopupHistory() {
        this.setState({
            showModal: false,
        });
    }

    render() {
        const {
            history,
            username
        } = this.state;

        return (
            <div className="History">
                <div className="row justify-content-between title-header page-titles">
                    <div className="col-11 align-self-center">
                        <React.Fragment>
                            <Heading
                                title={username || 'History'}
                                isDisplayHistoryBadge={true}
                                breadcrumbs={[
                                    { title: 'Ahpra Alerts', link: '/' },
                                    { title: 'Registration List', link: '/registrations-list' },
                                    { title: this.state.username, link: `/record/${this.state.linkedRecordId}` },
                                    { title: 'History', link: `/registrations/record/${this.state.linkedRecordId}/history` }]}
                            />
                        </React.Fragment>
                        <h3>{`${this.state.staffRecordDetail && this.state.staffRecordDetail.firstName ? this.state.staffRecordDetail.firstName : ''} ${this.state.staffRecordDetail && this.state.staffRecordDetail.lastName ? this.state.staffRecordDetail.lastName : ''}`}</h3>
                    </div>
                </div>
                <div className="row scrollable-content">
                    <BootstrapCardWrapper classes="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="table-header">Date</th>
                                    <th className="table-header">Service Type</th>
                                    <th className="table-header">User</th>
                                    <th className="table-header">Business Role</th>
                                    <th className="table-header">Change</th>
                                </tr>
                            </thead>
                            <tbody>
                                {history && history.map((auditEntry, i) => <HistoryItemRow auditEntry={auditEntry} clickEvent={() => this.openDetailHistory(auditEntry)} />)}
                            </tbody>
                        </table>
                    </BootstrapCardWrapper>
                </div>
                <HistoryPopup
                    show={this.state.showModal}
                    ahpraRegistrationData={this.state.ahpraRegistrationData}
                    onClose={() => this.onClosePopupHistory()} />
            </div>
        );
    }
}
