export const AHPRAAlertType = {
    Endorsement: "Endorsement",
    Condition: "Condition",
    Undertaking: "Undertaking",
    Notation: "Notation",
    Caution: "Caution",
    Reprimand: "Reprimand",
};


export const ActionType = {
    Created: "Created",
    Updated: "Updated",
    Deleted: "Deleted",
};

export const AlertStatus = {
    New: "New",
    Open: "Open",
    Closed: "Closed",
    Overdue: "Overdue",
};

export const AlertType = {
    AddedUser: "AddedUser",
    DeactivatedUser: "DeactivatedUser",
    Stipulations: "Stipulations",
    SuspendedRegistrations: "SuspendedRegistrations",
    UpcomingRegistrations: "UpcomingRegistrations",
    CancelledRegistration: "CancelledRegistration",
    InvalidRegistrations: "InvalidRegistrations",
};

export const FilterAlertStatus = {
    New: "New",
    Open: "Open",
    Overdue: "Overdue",
    None: "None",
};

export const InsuranceType = {
    PublicLiability: "PublicLiability",
    MedicalIndemnity: "MedicalIndemnity",
    ProfessionalIndemnity: "ProfessionalIndemnity",
};

export const ProvisioningStatus = {
    Active: "Active",
    Deactivated: "Deactivated",
    Inactive: "Inactive",
    New: "New",
    NoRecord: "NoRecord",
    NewNoLicense: "NewNoLicense",
};

export const SettingType = {
    DaysPriorToExpiryUpcomingAlert: "DaysPriorToExpiryUpcomingAlert"
};

export const StipulationFilterOptions = {
    WithStipulations: "WithStipulations",
    WithoutStipulations: "WithoutStipulations",
};