import Status from 'components/Status';
import * as Enums from 'components/Status/Enums';
import React from 'react';
import { classNames } from 'utils/util';

interface ItemCountProps {
	disabled?: boolean;
	itemCount?: number;
	noAccess?: boolean;
}

const ItemCount: React.FC<ItemCountProps> = ({ disabled, itemCount, noAccess }) => {
	return (
		<>
			{itemCount !== undefined && (
				<span className={classNames(['ph-checkboxGroup-itemCount', { disabled: disabled }])}>
					({itemCount})
				</span>
			)}
			{noAccess && <Status label="No Access" status={Enums.StatusEnum.NoAccess} shape={Enums.ShapeEnum.Circle} />}
		</>
	);
};

export default ItemCount;
