import React, { Children } from 'react';
import {Status} from '../../Common/Status';

export class AHPRAStatus extends React.PureComponent {
    render(){
        const { status } = this.props;
        if(!status || status === '' || typeof status != 'string') return <Status label={''} width='110px' />;;
        const classType = () => {
            switch (status.toLowerCase()) {
                case 'registered':
                    return 'normal';
                case 'suspended':
                    return 'alert';
                case 'cancelled':
                    return 'alert';
                case 'expired':
                    return 'alert';
                case 'invalid':
                    return 'alert';
                case 'pending':
                    return 'normal';
                default:
                    return 'normal';
            }
        };
        const icon = () => {
            switch (status.toLowerCase()) {
                case 'registered':
                    return null;
                case 'suspended':
                    return 'alert';
                case 'cancelled':
                    return 'alert';
                case 'expired':
                    return 'alert';
                case 'invalid':
                    return 'alert';
                case 'pending':
                    return null;
                default:
                    return null;
            }
        };
        return <Status label={status} classtype={classType()} icon={icon()} width='110px' />;
    }
} ;
