import React from 'react';
import {Button, Modal} from 'react-bootstrap';

const OverflowModal = ({title, children, showModal}) => (
    <Modal
            size="lg"
            show={true}
            onHide={() => showModal(false)}
            centered
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => showModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
);

export default OverflowModal;