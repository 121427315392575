import React from 'react';
import { classNames } from 'utils/util';

interface ImageProps {
	url: string;
}

const Image: React.FC<ImageProps> = ({ url }) => {
	const classes = classNames(['ph-image']);

	return <img className={classes} src={url} />;
};

export default Image;
