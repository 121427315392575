import { FC } from 'react';
import './tab.scss';
import { classNames } from 'utils/util';
import { ComponentBase } from 'utils/types';
import Badge from 'components/Badge/Badge';
import Icon from 'components/Icons/Icon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface TabProps extends ComponentBase {
	id: string;
	onClick: () => void;
	text: string;
	type?: TabType;
	ariaControls: string;
	tabBadge?: string;
	leftIcon?: IconProp;
}

export type TabType = 'primary' | 'secondary';

const Tab: FC<TabProps> = ({ onClick, text, type = 'secondary', ariaControls, className, id, tabBadge = '', leftIcon }) => {
	const classes = classNames([
		'ph-tab',
		{
			[type]: type,
		},
		className,
	]);

	return (
		<button
			id={id}
			role="tab"
			aria-selected={type === 'primary'}
			aria-controls={ariaControls}
			className={classes}
			onClick={onClick}
		>
			{leftIcon && <Icon icon={leftIcon} />}
			{text}
			{!!tabBadge && <Badge content={tabBadge} size="small" type="info" />}
		</button>
	);
};

export default Tab;
