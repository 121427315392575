import React from 'react';
import './menu.scss';
import Retry from 'components/Retry/Retry';
import MenuItem, { EmptyMenuItems } from 'components/Menu/MenuItem/MenuItem';
import { MenuItem as MI } from 'components/useMenuItems/utils/Interfaces';

interface MenuProps {
	items: Array<MI> | undefined;
	isLoading: boolean;
	hasError?: boolean;
	fetchMenuData?: () => void;
	className?: string;
	isChildMenu?: boolean;
}

const Menu: React.FC<MenuProps> = ({
	items,
	isLoading,
	hasError,
	fetchMenuData,
	isChildMenu = false,
	className = 'ph-menu',
}) => {
	const renderMenuItems = () => {
		if (hasError) {
			return <Retry fetchData={fetchMenuData} message={''} />;
		}

		const displayItems = items?.length === 0 ? EmptyMenuItems : items;

		return displayItems?.map((item: MI, index) => (
			<MenuItem
				key={item.id || index}
				label={item.label}
				icon={item.icon}
				items={item.items}
				isActive={item.isActive}
				isExpandedByDefault={item.isActive && !!items}
				href={item.href}
				isLoading={isLoading}
				isExternal={item.isExternal}
				supplementaryImage={item.supplementaryImage}
				isChild={isChildMenu}
			/>
		));
	};

	return <ul className={className}>{renderMenuItems()}</ul>;
};

export default Menu;
