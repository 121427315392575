import React, {useEffect, useState} from 'react';
import './GlobalToasts.scss';
import {Toast} from 'react-bootstrap';
import {NotificationsUtility} from '../../utils/NotificationsUtility';

const GlobalToasts = () => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [style, setStyle] = useState('');

    useEffect(() => {
        NotificationsUtility.addNotificationHandler((type, message) => {
            type === 'Error' ? setStyle('error') : setStyle('success');
            setShow(true);
            setMessage(message);
        });
    }, []);

    return null;

    return <div>
        <Toast
            className={`GlobalToasts ${style}`}
            onClose={() => setShow(false)}
            show={show}
            delay={3500}
            autohide={true}>
            <Toast.Header>
                <strong>Attention</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    </div>;
};

export default GlobalToasts;
