import React, { useState } from 'react';
import CountrySelectOption from './CountrySelectOption';
import './countrySelect.scss';
import au from './images/au.png';
import Icon from 'components/Icons/Icon';
import withDeprecationWarning from 'utils/hoc/withDeprecationWarning';

interface Options {
	name: string;
	value: string;
}

interface CountrySelectProps {
	options: Options[];
	selectedOption?: Options;
	onChange: (optionsFiltered: Options) => void;
	displayValueNextToName: boolean;
}

const printOptionsList = (options: Options[], displayValueNextToName: boolean) => {
	return options.map((option: Options) => (
		<CountrySelectOption key={option.value} option={option} displayValueNextToName={displayValueNextToName} />
	));
};

const CountrySelect: React.FC<CountrySelectProps> = ({ options, selectedOption, onChange, displayValueNextToName }) => {
	const [selected, setSelected] = useState(selectedOption ? selectedOption.value : '');

	const countryCodeSelectHandler = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const value = evt.target.value;
		const optionsFiltered = options.filter((x: Options) => x.value === value);

		setSelected(value);
		onChange(optionsFiltered[0]);
	};

	return (
		<div className="ph-countrySelect-select">
			<select value={selected} title="Country Select" onChange={countryCodeSelectHandler}>
				<option>Please select</option>
				{printOptionsList(options, displayValueNextToName)}
			</select>
			<div className="ph-countrySelect-selected">
				<img src={au} />
				<Icon icon={['fal', 'angle-down']} />
			</div>
		</div>
	);
};

export default withDeprecationWarning(CountrySelect);
