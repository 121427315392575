import React, { forwardRef } from 'react';
import './input.scss';
import { classNames } from 'utils/util';

export const InputTypes = [
	'color',
	'date',
	'datetime-local',
	'email',
	'file',
	'hidden',
	'image',
	'month',
	'number',
	'password',
	'radio',
	'range',
	'reset',
	'search',
	'tel',
	'text',
	'time',
	'url',
	'week',
] as const;
type InputType = (typeof InputTypes)[number];

interface InputProps {
	id?: string;
	value?: string;
	placeholder?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onClick?: React.MouseEventHandler<HTMLInputElement>;
	onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	disabled?: boolean;
	hasError?: boolean;
	className?: string;
	type?: InputType;
	name?: string;
	ignoreSpecialCharacterForNumberInput?: boolean;
	ignoredSpecialCharactersForNumberInput?: string[];
}

const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			id,
			value,
			placeholder,
			onChange,
			onClick,
			onKeyUp,
			onKeyDown,
			hasError,
			className,
			disabled = false,
			type = 'text',
			name,
			// Input[type=number] allows some special characters combination as valid floating-point number,
			// see: https://html.spec.whatwg.org/multipage/common-microsyntaxes.html#valid-floating-point-number
			// Use these below props to ignore that default behavior
			ignoreSpecialCharacterForNumberInput = false,
			ignoredSpecialCharactersForNumberInput = ['-', '+', 'e', '.'],
			...props
		},
		ref
	) => {
		const classes = classNames([
			'ph-input',
			{
				hasError: hasError,
			},
			className,
		]);

		const internalOnKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
			if (
				type === 'number' &&
				ignoreSpecialCharacterForNumberInput &&
				ignoredSpecialCharactersForNumberInput.includes(event.key.toLowerCase())
			) {
				event.preventDefault();
				event.stopPropagation();
				return;
			}

			if (onKeyDown) {
				onKeyDown(event);
			}
		};

		return (
			<input
				id={id}
				type={type}
				className={classes}
				onChange={onChange}
				onKeyUp={onKeyUp}
				onKeyDown={internalOnKeyDown}
				onClick={onClick}
				placeholder={placeholder}
				value={value}
				disabled={disabled}
				name={name}
				ref={ref}
				{...props}
			/>
		);
	}
);

Input.displayName = 'Input';

export default Input;
