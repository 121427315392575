import React, {Component} from 'react';
import './DropdownFilterNoTitle.scss';

export class DropdownFilterNoTitle extends Component {
    constructor(prop) {
        super(prop);
        this.state = {
            selectedId: prop.selectedId,
            selectedName: this.getItemName(prop.selectedId)
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedId !== this.props.selectedId) {
            this.setState({
                selectedId: this.props.selectedId
            })
        }
    }

    getItemName(id) {
        const item = this.props.elements.find(e => e.id == id);
        return item ? item.name : this.props.defaultName;
    }

    onChange(evt) {

        const result = {
            id: evt.target.value,
            name: this.props.defaultName
        };

        const selectElementQuery = this.props.elements.filter((x) => x.id === result.id);

        if(selectElementQuery.length > 0) result.name = selectElementQuery[0].name;

        this.props.onChange(result);
    }

    render() {

        const { readonly, defaultName, elements } = this.props;

        let result;
        if(readonly) {
            const query = this.props.elements.filter((i) => i.id === this.state.selectedId);
            result = query.length > 0 ? query[0].name : '';
        }

        return <div className="DropdownFilterNoTitle">
            <div>
                {readonly && result}
                {!readonly && 
                    <select value={this.state.selectedId} className="form-control" onChange={(evt) => this.onChange(evt)}>
                        {defaultName && <option>{defaultName}</option>}
                        {elements.map((element, i) =>
                            <option key={i} value={element.id}>{element.name}</option>
                        )}
                    </select>
                }
            </div>
        </div>;
    }
}
