import React, { useContext } from 'react';
import { PermissionsContext } from '../../PermissionsContext';

const DashboardOverviewHead = () => {

    const { isSingleSite } = useContext(PermissionsContext);

    return (<thead>
        <tr>
            <th style={{ fontWeight: 700 }}>{isSingleSite ? 'Clinical Service Type' : 'Practices'}</th>
            <th className="text-center" style={{ fontWeight: 700 }}>Registered</th>
            <th className="text-center" style={{ fontWeight: 700 }}>Stipulations</th>
            <th className="text-center" style={{ fontWeight: 700 }}>Upcoming Renewal</th>
            <th className="text-center" style={{ fontWeight: 700 }}>Expired</th>
            <th className="text-center" style={{ fontWeight: 700 }}>Suspended</th>
            <th className="text-center" style={{ fontWeight: 700 }}>Cancelled</th>
            <th className="text-center" style={{ fontWeight: 700 }}>Invalid</th>
            <th className="text-center" style={{ fontWeight: 700 }}>Added</th>
            <th className="text-center" style={{ fontWeight: 700 }}>Deactivated</th>
        </tr>
    </thead>);
}

export default DashboardOverviewHead;