import { IconDefinition, IconLookup, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { customIcons } from './constants';

// "fac" for "Font Awesome Custom"
export type PhIconPrefix = IconPrefix | 'fac';
export type PhIconName = IconPrefix | keyof typeof customIcons;
export type PhIconProp = IconName | [PhIconPrefix, PhIconName] | IconLookup;

const createIconDefinition = (iconName: string, path: string): IconDefinition => ({
	prefix: 'fac' as IconPrefix,
	iconName: iconName as IconName,
	icon: [
		48, // width
		48, // height
		[], // ligatures
		'', // unicode
		path,
	],
});

export const customIconsObject = Object.entries(customIcons).reduce((icons, [key, value]) => {
	icons[key] = createIconDefinition(value.name, value.svg);
	return icons;
}, {} as Record<string, IconDefinition>);
