export const TypeOfNotification = 'Success' | 'Error';

export class NotificationsUtility {

    static notificationHandler;

    static addNotificationHandler(handler) {
        NotificationsUtility.notificationHandler = handler;
    }

    static showNotification(type, message) {
        if (NotificationsUtility.notificationHandler != null) {
            NotificationsUtility.notificationHandler(type, message);
        }
    }

    static showError(message) {
        NotificationsUtility.showNotification('Error', message);
    }

    static showSuccess(message) {
        NotificationsUtility.showNotification('Success', message);
    }
}
