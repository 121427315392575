import moment from 'moment';

export const convertUtcDateToLocal = (utcDate) => {

    if(Object.prototype.toString.call(utcDate) === "[object Date]") {
        const now = new Date();
        const dateNonUtc = new Date(utcDate.getTime() - now.getTimezoneOffset() * 60000);
        return moment(dateNonUtc).format('DD/MM/YYYY');
    }

	return moment.utc(utcDate).local().format('DD/MM/YYYY');

};

export const convertUtcDateToLocalCustom = (utcDate, format) => {
    
    if(Object.prototype.toString.call(utcDate) === "[object Date]") {
        const now = new Date();
        const dateNonUtc = new Date(utcDate.getTime() - now.getTimezoneOffset() * 60000);
        return moment(dateNonUtc).format(format);
    }
    return moment.utc(utcDate).local().format(format);

};

export const convertHtmlStringToString = (value) => {
    if (!value) {
        return '';
    }

    return value.replace("&amp;","&");
};