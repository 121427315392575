import React from 'react';
import './SimpleLabel.scss';

const SimpleLabel = ({title, value}) => (
    <div className="SimpleLabel">
        <h6>{title}</h6>
        <div>
            <span>{value}</span>
        </div>
    </div>
);

export default SimpleLabel;
