import axios from 'axios';
import { useAppHeaderNotification, useUserSession } from '@practicehub/assets';

function UserSessionProvider({ appSettings, user, render}) {
    const userSession = useUserSession(
        appSettings.isPlatformLogin,
        appSettings.logOutTimeout,
        appSettings.portalUrl,
        appSettings.practiceHubProxyApiUrl,
        user.userId,
        user.orgId,
        appSettings.domainName,
        axios
    );
    userSession.user = user;

    userSession.useAppHeaderNotification = useAppHeaderNotification(appSettings.apiUrl);

    return render(userSession);
}

export default UserSessionProvider;