import { post } from "./applicationApiActions";

class AlertsApi {
    static acknowledgeAlert({ ahpraRegistrationNumber, linkedRecordId, registrationType, alertType, fileNote }) {
        return post('/Alerts/Acknowledge', { ahpraRegistrationNumber, linkedRecordId, registrationType, alertType, fileNote });
    }

    static closeAlert({ ahpraRegistrationNumber, linkedRecordId, registrationType, alertType, fileNote }) {
        return post('/Alerts/Close', { ahpraRegistrationNumber, linkedRecordId, registrationType, alertType, fileNote });
    }
}

export default AlertsApi;
