import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { SimpleDropdown } from '../shared/SimpleDropdown/SimpleDropdown';
import SimpleTextArea from '../shared/SimpleTextArea/SimpleTextArea';
import { GlobalAlertUtility } from '../../utils/GlobalAlertUtility';
import * as Api from '../../api';

export class FileNoteEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.fileNote ? this.props.fileNote.id : '',
            note: this.props.fileNote ? this.props.fileNote.note : '',
            ahpraRegistrationId: this.props.fileNote ? this.props.fileNote.ahpraRegistrationId : null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fileNote !== this.props.fileNote) {
            this.setState({
                id: this.props.fileNote ? this.props.fileNote.id : '',
                note: this.props.fileNote ? this.props.fileNote.note : '',
                ahpraRegistrationId: this.props.fileNote ? this.props.fileNote.ahpraRegistrationId : null
            });
        }
    }

    save() {
        Api.fileNotesApi.createOrUpdate({
            id: this.state.id,
            ahpraRegistrationId: +this.state.ahpraRegistrationId,
            note: this.state.note,
            linkedRecordId: +this.props.linkedRecordId
        }).then(() => {
            GlobalAlertUtility.showSuccess(this.props.fileNote && this.props.fileNote.id ? 'File note record updated' : 'File note record added');
            this.closingwithSave();
        });
    }

    closing() {
        this.cleaningState();
        this.props.onClose();
    }

    closingwithSave() {
        this.cleaningState();
        this.props.onCloseWithSave();
    }

    cleaningState() {
        this.setState({
            note: '',
            ahpraRegistrationId: null,
        });
    }

    render() {
        return <Modal
            size="lg"
            show={this.props.show}
            onHide={() => this.closing()}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Add File Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>File Note</h3>
                <br />
                {this.props.ahpraRecords &&
                    <div className="row">
                        <div className="col-4">
                            <SimpleDropdown
                                title="Clinical Service Type"
                                elements={this.props.ahpraRecords}
                                defaultName={"Please select"}
                                selected={this.state.ahpraRegistrationId ?? ''}
                                onChange={evt => this.setState({ ahpraRegistrationId: evt.target.value })}
                            />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-12">
                        <SimpleTextArea
                            title="Note"
                            value={this.state.note}
                            onChange={value => this.setState({ note: value.target.value })}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.closing()}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={() => this.save()}>
                    {this.props.fileNote && this.props.fileNote.id ? "Save" : "Add"}
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}