import useFileUploadValidation from '../useFileUploadValidation';


const withFileUploadValidation = (Component: React.ElementType) => {
    const Wrapper = (props: object) => {
        const useFileSignature = useFileUploadValidation();
        return <Component useFileUploadValidation={useFileSignature} {...props} />;
    };

    return Wrapper;
};

export default withFileUploadValidation;
