import React, { ReactNode } from 'react';
import './actionMenu.scss';
import { classNames } from 'utils/util';
import Button from 'components/Button';
import Card from 'components/Card';
import { ButtonTypes, ButtonStatesType, SizeType } from 'components/Button/Button';
import IconButton from 'components/IconButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FloatingPortal } from '@floating-ui/react';
import useActionMenuFloating from './useActionMenuFloating';

export const ActionMenuIcon: Record<string, IconProp> = {
	angleDown: ['fal', 'angle-down'],
	ellipsis: ['fal', 'ellipsis-vertical'],
};

type ActionMenuButtonType = (typeof ButtonTypes)[number];

export const ActionMenuIconPositions = ['left', 'right'] as string[];
export type ActionMenuIconPosition = (typeof ActionMenuIconPositions)[number];

export interface ActionItem {
	groupHeader?: string;
	label?: string | ReactNode;
	disable?: boolean;
	selected?: boolean;
	href?: string;
	onClick?: () => void;
}

interface ActionMenuProps {
	text?: string;
	type?: ActionMenuButtonType;
	icon?: IconProp;
	iconPosition?: ActionMenuIconPosition;
	items: ActionItem[];
	className?: string;
	state?: ButtonStatesType;
	size?: SizeType;
}

const ActionMenu: React.FC<ActionMenuProps> = ({
	text = '',
	type = 'primary',
	icon = ActionMenuIcon.ellipsis,
	iconPosition = 'right',
	items,
	className,
	state = 'isNormal',
	size = 'medium',
}) => {
	const {
		isOpen,
		setIsOpen,
		actionMenuRef,
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		middlewareData,
	} = useActionMenuFloating();

	const handleActionItemClick = (item: ActionItem) => {
		if (item.disable) {
			return;
		}

		setIsOpen(false);

		if (item.onClick) {
			item.onClick();
		}
	};

	const classes = classNames([
		'ph-actionMenu',
		{
			isOpen: isOpen,
		},
		className,
	]);

	return (
		<div className={classes} ref={actionMenuRef}>
			{text?.length > 0 ? (
				<Button
					type={type}
					buttonText={text}
					leftIcon={iconPosition === 'left' ? icon : undefined}
					rightIcon={iconPosition === 'right' ? icon : undefined}
					ref={refs.setReference}
					state={state}
					size={size}
					{...getReferenceProps()}
				></Button>
			) : (
				<IconButton icon={icon} ref={refs.setReference} state={state} {...getReferenceProps()} />
			)}
			{isOpen && (
				<FloatingPortal {...getFloatingProps()}>
					<Card
						className="ph-actionMenu-items"
						ref={refs.setFloating}
						style={{
							...floatingStyles,
							display: middlewareData.hide?.referenceHidden ? 'none' : 'flex',
						}}
					>
						{items.map((item, index) => {
							const itemKey = `item-${index}`;
							if (item.groupHeader) {
								return <h4 key={itemKey}>{item.groupHeader}</h4>;
							}

							if (item.label) {
								return (
									<a
										key={itemKey}
										href={item.href}
										className={`ph-actionMenu-item ${item.disable ? 'disable' : ''} ${
											item.selected ? 'selected' : ''
										}`}
										onClick={() => handleActionItemClick(item)}
									>
										{item.label}
									</a>
								);
							}
						})}
					</Card>
				</FloatingPortal>
			)}
		</div>
	);
};

export default ActionMenu;
