import React from 'react';
import './SimpleInput.scss';
import {Form} from 'react-bootstrap';

const SimpleInput = ({title, value, onChange, readonly, error, type}) => (
    <div className="form-group SimpleInput">
        <label className="control-label">{title}</label>
        {readonly && (value || (type == "number" && value != 0)) && <p>{value}</p>}
        {!readonly && 
            <Form.Control
                type={type || "text"}
                className={`form-control ${error ? 'is-invalid' : ''}`}
                value={value || ''}
                onChange={onChange}
            />
        }
        {error && <div className="invalid-feedback">{error}</div>}
    </div>
);

export default SimpleInput;