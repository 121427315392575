import React from 'react';
import { classNames, generateCombinedKey } from 'utils/util';
import './breadcrumb.scss';

export type link = string | ToObject;

type ToObject = {
	pathname: string;
	search?: string;
	hash?: string;
	state?: { [key: string]: unknown };
};

type ContainerFunction = (link: link, title: string) => JSX.Element;

export type BreadcrumbType = {
	title: string;
	link: link;
	container: ContainerFunction;
};

interface BreadcrumbProps {
	breadcrumbs: BreadcrumbType[] | undefined;
}

interface BreadcrumbItemProps {
	breadcrumb: BreadcrumbType;
}

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ breadcrumb }) => {
	const { link, title, container } = breadcrumb;
	const content = link !== '' ? container(link, title) : <>{title}</>;
	return <li>{content}</li>;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => {
	const classes = classNames(['ph-breadcrumb']);

	if (!breadcrumbs || breadcrumbs.length === 0) {
		return null;
	}

	return (
		<nav aria-label="Breadcrumb" className={classes}>
			<ol>
				{breadcrumbs.map((breadcrumb, index) => (
					<BreadcrumbItem key={generateCombinedKey(breadcrumb.title, index)} breadcrumb={breadcrumb} />
				))}
			</ol>
		</nav>
	);
};

export default Breadcrumb;
