import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
const signUpSignInPolicy = window.PH_SignUpOrSignInPolicy;
const signUpSignInPolicyMaad = window.PH_SignUpOrSignInPolicyMaad;
const signUpSignInAuthority = window.PH_SignUpSignInAuthority;
const authorityDomain = window.PH_AuthorityDomain;
const clientId = window.PH_ClientId;
const b2cScopes = window.PH_Scopes;

const isExternalUserCookie = ('; ' + document.cookie).split(`; isExternalUser=`).pop().split(';')[0];
const isExternalUser = isExternalUserCookie.toLowerCase() === 'true';
const policy =  isExternalUser ? signUpSignInPolicyMaad : signUpSignInPolicy;
const policyAuthority = signUpSignInAuthority + '/' + policy;

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: policy,
    },
    authorities: {
        signUpSignIn: {
            authority: policyAuthority
        }
    },
    authorityDomain: authorityDomain
}

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {

        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {

                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    scopes: ["openid", ...b2cScopes]
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
    scopes: [...b2cScopes],
    forceRefresh: false
};