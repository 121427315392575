import React from 'react';
import { Alert } from 'index';

interface ITableCustomNoRowsOverlay {
	message?: string;
}

const TableCustomNoRowsOverlay: React.FC<ITableCustomNoRowsOverlay> = ({ message }) => {
	return (
		<Alert message={message ?? 'There are no entries to display.'} state="isInformation" showCloseButton={false} />
	);
};

export default TableCustomNoRowsOverlay;
