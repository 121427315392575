import React from 'react';
import './skeleton.scss';
import { classNames } from 'utils/util';

interface SkeletonProps {
	animation?: 'pulse';
	variant?: 'text' | 'circular' | 'rectangular' | 'rounded';
	width?: number;
	height?: number;
	style?: object;
}

const Skeleton: React.FC<SkeletonProps> = ({ animation = 'pulse', variant = 'text', width, height, style }) => {
	const classes = classNames([
		'ph-skeleton',
		animation,
		{
			[variant]: variant,
		},
	]);
	return (
		<span
			className={classes}
			style={{
				width,
				height,
				...style,
			}}
		></span>
	);
};

export default Skeleton;
