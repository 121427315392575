import React, { Component } from 'react';
import './RegistrationDetail.scss';
import { Link } from 'react-router-dom';
import UserDetail from '../UserDetail/UserDetail';
import { FileNotes } from '../FileNotes/FileNotes';
import SimpleTab from '../shared/SimpleTab/SimpleTab';
import { AHPRARecord } from '../AHPRARecord/AHPRARecord';
import { Alerts } from '../Alerts/Alerts';
import Heading from '../shared/Heading/Heading';
import RegistrationDetailDropdown from './RegistrationDetailDropdown';
import { RegistrationSelector } from '../RegistrationSelector/RegistrationSelector';
import { SendEmailModal } from './SendEmailModal';
import { ConfirmDialogUtility } from '../../utils/ConfirmDialogUtility';
import { GlobalAlertUtility } from '../../utils/GlobalAlertUtility';
import { SuccessMessage } from '../../Constants';
import * as Api from '../../api';

export class RegistrationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInitialize: true,
            userDetail: {},
            ahpraDetails: [],
            fileNotes: [],
            selectedTab: '',
            isLoading: false,
            showSendEmailModal: false,
            isLoadingFileNote: false,
            showRegistrationEditor: false,
            isLicenseAvailable: false,

            isAHPRARecordRequiredToCloseAlert: false,
            currentAlert: null,
            currentCloseAlertAction: null
        };
        this.openRegistrationEditor = this.openRegistrationEditor.bind(this);
        this.deactivateRecord = this.deactivateRecord.bind(this);
        this.setRecordActive = this.setRecordActive.bind(this);
        this.showSendEmailModal = this.showSendEmailModal.bind(this);
        this.synchroniseAhpraData = this.synchroniseAhpraData.bind(this);
        this.isEmptyAhpraRecord = this.isEmptyAhpraRecord.bind(this);
        this.openRegistrationEditorAndCloseAlert = this.openRegistrationEditorAndCloseAlert.bind(this);
    }

    componentDidMount() {
        document.title = 'Ahpra Alerts - Detail';
        this.setState({
            isLoading: true,
            isAHPRARecordRequiredToCloseAlert: false,
            currentAlert: null,
            currentCloseAlertAction: null
        });
        this.getAvailableLicenseAndGetRecord();
    }

    getAvailableLicenseAndGetRecord() {
        Api.licenseApi.isLicenseAvailable().then((result) => {
            if (result === 'true') {
                this.setState({ isLicenseAvailable: true }, () => {
                    this.getUserDetail();
                });
            }
            else {
                this.getUserDetail();
            }
        });
    }

    getUserDetail() {
        this.setState({ isLoading: true });
        
        Api.registrationApi.getDetail(this.props.linkedRecordId)
            .then((result) => {
                
                this.setState({
                    userDetail: result,
                    isLoading: false,
                    ahpraDetails: result.ahpraDetails,
                    selectedTab: result.ahpraDetails && result.ahpraDetails.length > 0 ? result.ahpraDetails[0].id.toString() : '',
                    fileNotes: result.fileNotes
                });

                if(this.state.isInitialize) {
                    Api.recentlyViewedApi.saveRecentlyViewed({
                        absoluteCallerUrl: window.location.href.split("?")[0],
                        name: this.getFullName(),
                    });
                }

                this.setState({isInitialize: false});
                
            });
    }

    getFileNote() {
        this.setState({ isLoadingFileNote: true });

        Api.fileNotesApi.get(this.props.linkedRecordId)
            .then((data) => {
                this.setState({
                    fileNotes: data,
                    isLoadingFileNote: false
                });

            });
    }

    synchroniseAhpraData() {
        this.setState({ isLoading: true });
        Api.registrationApi.synchroniseAhpraData(this.props.linkedRecordId)
            .then((result) => {
                this.setState({
                    userDetail: result,
                    isLoading: false,
                    ahpraDetails: result.ahpraDetails,
                    selectedTab: result.ahpraDetails && result.ahpraDetails.length > 0 ? result.ahpraDetails[0].id.toString() : '',
                    fileNotes: result.fileNotes
                });

            });
    }

    getFullName() {
        // US#15764 - Dynamic Decoupling - covers when user/staff not migrated or not found yet.
        // Note: Organisation or user or Affiliation record are not found 
        let fullName = this.state.userDetail && this.state.userDetail.firstName && this.state.userDetail.lastName ? `${this.state.userDetail.firstName} ${this.state.userDetail.lastName}` :
            this.state.userDetail.staffRecordId ? `Staff Not Found/Not Migrated yet` :
                this.state.userDetail.userId ? `User Not Found/Not Migrated yet` : "-";
        return fullName;
    }

    /**
     * get the list of ahpra registration profession, with specifications: \n\r
     * 1. 
     * 
     * @returns the list of profession and ahpra reg id
     */
    getAvailableAhpraRecords() {

        const {
            ahpraDetails
        } = this.state;

        var availableAhpraRecords = ahpraDetails?.map(ahpraDetail => {
            const professions = ahpraDetail.professions?.filter(p => p.name);
            let profResult = [];
            if (professions && professions.length > 0) { // Card#5741 - only taken the first row
                profResult.push({
                    name: professions[0].name,
                    value: ahpraDetail.id + ''
                })
            } else if (ahpraDetail.clinicalServiceType) {// c
                profResult.push({
                    name: ahpraDetail.clinicalServiceType,
                    value: ahpraDetail.id + ''
                })
            } else {
                profResult.push({
                    name: "None",
                    value: ahpraDetail.id + ''
                })
            }
            return profResult;
        });
        var availableProfessionTabs = [];
        if (availableAhpraRecords && availableAhpraRecords.length > 0) {
            availableProfessionTabs = availableAhpraRecords.reduce((prevValue, curValue) => {
                if (!prevValue) {
                    prevValue = [];
                }
                if (!curValue) {
                    curValue = [];
                }
                return prevValue.concat(curValue);
            });
        }
        return availableProfessionTabs;
    }

    getCurrentAhpraRecord() {
        return this.state.ahpraDetails?.find(u => u.id + '' === this.state.selectedTab);
    }

    onTabChange(value) {
        this.setState({
            selectedTab: value
        });
    }

    onFileNotesClose() {
        //this.getUserDetail();
    }

    onFileNotesCloseWithSave() {
        this.getFileNote();
    }

    openRegistrationEditor() {
        this.setState({ showRegistrationEditor: true });
    }

    onRegistrationSelectorCloseWithUpdate() {
        if (this.state.isAHPRARecordRequiredToCloseAlert && this.state.currentAlert && this.state.currentCloseAlertAction) {
            // This registration form is opened when user clicks on Close Alert action
            // After user finishes we neeed to reopen close alert
            this.setState({ showRegistrationEditor: false });
            this.state.currentCloseAlertAction(this.state.currentAlert);
            return;
        }

        this.setState({ showRegistrationEditor: false });
        this.getUserDetail();
    }

    openRegistrationEditorAndCloseAlert(closeAlertAction, alert) {
        this.openRegistrationEditor();
        this.setState({
            isAHPRARecordRequiredToCloseAlert: true,
            currentAlert: alert,
            currentCloseAlertAction: closeAlertAction
        });
    }

    onRegistrationSelectorClose() {
        this.setState({ showRegistrationEditor: false });
    }

    setRecordActive() {
        Api.linkedRecordsApi.updateRecordStatus(this.props.linkedRecordId, 1).then(() => {
            this.getUserDetail();
            GlobalAlertUtility.showSuccess(SuccessMessage.USER_ACTIVATE.replace('{0}', this.getFullName()));
        });
    }

    deactivateRecord() {
        ConfirmDialogUtility.showConfirmDialog(`Are you sure you want to deactivate '${this.getFullName()}'?`, deactivateResult => {
            if (deactivateResult) {
                ConfirmDialogUtility.showConfirmDialog('Do you wish to also make this record inactive?', inactiveResult => {
                    if (inactiveResult) {
                        Api.linkedRecordsApi.updateRecordStatus(this.props.linkedRecordId, 3).then(() => {
                            this.getUserDetail();
                            GlobalAlertUtility.showSuccess(SuccessMessage.USER_INACTIVATE.replace('{0}', this.getFullName()));
                        });
                    }
                    else {
                        Api.linkedRecordsApi.updateRecordStatus(this.props.linkedRecordId, 2).then(() => {
                            this.getUserDetail();
                            GlobalAlertUtility.showSuccess(SuccessMessage.USER_DEACTIVATE.replace('{0}', this.getFullName()));
                        });
                    }

                });
            }
        });
    }

    showSendEmailModal() {
        this.setState({ showSendEmailModal: true });
    }    

    closeSendEmailModal(emailSent) {
        this.setState({ showSendEmailModal: false });
        if (emailSent) this.getStaffRecordDetail();
    }

    isEmptyAhpraRecord() {
        var ahpraRecord = this.getCurrentAhpraRecord();
        return ahpraRecord === null || ahpraRecord === undefined;
    }

    openRegistrationEditorIfAhpraRegistrationNumberIsEmpty() {
        var ahpraRecord = this.getCurrentAhpraRecord();
        if (ahpraRecord !== null && ahpraRecord !== undefined) {
            return false;
        }

        this.setState({ showRegistrationEditor: true });
        return true;
    }

    render() {
        const ahpraRecord = this.getCurrentAhpraRecord();
        const ahpraTabs = this.getAvailableAhpraRecords();

        return <div className="RegistrationDetail">
            {!this.state.isLoading &&
                <>
                    <Heading
                        colClasses='col-9'
                        title={this.getFullName()}
                        titleElement={
                            <h3 title={this.getFullName()} className='overflow-inner'>
                                <span className='page-title-inner'>{this.getFullName()}</span>
                            </h3>
                        }
                        breadcrumbs={
                            <div className='col-md-12'>
                                <ol className='breadcrumb float-left'>
                                    <li className='breadcrumb-item'>
                                        <Link to='/'>Ahpra Alerts</Link>
                                    </li>
                                    <li className='breadcrumb-item'>
                                        <Link to='/registrations-list'>Registration List</Link>
                                    </li>
                                    <li className='breadcrumb-item active'>
                                        <span>{this.getFullName()}</span>
                                    </li>
                                </ol>
                            </div>
                        }
                    />
                    <RegistrationDetailDropdown
                        provisioningStatus={this.state.userDetail.provisioningStatus}
                        registrationRecords={this.state.ahpraDetails}
                        openRegistrationEditor={this.openRegistrationEditor}
                        deactivateRecord={this.deactivateRecord}
                        showSendEmailModal={this.showSendEmailModal}
                        synchroniseAhpraData={this.synchroniseAhpraData}
                        setRecordActive={this.setRecordActive} />
                    <div style={{ clear: 'both' }} />
                    <div className='row scrollable-content'>
                    <div className='col-12'>
                    <Alerts
                        alerts={this.state.userDetail.alerts}
                        loadingEvent={(isLoading) => this.setState({ isLoading: isLoading })}
                        reload={() => this.getUserDetail()}
                        isEmptyAhpraRecord={this.isEmptyAhpraRecord}
                        openRegistrationEditorAndCloseAlert={this.openRegistrationEditorAndCloseAlert}
                    />
                    <div className="row">
                        <div className="col">
                            {(this.state.userDetail.provisioningStatus !== 'NewNoLicense' ||
                                (this.state.userDetail.provisioningStatus === 'NewNoLicense' && this.state.isLicenseAvailable)) &&
                                this.state.ahpraDetails?.length > 0 &&
                                <>
                                    <SimpleTab
                                        selectedValue={this.state.selectedTab}
                                        tabs={ahpraTabs}
                                        onChange={value => this.onTabChange(value)}
                                    />
                                </>
                            }
                            <div className="row">
                                <div className="col-9">
                                    {(this.state.userDetail.provisioningStatus !== 'NewNoLicense' ||
                                        (this.state.userDetail.provisioningStatus === 'NewNoLicense' && this.state.isLicenseAvailable)) &&
                                        <AHPRARecord ahpraRecord={this.getCurrentAhpraRecord()} />
                                    }
                                    {(this.state.userDetail.provisioningStatus === 'NewNoLicense' && !this.state.isLicenseAvailable) &&
                                        <>
                                            <div className="card shadow-sm rounded-lg">
                                                <div className="card-body">
                                                    <h6>This record is disabled, because you have no licences available. To purchase additional licences please contact our sales team.</h6>
                                                </div>
                                            </div>

                                        </>
                                    }
                                    {this.state.fileNotes && this.state.fileNotes.length > 0 &&
                                        <FileNotes
                                            onClose={() => this.onFileNotesClose()}
                                            onCloseWithSave={() => this.onFileNotesCloseWithSave()}
                                            fileNotes={this.state.fileNotes}
                                            ahpraRecords={ahpraTabs}
                                            linkedRecordId={this.props.linkedRecordId}
                                            provisioningStatus={this.state.userDetail.provisioningStatus}
                                            isLoading={this.state.isLoadingFileNote}
                                            isLicenseAvailable={this.state.isLicenseAvailable}
                                        />
                                    }
                                </div>
                                <div className="col-3">
                                    <UserDetail staffRecordDetail={this.state.userDetail} linkedRecordId={this.props.linkedRecordId} ahpraRecord={ahpraRecord ? ahpraRecord : null} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.showRegistrationEditor && <RegistrationSelector
                        show={this.state.showRegistrationEditor}
                        onClose={() => this.onRegistrationSelectorClose()}
                        onCloseWithUpdate={() => this.onRegistrationSelectorCloseWithUpdate()}
                        linkedRecordId={this.props.linkedRecordId}
                    />}
                    {this.state.showSendEmailModal &&
                        <SendEmailModal
                            onClose={(emailSent) => this.closeSendEmailModal(emailSent)}
                            linkedRecordId={this.props.linkedRecordId}
                            to={this.state.userDetail.email}
                            toName={`${this.getFullName()}`}
                            from={this.state.userDetail.currentUserEmail}
                            fromName={this.state.userDetail.currentUserName}
                        />
                    }
                </div>
                </div>
            </>
        }
        {this.state.isLoading &&
            <h3 className="text-center font-weight-bold">
                <i className="fas fa-spinner fa-spin"></i> Loading
            </h3>
        }
    </div>;
    }
}