import React, { ReactNode } from 'react';
import './alert.scss';
import { classNames } from 'utils/util';
import Icon from 'components/Icons/Icon';
import IconButton from 'components/IconButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface AlertProps {
	message: ReactNode;
	onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
	icon?: IconProp;
	state?: keyof typeof AlertStates;
	showCloseButton: boolean;
	header?: string;
	action?: ReactNode;
}

const Alert: React.FC<AlertProps> = ({
	message,
	onClick,
	icon,
	state = AlertStates.DEFAULT,
	showCloseButton,
	header,
	action,
}) => {
	const classes = classNames(['ph-alert', state]);
	const alertIcon = icon || AlertIcons[state];

	return (
		<div className={classes}>
			<Icon icon={alertIcon} className="ph-alert-icon" />
			<div>
				{header && <h4>{header}</h4>}
				<p>{message}</p>
				{action && <div className="ph-alert-action">{action}</div>}
			</div>
			{showCloseButton ? <IconButton icon={['fal', 'xmark']} onClick={onClick} /> : null}
		</div>
	);
};

export default Alert;

export const AlertStates: Record<string, string> = {
	DEFAULT: 'isDefault',
	INFORMATION: 'isInformation',
	SUCCESS: 'isSuccess',
	WARNING: 'isWarning',
	DANGER: 'isDanger',
};

const AlertIcons: Record<string, IconProp> = {
	[AlertStates.DEFAULT]: ['fal', 'exclamation-circle'],
	[AlertStates.INFORMATION]: ['fal', 'exclamation-circle'],
	[AlertStates.SUCCESS]: ['fal', 'check-circle'],
	[AlertStates.WARNING]: ['fal', 'exclamation-circle'],
	[AlertStates.DANGER]: ['fal', 'triangle-exclamation'],
};
