import React from 'react';
import './SimpleTab.scss';

const SimpleTab = ({selectedValue, tabs, onChange}) => {
    return <table className="SimpleTab">
        <thead>
        <tr>
            {tabs && tabs.map((item, i) => {
                return <th key={i}
                           className={item.value === selectedValue ? 'selected' : ''}
                           onClick={() => onChange(item.value)}>
                    {item.name ? item.name : 'None'}
                </th>;
            })}
        </tr>
        </thead>
    </table>;
};

export default SimpleTab;
