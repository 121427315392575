export class GlobalAlertUtility {

    static alertHandler = (type, message) => { return; }

    static showAlert(type, message) {
        if (GlobalAlertUtility.alertHandler != null) {
            GlobalAlertUtility.alertHandler(type, message);
        }
    }

    static showError(message) {
        GlobalAlertUtility.showAlert('Error', message);
    }

    static showSuccess(message) {
        GlobalAlertUtility.showAlert('Success', message);
    }
}