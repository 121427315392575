import React, { useState, useEffect, forwardRef } from 'react';
import './textArea.scss';
import { classNames } from 'utils/util';
import ErrorMessage from 'components/ErrorMessage';
import FormLabel from 'components/FormLabel';
import CharLimit from 'components/CharLimit';

interface TextAreaProps {
	id: string;
	value?: string;
	label?: string;
	placeholder?: string;
	isMandatory?: boolean;
	error?: string;
	rows?: number;
	disabled?: boolean;
	className?: string;
	name?: string;
	charLimit?: number;
	onChange?: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<TextAreaProps> = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	(
		{
			id,
			value = '',
			label = '',
			placeholder = '',
			isMandatory = false,
			error = '',
			className,
			disabled = false,
			rows = 5,
			charLimit = 0,
			name,
			onChange,
			...props
		},
		ref
	) => {
		const [inputValue, setInputValue] = useState(value);
		const [errorMessage, setErrorMessage] = useState(error);
		const [isInvalid, setIsInvalid] = useState(false);

		const onInternalChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
			const value = evt.target.value;
			setInputValue(value);
			if (onChange) onChange(evt);

			charLimit > 0 && value.length > charLimit ? setIsInvalid(true) : setIsInvalid(false);
		};

		useEffect(() => {
			setErrorMessage(error);
		}, [error]);

		useEffect(() => {
			setInputValue(value);
		}, [value]);

		const classes = classNames([
			'ph-input',
			{
				hasError: errorMessage || isInvalid,
			},
			className,
		]);

		return (
			<div className="ph-text-area">
				{label ? (
					<div className="ph-input-label">
						<FormLabel label={label} htmlIdFor={id} isMandatory={isMandatory} />
						<CharLimit
							charLimit={charLimit}
							className={className}
							isInvalid={isInvalid}
							inputValue={inputValue}
						/>
					</div>
				) : null}

				<div className="ph-input-field">
					<textarea
						id={id}
						autoComplete="off"
						disabled={disabled}
						name={name}
						placeholder={placeholder}
						wrap="soft"
						value={inputValue}
						rows={rows}
						className={classes}
						onChange={onInternalChange}
						ref={ref}
						{...props}
					/>
				</div>
				{errorMessage && <ErrorMessage error={errorMessage} />}
				{isInvalid && <ErrorMessage error={'This field cannot exceed ' + charLimit + ' characters'} />}
			</div>
		);
	}
);

TextArea.displayName = 'TextArea';

export default TextArea;
