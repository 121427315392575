import React from 'react';
import './errorPage.scss';
import { Icon } from 'index';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ErrorPageProps {
	content: string;
	className?: string;
	iconClass?: IconProp;
	imageSrc?: string;
	strongContent?: string;
	link?: string;
	linkText?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
	iconClass,
	strongContent,
	content,
	className,
	link,
	linkText,
	imageSrc,
}) => {
	return (
		<div className={`ph-error-page ${className}`}>
			{iconClass && <Icon icon={iconClass} />}
			{imageSrc && <img src={imageSrc} alt="" />}
			{strongContent && <strong>{strongContent}</strong>}
			<p>{content}</p>
			{linkText && <a href={link}>{linkText}</a>}
		</div>
	);
};

export default ErrorPage;
