import React, { useState } from 'react';
import CountrySelect from 'components/CountrySelect';
import './phoneInput.scss';
import { classNames } from 'utils/util';
import withDeprecationWarning from 'utils/hoc/withDeprecationWarning';

interface CountryCode {
	name: string;
	value: string;
}

interface PhoneInputProps {
	label: string;
	countryCodes: CountryCode[];
	note?: string;
	isMandatory?: boolean;
	error?: string;
	placeholder?: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ label, countryCodes, note, isMandatory = false, error }) => {
	const [currentValue, setCurrentValue] = useState('');
	const [countryCode, setCountryCode] = useState(countryCodes[0].value);

	if (!countryCodes) return null;

	const classes = classNames(['ph-phoneInput', { hasError: error, isMandatory: isMandatory, hasNoLabel: !label }]);

	const countryCodeSelectHandler = (countryCode: CountryCode) => {
		setCountryCode(countryCode.value);
	};

	const phoneNumberInputChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
		let value = evt.target.value;

		if (value.length < countryCode.length + 1) return '';

		value = value.replace(`${countryCode} `, '');

		value = value.replace(/[^\d\s]/g, '');

		setCurrentValue(value);
	};

	return (
		<label aria-label={label} className={classes}>
			{label ? <span className="ph-phoneInput-label">{label}</span> : null}
			{note ? <span className="ph-phoneInput-note">{note}</span> : null}
			<div className="ph-phoneInput-group">
				<CountrySelect
					options={countryCodes}
					onChange={countryCodeSelectHandler}
					displayValueNextToName={true}
				/>
				<input
					type="text"
					className="ph-phoneInput-input"
					value={`${countryCode} ${currentValue}`}
					onChange={phoneNumberInputChangeHandler}
				/>
			</div>
			{error && <span className="ph-phoneInput-error">{error}</span>}
		</label>
	);
};

export default withDeprecationWarning(PhoneInput);
