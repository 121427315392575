import './userNotifications.scss';
import { classNames } from 'utils/util';
import IconButton from 'components/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import Notification, { NotificationProps } from 'components/Notification/Notification';
import useCheckOutsideClick from 'components/useCheckOutsideClick/useCheckOutsideClick';

const UserNotifications: React.FC<NotificationProps> = ({
	notification,
	updateNotification,
	notificationContentComponent: NotificationContent,
}) => {
	const [isAppHeaderNotificationOpen, setAppHeaderNotificationOpen] = useState(false);
	const userNotificationRef = useRef<HTMLDivElement>(null);

	const onToggle = () => {
		// Clear notification array when Notification component is closed
		if (!isAppHeaderNotificationOpen) {
			notification.clearNotifications();
		}
		setAppHeaderNotificationOpen((prev) => !prev);
	};

	useEffect(() => {
		if (isAppHeaderNotificationOpen && !notification.isLoading) {
			notification.fetchNotifications();
		}
	}, [isAppHeaderNotificationOpen]);

	useCheckOutsideClick(userNotificationRef, () => {
		setAppHeaderNotificationOpen(false);
	});

	const classes = classNames([
		{
			unRead: notification.notifications.isAnyUnread,
			isLoading: notification.isLoading,
			isOpen: isAppHeaderNotificationOpen,
		},
	]);

	return (
		<div ref={userNotificationRef}>
			<IconButton
				icon={isAppHeaderNotificationOpen ? ['fas', 'bell'] : ['far', 'bell']}
				onClick={onToggle}
				className={classes}
			/>
			{isAppHeaderNotificationOpen && (
				<Notification
					notification={notification}
					updateNotification={updateNotification}
					notificationContentComponent={NotificationContent}
				/>
			)}
		</div>
	);
};

export default UserNotifications;
