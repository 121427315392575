import React, { useState } from 'react';
import './buttonGroup.scss';
import Button, { ButtonProps, SizeType } from 'components/Button';
import { TypeWithoutKeys } from 'utils/types';
import { classNames } from 'utils/util';

/**
 * The ButtonGroupButton is a type that does not
 * expose the type and size property.
 *
 * These properties are instead accepted by ButtonGroup to
 * allow ButtonGroup to keep the size of all buttons
 * consistent and also enforce the restriction of having
 * only one primary button in ButtonGroup.
 */

type ButtonGroupButton = TypeWithoutKeys<ButtonProps, 'type' | 'size'>;

interface ButtonGroupProps {
	buttons: ButtonGroupButton[];
	initialActiveButtonIndex?: number;
	size?: SizeType;
	className?: string;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ buttons, initialActiveButtonIndex, size, className }) => {
	if (buttons.length <= 1) {
		throw new Error(
			'Cannot create a button group with fewer than 2 buttons. Use the `Button` component instead if you\'re trying to create a single button.'
		);
	}

	const classes = classNames(['ph-button-group', className]);

	const [activeButtonIndex, setActiveButtonIndex] = useState(initialActiveButtonIndex);

	const handleButtonClick =
		(index: number, onClick?: (event?: React.MouseEvent<HTMLElement>) => void) =>
		(event?: React.MouseEvent<HTMLElement>) => {
			setActiveButtonIndex(index);

			onClick?.(event);
		};

	return (
		<div className={classes}>
			{buttons.map((buttonProps, index) => {
				return (
					<Button
						{...buttonProps}
						key={index}
						size={size}
						type={activeButtonIndex === index ? 'primary' : 'secondary'}
						onClick={handleButtonClick(index, buttonProps.onClick)}
					/>
				);
			})}
		</div>
	);
};

export default ButtonGroup;
