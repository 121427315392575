import React from 'react';
import './Popover.scss';

export class Popover extends React.PureComponent {
    state = {
        isOpen: false
    };
    toggle = (evt) => {
        if (evt) evt.preventDefault();
        this.setState({ isOpen: !this.state.isOpen });
    }

    collapse = () => {
        this.setState({ isOpen: false });
    }

    clickContentHandler = () => {
        if (this.props.clickToDismiss) {
            this.setState({ isOpen: false });
        }
    }
    render() {
        const { isOpen } = this.state;
        const { title, countlabel, label, children } = this.props;

        return (
            <div className="simple-popover" onBlur={this.collapse} tabIndex="-1">
                <div className="simple-popover-label background" onClick={this.toggle}>
                    {label != null &&
                        <div><span className="count-label">{countlabel}</span>&nbsp;<span>{label}</span></div>
                    }
                </div>
                {isOpen &&
                    <div className="simple-popover-dropdown" onClick={this.clickContentHandler}>
                        {title && <div className="simple-popover-title">{title}</div>}
                        <div className="simple-popover-content">
                            {children}
                        </div>
                    </div>
                }
            </div>
        );
    }
}