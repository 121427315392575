import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './GlobalAlert.scss';
import {Toast} from 'react-bootstrap';
import { GlobalAlertUtility } from '../../utils/GlobalAlertUtility';


const GlobalAlert = () => {
    const [show, setShow] = useState('hidden');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const location = useLocation()

    useEffect(() => {
            setMessageType('success');
            setMessage('');
            setShow('hidden');
      }, [location]);

    useEffect(() => {
        GlobalAlertUtility.alertHandler = (msgType, bodyMessage) => {
            msgType === 'Error' ? setMessageType('warning') : setMessageType('success');
            setMessage(bodyMessage);
            setShow('');
        };
        
    }, []);

    return <div className={`float-message alert alert-${messageType} ${show}`} onClick={() => setShow('hidden')}><strong>{message}</strong></div>;
};

export default GlobalAlert;
