import React, { useEffect, useState } from 'react';
import Radio, { RadioProps } from 'components/Radio';
import './radiogroup.scss';
import { classNames } from 'utils/util';

interface RadioGroupProps {
	label: string;
	radios: RadioProps[];
	name: string;
	className?: string;
}

const findCheckedRadioId = (radios: RadioProps[]) => {
	const checkedRadio = radios.find((radio) => radio.checked);
	return checkedRadio ? checkedRadio.id : null;
};

const RadioGroup: React.FC<RadioGroupProps> = ({ label, radios, name, className }) => {
	const [selectedRadio, setSelectedRadio] = useState<string | null>(() => {
		return findCheckedRadioId(radios);
	});

	useEffect(() => {
		setSelectedRadio(findCheckedRadioId(radios));
	}, [radios]);

	return (
		<fieldset className={classNames(['ph-radioGroup', className])} role="radiogroup">
			{!!label && <legend>{label}</legend>}
			{radios.length
				? radios.map((radio: RadioProps) => (
						<Radio
							key={radio.id}
							id={radio.id}
							label={radio.name}
							disabled={radio.disabled}
							value={radio.value}
							name={name}
							className={radio.className}
							checked={selectedRadio === radio.id}
							handleRadioChange={(event) => {
								setSelectedRadio(event.target.id);
								return radio.handleRadioChange?.(event);
							}}
						/>
				  ))
				: null}
		</fieldset>
	);
};

export default RadioGroup;
