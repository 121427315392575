import { Organisation } from 'components/OrgSwitcher';
import TextInput from 'components/TextInput';
import React, { useCallback, useState } from 'react';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import Alert from '../../Alert/Alert';
import Icon from 'components/Icons/Icon';
import './trialBanner.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { useForm } from 'components/useForm';
import { ValidationRules } from 'components/useForm/type';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isTomorrow);

interface TrialBannerProps {
	org: Organisation,
	user: { userFirstName: string, userLastName: string, userEmail: string, userPhone: string },
	onSubmitHandler: (t: { Name: string, Email: string, ContactNumber: string }) => Promise<void>,
}

export const validationRules: ValidationRules = {
	Name: (name: string) => {
		if (!name) {
			return 'Name cannot be empty.';
		}
		return null;
	},

	Email: (Email: string) => {
		if (!Email) {
			return 'Email cannot be empty.';
		}
		if (!/^[\w\.!#$%^&*\(\)\{\}\[\]\\\+\/=<>|?,-:"';]+@([\w-]+\.)+[\w-]{2,4}$/.test(Email)) {
			return 'Email format is invalid.';
		}
		return null;
	},

	ContactNumber: (contactNumber: string) => {
		if (!contactNumber) {
			return 'Contact Number cannot be empty.';
		}
		return null;
	}
};

const generateBannerMessage = (trialEndDateUtc: string) => {
	const trialEndDateLocal = dayjs.utc(trialEndDateUtc).tz(dayjs.tz.guess()).startOf('day');
	const trialEndsIn = trialEndDateLocal.diff(dayjs().startOf('day'), 'day');
	const endDayText = trialEndsIn > 1 ? `in ${trialEndsIn} days` : 'today';

	return `Your trial ends ${endDayText}`;
};

const TrialBanner: React.FC<TrialBannerProps> = ({ org, user, onSubmitHandler }) => {
	const { userFirstName, userLastName, userEmail, userPhone } = user;
	const [loading, setLoading] = useState(false);

	const initialValues = {
		Name: `${userFirstName} ${userLastName}`,
		Email: userEmail,
		ContactNumber: userPhone
	};

	const { values, handleChange, handleSubmit, hasError, errors, resetForm } = useForm(
		{
			...initialValues
		},
		() => {
			setLoading(true);
			onSubmitHandler(values).then(() => {
				setLoading(false);
				setShowThankYou(true);
			});
		},
		validationRules
	);

	const { trialEndDateUtc } = org;
	const [showUpgradeModal, setShowUpgradeModal] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);
	const upgradeClickHandler = useCallback(() => {
		setShowThankYou(false);
		setShowUpgradeModal(true);
		resetForm();
	}, []);

	const closeUpgradeModal = useCallback(() => {
		setShowUpgradeModal(false);
	}, []);

	if (!trialEndDateUtc) {
		return null;
	}

	const thankYou = () =>
		<div className="thank-you">
			<div>
				<p className="title"><strong>Thank You!</strong></p>
				<br />
				<p>We've received your details.</p>
				<p>Someone from our team will contact you soon.</p>
			</div>
		</div>

	return (
		<div className="ph-trial-message">
			<Alert
				message={
					<>
						{generateBannerMessage(trialEndDateUtc)}
						<Button buttonText="Upgrade" onClick={upgradeClickHandler} size="medium" />
					</>
				}
				showCloseButton={false}
				state="isWarning"
				icon={['far', 'clock']}
			/>

			<Modal
				show={showUpgradeModal}
				title="Upgrade"
				className="ph-upgrade-modal"
				size="medium"
				onClose={closeUpgradeModal}
				onCancel={closeUpgradeModal}
				showFooter={false}
			>
				{showThankYou && thankYou()}
				{!showThankYou && <>
					<h3>We hope you're enjoying PracticeHub and the free trial! </h3>
					<div className="ph-upgrade-content">
						<div className="content-left">
							<div className="upgrade-form">
								<p>Keep your practice running smoothly</p>
								<strong>Contact our sales team today to learn more</strong>
								<form onSubmit={handleSubmit}>
									<TextInput value={values.Name} onChange={handleChange} error={errors['Name'] ?? ''} name="Name" label="Name" id="Name" />
									<TextInput value={values.Email} onChange={handleChange} name="Email" error={errors['Email'] ?? ''} label="Email" id="Email" />
									<TextInput type='number' ignoreSpecialCharacterForNumberInput={true} value={values.ContactNumber} onChange={handleChange} name="ContactNumber" error={errors['ContactNumber'] ?? ''} label="Contact Number" id="ContactNumber" />
									<Button
										id="button-upgrade-form-submit"
										state={hasError || loading ? 'isDisabled' : 'isNormal'}
										onClick={handleSubmit}
										buttonText={loading ? 'Submitting' : 'Submit'}
									/>
								</form>
							</div>
							<ul>
								<li>
									<Icon icon={['fal', 'envelope']} />
									<a href="mailto:practicesolutions@avant.org.au">practicesolutions@avant.org.au</a>
								</li>
								<li>
									<Icon icon={['fal', 'phone']} />
									1800 010 236
								</li>
							</ul>
						</div>
						<div className="content-right">
							<p>Here are the benefits you'll continue to enjoy after the upgrade:</p>
							<ul>
								<li>
									<strong>Risk Management</strong>
									<p>Efficiently capture and manage crucial aspects of your practice, including feedback, incidents, risks and more.</p>
								</li>
								<li>
									<strong>Management Support</strong>
									<p>Leverage expert healthcare know-how and easily access and manage all your key documents in one place.</p>
								</li>
								<li>
									<strong>Accreditation</strong>
									<p>
										Manage key operational policies, store evidence, and demonstrate compliance with healthcare quality Standards.
									</p>
								</li>
								<li>
									<strong>HR Management</strong>
									<p>Be confident that your staff can easily access the most up to date information, and easily keep track of your team’s compliance.</p>
								</li>
							</ul>
						</div>
					</div>
				</>}
			</Modal>
		</div>
	);
};

export default TrialBanner;

