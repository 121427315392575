import React from 'react';
import {Form} from 'react-bootstrap';

const SimpleTextArea = ({title, value, onChange, error}) => (
    <div className="SimpleTextArea">
        <label className="control-label">{title}</label>
        <div>
            <textarea
                className={`form-control ${error ? 'is-invalid' : ''}`}
                value={value || ''}
                onChange={onChange}>
            </textarea>
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    </div>
);

export default SimpleTextArea;
