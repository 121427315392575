import React from 'react';
import './notification.scss';
import Card from 'components/Card/Card';
import MarkAllAsRead from './MarkAsRead';
import Alert, { AlertStates } from 'components/Alert/Alert';
import { AppHeaderNotifications } from './NotificationContent/AppHeaderNotificationContent';
import { CustomNotificationItemType } from './NotificationItem/AppHeaderNotificationContentItem/AppHeaderNotificationContentItem';

export interface NotificationItem extends CustomNotificationItemType {
	id: string;
}

export interface FetchNotification {
	notifications: AppHeaderNotifications;
	fetchNotifications: () => Promise<void>;
	isLoading: boolean;
	hasError: boolean;
	clearNotifications: () => void;
}

export interface UpdateNotification {
	updateNotifications: (notificationIds: string[]) => Promise<void>;
	isLoading: boolean;
	hasError: boolean;
}

export interface UseNotification {
	notification: FetchNotification;
	updateNotification: UpdateNotification;
}

export interface NotificationProps extends UseNotification {
	notificationContentComponent: React.ElementType;
}

/*
The Notification component takes notification (State object),
updateNotification (Sate object) and notificationContent (React component).
notificationContent comp knows which NotificationItem comp to render.
notificationContent comp sends NotificationItem comp to NotificationSection comp.
NotificationSection comp maps the items and renders each item using NotificationItem comp
*/

const Notification: React.FC<NotificationProps> = ({
	notification,
	updateNotification,
	notificationContentComponent: NotificationContentComponent,
}) => {
	const handleMarkAllAsRead = (notificationIds: string[]) => {
		updateNotification?.updateNotifications(notificationIds);
	};

	return (
		<Card className="ph-notification">
			<header>
				<h4>Notifications</h4>
				<MarkAllAsRead
					handleMarkAllAsRead={handleMarkAllAsRead}
					message="Mark all as read"
					notification={notification}
					updateNotification={updateNotification}
				/>
			</header>

			{updateNotification?.hasError && (
				<Alert
					message="Error processing your request."
					showCloseButton={false}
					state={AlertStates.DANGER}
					action={
						<MarkAllAsRead
							handleMarkAllAsRead={handleMarkAllAsRead}
							message="Please try again"
							notification={notification}
							updateNotification={updateNotification}
						/>
					}
				/>
			)}

			<div className="ph-notifications">
				<NotificationContentComponent notification={notification} updateNotification={updateNotification} />
			</div>
		</Card>
	);
};

export default Notification;
