import { useCallback } from 'react';

export interface FileSignature {
    fileSignatures: Uint8Array[];
    mimeType: string;
}

const fileSignatures: { [key: string]: FileSignature } = {
    '.pdf': { fileSignatures: [new Uint8Array([0x25, 0x50, 0x44, 0x46])], mimeType: 'application/pdf' },
    '.jpg': { fileSignatures: [new Uint8Array([0xFF, 0xD8, 0xFF])], mimeType: 'image/jpeg' },
    '.jpeg': { fileSignatures: [new Uint8Array([0xFF, 0xD8, 0xFF])], mimeType: 'image/jpeg' },
    '.png': { fileSignatures: [new Uint8Array([0x89, 0x50, 0x4E, 0x47])], mimeType: 'image/png' },
    '.bmp': { fileSignatures: [new Uint8Array([0x42, 0x4D])], mimeType: 'image/bmp' },
    '.gif': { fileSignatures: [new Uint8Array([0x47, 0x49, 0x46, 0x38])], mimeType: 'image/gif' },
    '.doc': { fileSignatures: [new Uint8Array([0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1])], mimeType: 'application/msword' },
    '.docx': { fileSignatures: [new Uint8Array([0x50, 0x4B, 0x03, 0x04])], mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    '.ppt': { fileSignatures: [new Uint8Array([0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1])], mimeType: 'application/vnd.ms-powerpoint' },
    '.pptx': { fileSignatures: [new Uint8Array([0x50, 0x4B, 0x03, 0x04])], mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
    '.xls': { fileSignatures: [new Uint8Array([0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1])], mimeType: 'application/vnd.ms-excel' },
    '.xlsx': { fileSignatures: [new Uint8Array([0x50, 0x4B, 0x03, 0x04])], mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    '.xlsm': { fileSignatures: [new Uint8Array([0x50, 0x4B, 0x03, 0x04])], mimeType: 'application/vnd.ms-excel.sheet.macroEnabled.12' },
    '.docm': { fileSignatures: [new Uint8Array([0x50, 0x4B, 0x03, 0x04])], mimeType: 'application/vnd.ms-word.document.macroEnabled.12' },
    '.pptm': { fileSignatures: [new Uint8Array([0x50, 0x4B, 0x03, 0x04])], mimeType: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12' },
    '.ai': { fileSignatures: [new Uint8Array([0x25, 0x50, 0x44, 0x46])], mimeType: 'application/postscript' },
    '.pub': { fileSignatures: [new Uint8Array([0xEC, 0xA5, 0xC1, 0x00])], mimeType: 'application/x-mspublisher' },
    '.rtf': { fileSignatures: [new Uint8Array([0x7B, 0x5C, 0x72, 0x74, 0x66, 0x31])], mimeType: 'application/rtf' },
    '.odt': { fileSignatures: [new Uint8Array([0x50, 0x4B, 0x03, 0x04])], mimeType: 'application/vnd.oasis.opendocument.text' },
    '.dotx': { fileSignatures: [new Uint8Array([0x50, 0x4B, 0x03, 0x04])], mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' },
    '.oft': { fileSignatures: [new Uint8Array([0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1])], mimeType: 'application/x-font-otf' },
    '.pages': { fileSignatures: [new Uint8Array([0x50, 0x4B, 0x03, 0x04])], mimeType: 'application/x-iwork-pages-sffpages' },
    '.numbers': { fileSignatures: [new Uint8Array([0x50, 0x4B, 0x03, 0x04])], mimeType: 'application/x-iwork-numbers-sffnumbers' }
};
const allowedMimeTypes = Object.values(fileSignatures).map((signature) => signature.mimeType);


type useFileUploadValidationReturn = {
    validateFileMemeType: (mimeType: string) => boolean;
    validateFileSignature: (file: File) => Promise<boolean>;
};

const isValidFileSignature = async (file: File, ext: string): Promise<boolean> => {
    if (fileSignatures.hasOwnProperty(ext)) {
        const signatures = fileSignatures[ext].fileSignatures;
        const maxLength = Math.max(...signatures.map((s) => s.length));
        const blobFile = file.slice(0, maxLength);
        const headerBytes = new Uint8Array(await blobFile.arrayBuffer());

        return signatures.some((signature) =>
            headerBytes.slice(0, signature.length).every((byte, index) => byte === signature[index])
        );
    }
    return false;
};

const useFileUploadValidation = (): useFileUploadValidationReturn => {
    const validateFileMemeType = useCallback((mimeType: string) => {
        return allowedMimeTypes.includes(mimeType);
    }, []);

    const validateFileSignature = useCallback(async (file: File) => {
        if (!file) throw new Error('File not found');
        const ext = file.name.split('.').pop()?.toLowerCase() || '';
        return await isValidFileSignature(file, `.${ext}`);
    }, []);

    return { validateFileMemeType, validateFileSignature };
};

export default useFileUploadValidation;
