const COOKIE_NAMES = {
	LATEST_ACTIVITY_DETECTED_DATETIME: 'LatestActivityDetectedDateTime',
	CURRENT_USERID_ORGID: 'Current_UserId_OrgId',
	DISABLE_LOGOUT_BUTTON: 'DisableLogOutButton',
};

const getCookie = (name: string) => {
	const value: string = '; ' + document.cookie;
	const parts: string[] = value.split('; ' + name + '=');
	// what is the significane of 2? use const with a meanigful name
	if (parts.length === 2) return parts.pop()?.split(';').shift();
};

const setCookie = (name: string, value: string, domainName: string) => {
	const expires = ''; //never expire
	document.cookie = `${name}=${value || ''}${expires}; path=/; domain=${domainName}`;
};

const deleteCookie = (name: string, domainName: string) => {
	document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${domainName}`;
};

const setLatestActivityTimeCookie = (value: string, domainName: string) => {
	setCookie(COOKIE_NAMES.LATEST_ACTIVITY_DETECTED_DATETIME, value, domainName);
};

export { COOKIE_NAMES, getCookie, setCookie, deleteCookie, setLatestActivityTimeCookie };
