import React, { useEffect } from 'react';

function withDeprecationWarning<T extends object>(WrappedComponent: React.ComponentType<T>) {
	const DeprecatedComponent: React.FC<T> = (props) => {
		useEffect(() => {
			console.warn(
				`Warning: ${WrappedComponent.displayName} is deprecated and will be removed in the next major release.`
			);
		}, []);

		return <WrappedComponent {...props} />;
	};

	return DeprecatedComponent;
}

export default withDeprecationWarning;
