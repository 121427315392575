import React from 'react';
import Retry from 'components/Retry';
import EmptyNotifications from 'components/Notification/EmptyNotifications';
import NotificationSection from 'components/Notification/NotificationSection';
import { FetchNotification, UseNotification } from 'components/Notification';
import AppHeaderNotificationContentItem, {
	AppHeaderNotificationItem,
} from 'components/Notification/NotificationItem/AppHeaderNotificationContentItem';

export interface AppHeaderNotifications {
	today: AppHeaderNotificationItem[];
	thisweek: AppHeaderNotificationItem[];
	earlier: AppHeaderNotificationItem[];
	isEmpty: boolean;
	isAnyUnread: boolean;
}

interface AppHeaderNotification extends FetchNotification {
	notifications: AppHeaderNotifications;
}

interface AppHeaderNotificationContentProps extends UseNotification {
	notification: AppHeaderNotification;
}

const AppHeaderNotificationContent: React.FC<AppHeaderNotificationContentProps> = ({
	notification,
	updateNotification,
}) => {
	const { notifications, hasError, isLoading } = notification;
	const { updateNotifications } = updateNotification;

	if (hasError) {
		return <Retry fetchData={notification.fetchNotifications} heading="Unable to load notifications" />;
	}

	if (notifications?.isEmpty) {
		return <EmptyNotifications />;
	}

	return (
		<>
			<NotificationSection
				title="Today"
				items={notification.notifications.today}
				updateNotifications={updateNotifications}
				notificationItemComponent={AppHeaderNotificationContentItem}
				isLoading={isLoading}
			/>
			<NotificationSection
				title="This Week"
				items={notification.notifications.thisweek}
				updateNotifications={updateNotifications}
				notificationItemComponent={AppHeaderNotificationContentItem}
				isLoading={isLoading}
			/>
			<NotificationSection
				title="Earlier"
				items={notification.notifications.earlier}
				updateNotifications={updateNotifications}
				notificationItemComponent={AppHeaderNotificationContentItem}
				isLoading={isLoading}
			/>
		</>
	);
};

export default AppHeaderNotificationContent;
