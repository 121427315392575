import Avatar from 'components/Avatar';
import React, { useRef, useState } from 'react';
import useCheckOutsideClick from 'components/useCheckOutsideClick/useCheckOutsideClick';
import Card from 'components/Card';
import { classNames } from 'utils/util';

export interface UserProfileItemsProps {
	onSignOut: (urlParam?: string) => void;
	userFirstName: string;
	userLastName: string;
	manageAccountUrl: string;
	showManageAccount: boolean;
}

const textResources = {
	manageAccount: 'Manage Account',
	logout: 'Logout',
};

export const UserProfileItems: React.FC<UserProfileItemsProps> = ({
	onSignOut,
	userFirstName,
	userLastName,
	manageAccountUrl,
	showManageAccount,
}) => {
	const [isUserProfileItemsOpen, setIsUserProfileItemsOpen] = useState(false);
	const userProfileItemsRef = useRef<HTMLDivElement>(null);

	const onToggle = () => {
		setIsUserProfileItemsOpen((pre) => !pre);
	};

	useCheckOutsideClick(userProfileItemsRef, () => {
		setIsUserProfileItemsOpen(false);
	});

	const classes = classNames([
		'ph-userProfile',
		{
			isOpen: isUserProfileItemsOpen,
		},
	]);

	return (
		<div ref={userProfileItemsRef}>
			<button className={classes} onClick={onToggle} name="user-profile" aria-label="user-profile">
				<Avatar firstName={userFirstName} lastName={userLastName} />
			</button>
			{isUserProfileItemsOpen && (
				<Card className="ph-userProfile-items">
					<div className="ph-userProfile-item">
						<Avatar firstName={userFirstName} lastName={userLastName} />
						<span>{`${userFirstName} ${userLastName}`}</span>
					</div>
					{showManageAccount && (
						<a href={manageAccountUrl} className="ph-userProfile-item">
							{textResources.manageAccount}
						</a>
					)}
					<a
						href="#"
						className="ph-userProfile-item"
						onClick={() => {
							onSignOut();
						}}
						aria-label="user-sign-out-button"
					>
						<span>{textResources.logout}</span>
					</a>
				</Card>
			)}
		</div>
	);
};
