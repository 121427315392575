import React, { useEffect, useState } from 'react';
import './search.scss';
import { classNames } from 'utils/util';
import Icon from 'components/Icons/Icon';
import Input from 'components/Input';
import ErrorMessage from 'components/ErrorMessage';
import FormLabel from 'components/FormLabel';
import IconButton from 'components/IconButton';
import Badge from 'components/Badge';
import Card from 'components/Card/Card';
import { Tag } from 'components/useGlobalSearch/useGlobalSearch';

interface SearchProps {
	id?: string;
	label?: string;
	placeholder?: string;
	value?: string;
	disabled?: boolean;
	isMandatory?: boolean;
	error?: string;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	onChange?: (val: string) => void;
	onKeyUp?: (val: string) => void;
	searchTags?: (val: string) => Tag[];
	onTagSearch?: (searchText: string, tagIds: string[]) => void;
	clearSignal?: number;
}

type TagWithEvent = Tag & { onClick: () => void };

const KEY_ENTER = 'Enter';

const Search: React.FC<SearchProps> = ({
	id,
	placeholder = '',
	label,
	onClick,
	onChange,
	onKeyUp,
	value = '',
	disabled = false,
	error,
	isMandatory,
	searchTags,
	onTagSearch,
	clearSignal,
}) => {
	const initialClassName = 'ph-search';
	const [inputValue, setInputValue] = useState(value);
	const [showTagSection, setShowTagSection] = useState(false);
	const [tagsInternal, setTagsInternal] = useState<TagWithEvent[] | undefined>(undefined);
	const [errorMessage, setErrorMessage] = useState(error);
	const classes = classNames([initialClassName, { hasError: !!errorMessage }]);

	useEffect(() => {
		if (clearSignal) {
			onClearInput();
		}
	}, [clearSignal]);

	const onClearInput = () => {
		setInputValue('');
		setErrorMessage('');
		setShowTagSection(false);

		if (onChange) onChange('');
	};

	const onInternalChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
		const textInput = evt.target.value;
		setInputValue(textInput);
		setErrorMessage('');

		if (textInput && textInput.length > 0 && textInput.trim().length > 0) {
			setShowTagSection(true);
			setTagsInternal(
				searchTags &&
					searchTags(textInput)?.map((t) => {
						const tag = {
							id: t.id,
							name: t.name,
							onClick: () => onTagSearch && onTagSearch('', [t.id.toString()]),
						};

						return tag;
					})
			);
		} else {
			setShowTagSection(false);
			setTagsInternal(undefined);
		}

		if (onChange) onChange(evt.target.value);
	};

	const onInternalKeyUp = (evt: React.KeyboardEvent<HTMLInputElement>) => {
		if (evt.key === KEY_ENTER && onKeyUp) {
			onKeyUp((evt.target as HTMLInputElement).value);
		}
	};

	const isNoTagFound = () => Array.isArray(tagsInternal) && tagsInternal?.length === 0;

	return (
		<div className="ph-search-container">
			{label ? <FormLabel label={label} htmlIdFor={id} isMandatory={isMandatory} /> : null}
			<div className={classes} onClick={onClick}>
				<div className="ph-search-icon-search">
					<Icon icon={['far', 'search']} />
				</div>
				<Input
					id={id}
					placeholder={placeholder}
					value={inputValue}
					onChange={onInternalChange}
					onKeyUp={onInternalKeyUp}
					disabled={disabled}
					hasError={!!errorMessage}
				/>
				{inputValue.length > 0 ? (
					<div className="ph-search-clear-icon">
						<IconButton icon={['far', 'xmark']} onClick={onClearInput} />
					</div>
				) : null}
			</div>
			{errorMessage && <ErrorMessage error={errorMessage} />}
			{showTagSection ? (
				<Card className="ph-search-dialog">
					{isNoTagFound() ? (
						<p>No tag found.</p>
					) : (
						<>
							<p>Or search by a Tag</p>
							<ol>
								{tagsInternal?.map((tag) => (
									<li key={tag.id}>
										<Badge content={tag.name} onClick={tag.onClick} />
									</li>
								))}
							</ol>
						</>
					)}
				</Card>
			) : null}
		</div>
	);
};

export default Search;
