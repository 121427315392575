import { ChangeEvent, useMemo, useState, useCallback } from 'react';
import { ValidationRules, ValidationErrors, CombinedChangeEvent } from './type';

const validateField = (name: string, value: string, rules: ValidationRules) => {
    return rules[name]?.(value);
};

function useForm<T extends Record<string, string>>(
    INITIAL_VALUES: T,
    onSubmit: () => void,
    validationRules: ValidationRules
) {
    const [values, setValues] = useState(INITIAL_VALUES);
    const [errors, setErrors] = useState<ValidationErrors>({});
    const [touched, setTouched] = useState<Record<string, boolean>>({});
    const resetForm = () => {
        let temp = {...touched};
        Object.keys(values).forEach((key) => {
            temp[key] = false;
            setErrors((prevErrors) => ({ ...prevErrors, [key]: '' }));
        });
        setTouched({...temp});
        setValues({ ...INITIAL_VALUES });
    };
    const hasErrors = useCallback(() => {
        return Object.keys(values).some((key) => {
            if (touched[key]) {
                const error = validateField(key, values[key], validationRules);
                setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
                return error || error === '';
            }
            return false;
        });
    }, [values, touched, validationRules]);

    const hasError = useMemo(() => hasErrors(), [hasErrors, values, touched]);

    const handleChange = (event: CombinedChangeEvent) => {
        const { name, value } = event.target;
        const trimmedValue = value.trim();

        setValues((v) => ({
            ...v,
            [name]: trimmedValue,
        }));

        setTouched((t) => ({
            ...t,
            [name]: true,
        }));

        const error = validateField(name, trimmedValue, validationRules);
        setErrors((e) => ({
            ...e,
            [name]: error,
        }));
    };

    const handleSubmit = (event: any) => {
		event.preventDefault();

		const allTouched = Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {});
		setTouched(allTouched);

		const newErrors: ValidationErrors = {};
		let formIsValid = true;

		Object.keys(values).forEach((key) => {
            const error = validateField(key, values[key], validationRules);
            newErrors[key] = error;
            if (error || error === '') {
				formIsValid = false;
			}
        });

		setErrors(newErrors);

		if (formIsValid) {
			onSubmit();
		}
    };

    return { values, setValues, handleChange, handleSubmit, hasError, errors, resetForm };
}

export default useForm;
