import React, { useEffect, useState } from 'react';
import './select.scss';
import ReactSelect, { ActionMeta, GroupBase, MenuPlacement, PropsValue } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import FormLabel, { FormLabelWithControl } from 'components/FormLabel';
import ErrorMessage from 'components/ErrorMessage';
import { classNames } from 'utils/util';

export interface Option {
	value?: string;
	label: string;
}

export interface SelectProps {
	label?: FormLabelWithControl;
	id: string;
	errorMessage?: string;
	disabled?: boolean;
	options: Option[];
	isMulti?: boolean;
	placeholder?: string;
	isSearchable?: boolean;
	value?: string | string[];
	onChange?: (value: PropsValue<Option>, actionMeta?: ActionMeta<Option>) => void;
	menuPlacement?: MenuPlacement;
	className?: string;
	isCreatable?: boolean;
	onCreateOption?: (inputValue: string) => void;
	isLoading?: boolean;
}

const getOptionByValue = (options: Option[], value?: string | string[]): Option[] => {
	if (Array.isArray(value) && value.length === 0) {
		// No option select when multi select
		// Return here to prevent loop all option below
		return [];
	}

	return options
		.filter((option) => {
			if (!Array.isArray(value)) {
				return option.value === value;
			} else {
				return value.includes(option.value ?? '');
			}
		})
		.map((option) => ({ ...option }));
};

const getControlBorderColorStyle = (isFocused: boolean, errorMessage?: string) => {
	if (isFocused) {
		return 'var(--ph-brand-primary-color)';
	}

	if (errorMessage) {
		return 'var(--ph-error)';
	}

	return 'var(--ph-border-dark)';
};

const getControlBoxShadowStyle = (isFocused: boolean, errorMessage?: string) => {
	if (isFocused) {
		return 'var(--ph-box-shadow-focus)';
	}

	if (errorMessage) {
		return 'var(--ph-box-shadow-focus-error)';
	}

	return '';
};

const Select: React.FC<SelectProps> = ({
	label,
	id,
	errorMessage,
	disabled = false,
	options,
	isMulti = false,
	placeholder = '',
	isSearchable = false,
	value,
	onChange,
	menuPlacement = 'auto',
	className,
	isCreatable = false,
	onCreateOption,
	isLoading = false,
	...props
}: SelectProps) => {
	const [selectedValueOption, setSelectedValueOption] = useState<PropsValue<Option>>(
		getOptionByValue(options, value)
	);
	const classes = classNames(['ph-select', className, 'keeper-ignore']);

	const onInternalChange = (newValue: PropsValue<Option>, actionMeta?: ActionMeta<Option>) => {
		setSelectedValueOption(newValue);
		if (onChange) {
			onChange(newValue, actionMeta);
		}
	};

	useEffect(() => {
		setSelectedValueOption(getOptionByValue(options, value));
	}, [value, options]);

	const combinedProps: CreatableProps<Option, boolean, GroupBase<Option>> = {
		styles: {
			control: (providedStyles, state) => ({
				...providedStyles,
				borderColor: getControlBorderColorStyle(state.isFocused, errorMessage),
				minHeight: 'calc(5 * var(--ph-size-base))',
				boxShadow: getControlBoxShadowStyle(state.isFocused, errorMessage),
				outline: state.isFocused ? 'none' : '',
				'&:hover': {
					borderColor: 'var(--ph-border-dark)',
				},
			}),
			menu: (providedStyles) => ({ ...providedStyles, marginTop: 0 }),
			menuPortal: (providedStyles) => ({ ...providedStyles, zIndex: 'var(--ph-z-index-overlay)' }),
			menuList: (providedStyles) => ({
				...providedStyles,
				padding: 'var(--ph-size-base) 0',
				display: 'flex',
				flexDirection: 'column',
			}),
			option: (providedStyles, state) => ({
				...providedStyles,
				backgroundColor: state.isSelected ? 'var(--ph-menu-background-active_hover)' : '',
				padding: 'var(--ph-size-base)',
				color: 'var(--ph-text-color-normal)',
				fontWeight: state.isSelected ? 'var(--ph-font-weight-semi-bold)' : '',
				'&:hover': {
					backgroundColor: state.isSelected
						? 'var(--ph-menu-background-active_hover)'
						: 'var(--ph-grey-lighter)',
					cursor: 'pointer',
				},
				'&:active': {
					backgroundColor: 'var(--ph-menu-background-active_hover)',
				},
			}),
		},
		options,
		components: {
			IndicatorSeparator: () => null,
		},
		inputId: id,
		isSearchable,
		placeholder,
		isMulti,
		isDisabled: disabled,
		value: selectedValueOption,
		onChange: onInternalChange,
		menuPlacement,
		isLoading,
		...props,
	};

	return (
		<div className={classes}>
			{!!label && <FormLabel label={label.label} htmlIdFor={id} isMandatory={label.isMandatory} />}

			{isCreatable ? (
				<CreatableSelect
					{...combinedProps}
					isSearchable={true}
					onCreateOption={onCreateOption}
					menuPortalTarget={document.body}
					classNamePrefix="ph-select"
					menuShouldBlockScroll={true}
				/>
			) : (
				<ReactSelect
					{...combinedProps}
					menuPortalTarget={document.body}
					classNamePrefix="ph-select"
					menuShouldBlockScroll={true}
				/>
			)}

			{!!errorMessage && <ErrorMessage error={errorMessage} />}
		</div>
	);
};

export default Select;
