import React from 'react';
import './errorMessage.scss';
import Icon from 'components/Icons/Icon';

interface ErrorMessageProps {
	error: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
	return (
		<>
			{error && (
				<div className="ph-error">
					<Icon icon={['fal', 'triangle-exclamation']} />
					<span>{error}</span>
				</div>
			)}
		</>
	);
};

export default ErrorMessage;
