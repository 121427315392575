import Button from 'components/Button';
import React, { ReactNode, useState } from 'react';
import './inputCollection.scss';
import InputCollectionItem from './InputCollectionItem';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import withDeprecationWarning from 'utils/hoc/withDeprecationWarning';

export interface InitialCollection {
	name: string;
	value: string;
}

interface InputCollectionProps {
	label?: string;
	description: string;
	itemName?: string;
	initialCollection?: string[];
	note?: string;
	buttonPrefix: IconProp;
	buttonLabel: string;
	buttonIconClass?: IconProp;
	isMandatory?: boolean;
	renderItem?: (value?: ReactNode) => ReactNode;
	error?: string;
}

const InputCollection: React.FC<InputCollectionProps> = ({
	label,
	description,
	itemName = 'Item',
	initialCollection = [],
	note,
	buttonPrefix,
	buttonLabel,
	buttonIconClass,
	isMandatory,
	renderItem,
	error,
}) => {
	const classes = ['ph-inputCollection'];
	const [collection, setCollection] = useState(
		initialCollection.map((x: string, index: number) => ({
			name: `${itemName} ${index}`,
			value: x,
		}))
	);

	if (error) classes.push('hasError');
	if (isMandatory) classes.push('isMandatory');
	if (!label) classes.push('hasNoLabel');
	if (collection.length) classes.push('hasChildren');

	const addItem = () => {
		setCollection([
			...collection,
			{
				name: 'Additional Site',
				value: {},
			} as InitialCollection,
		]);
	};

	const removeItem = (item: object) => {
		setCollection([...collection.filter((x: object) => x !== item)]);
	};

	return (
		<div aria-label={label} className={classes.join(' ')}>
			{label ? <span className="ph-inputCollection-label">{label}</span> : null}
			{note ? <span className="ph-inputCollection-note">{note}</span> : null}

			<div className="ph-inputCollection-input">
				{description ? <div className="ph-inputCollection-desciption">{description}</div> : null}
				<Button
					onClick={addItem}
					rightIcon={buttonIconClass}
					buttonText={buttonLabel}
					type={'secondary'}
					leftIcon={buttonPrefix}
				></Button>
			</div>

			{collection.length ? (
				<div className="ph-inputCollection-list">
					{collection.map((x: InitialCollection, index: number) => (
						<InputCollectionItem key={index} item={x} renderItem={renderItem} removeItem={removeItem} />
					))}
				</div>
			) : null}
			{error && <span className="ph-inputCollection-error">{error}</span>}
		</div>
	);
};

export default withDeprecationWarning(InputCollection);
