import React, { useState, useRef } from 'react';
import './imageInput.scss';
import Button from 'components/Button';
import Icon from 'components/Icons/Icon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import withDeprecationWarning from 'utils/hoc/withDeprecationWarning';

interface ImageInputProps {
	label?: string;
	description: string;
	initialSource?: string;
	note?: string;
	buttonPrefix?: IconProp;
	buttonLabel: string;
	buttonIconClass?: IconProp;
	isMandatory?: boolean;
	error?: string;
}

const ImageInput: React.FC<ImageInputProps> = ({
	label,
	description,
	initialSource = null,
	note,
	buttonPrefix,
	buttonLabel,
	buttonIconClass,
	isMandatory,
	error,
}) => {
	const classes = ['ph-imageInput'];
	const [src, setSrc] = useState(initialSource);
	const hiddenInput = useRef<HTMLInputElement>(null);

	if (error) classes.push('hasError');
	if (isMandatory) classes.push('isMandatory');
	if (!label) classes.push('hasNoLabel');

	const selectFile = () => {
		if (hiddenInput.current) {
			hiddenInput.current.click();
		}
	};

	const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const files = evt.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			setSrc(URL.createObjectURL(file));
		}
	};

	const clear = () => {
		if (hiddenInput.current) {
			hiddenInput.current.value = '';
		}
		setSrc('');
	};

	return (
		<div aria-label={label} className={classes.join(' ')}>
			{label ? <span className="ph-imageInput-label">{label}</span> : null}
			{note ? <span className="ph-imageInput-note">{note}</span> : null}
			<div className="ph-imageInput-input">
				{description ? <div className="ph-imageInput-desciption">{description}</div> : null}
				{!src && (
					<Button
						onClick={selectFile}
						type={'secondary'}
						rightIcon={buttonIconClass}
						buttonText={buttonLabel}
						leftIcon={buttonPrefix}
					></Button>
				)}
				<input type="file" style={{ display: 'none' }} ref={hiddenInput} onChange={onChange} />
				{src && (
					<div className="ph-imageInput-preview">
						<div className="ph-imageInput-preview-image">
							<img title="Image preview" src={src} />
						</div>
						<div className="ph-imageInput-remove" title="Image clear" onClick={clear}>
							<Icon icon={['fal', 'trash-can']} />
						</div>
					</div>
				)}
			</div>
			{error && <span className="ph-imageInput-error">{error}</span>}
		</div>
	);
};

export default withDeprecationWarning(ImageInput);
