import { NotificationsUtility } from './NotificationsUtility';

export class ApiUtility {
    static getBaseUrl() {
        return '';
    }

    static errorResponse(error) {
        if (error.response && error.response.status === 400) {
            NotificationsUtility.showError(error.message);
        }
        return error;
    };
}
 